import React from 'react';
import './About.css';
import Footer from '../../components/Footer'
import Navbar from '../../components/Navbar';
const About = () => {
  return (
    <div>
      <div className="about_all">
        <div className="about_top">
          <div className="about_title-with-content">
            <p className="about_title">
              ABOUT THE EVENT
            </p>
            <div className="about_image-with-content">
              <p className="about_content about_para1">
              Exodus 4.0 is a front-end web development competition in which students of all colleges can participate and show off their skills. The competition is held online and has a prize pool of Rupees 6500. The participants will be judged on the design, interactivity, and creativity of their websites.

              </p>
              <img src="../../images/arrow_3.svg" alt="Satellite" className="about_satellite" />
            </div>
          </div>
        </div>


        <div className="about_bottom">

          <img src="../../images/Arrow _1.svg" alt="Moon" className="about_moon" />
          <p className="about_content about_para2">
          Imagine a world where Earth's survivors settle on a distant planet and navigate a pandemic. Your challenge is to envision and design a website for a supply delivery service, serving as a lifeline to citizens during this complex period. The participants are encouraged to let their creativity soar as they design an intuitive interface and futuristic solutions. In this competition,  we seek innovative solutions and outstanding web development skills.

          </p>
        </div>
      </div>
    </div>

  );
}

export default About;