/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import './Rules.css';
const Rules = () => {
    return (
        <div className="rules_wrapper">
            <h1 className="rules_header">RULES</h1>
            <div className="rules_text">
                <p>1. Event Duration: January 26th to January 28th.</p>
                <p>2. Submission Deadline: January 28th, 11:59 PM.</p>
                <p>3. Eligibility: Open to all college students</p>
                <p>4. Team Size: 1-2 people.</p>
                <p>5. Languages/Frameworks: Participants are free to use any programming languages or frameworks.</p>
                <p>6. Submission Format: Submit your hosted project via this portal before the deadline.</p>
                <p>7. Judging Criteria: Creativity, functionality, user experience, and adherence to web development best practices.</p>
                <p>8. Code of Conduct: Respectful and ethical behavior expected. Plagiarism will result in disqualification.</p>
                <p>9. Results: Winners will be announced on our Instagram page.</p>
                <p>10. Have Fun! Enjoy the challenge, and best of luck!</p>
            </div>
            <div>
                <img className="rules_vector1" src="../../images/Vector 29.svg" alt="Vector 1" />
                <img className="rules_vector2" src="../../images/Vector 34.svg" alt="Vector 2" />
                <img className="rules_vector3" src="../../images/Vector 33.svg" alt="Vector 3" />
                <img className="rules_vector4" src="../../images/Vector 32.svg" alt="Vector 4" />
                {/* <img className="rules_vector5" src="../../images/Vector 28.svg" alt="Vector 5" /> */}
                <img className="rules_vector6" src="../../images/Astronaut.svg" alt="Vector 6" />
            </div>
        </div>
    );
}

export default Rules;