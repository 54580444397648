import React, {useState, useEffect} from 'react';
import './Home.css';
import spiralVector from './spiralvector.svg';
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import {useLocation} from "react-router-dom";

const Home = () => {
    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);

    const targetDate = new Date("January 26, 2024 00:00:00").getTime() + 5.5*60*60*1000;

    const count = () => {
        const now = new Date().getTime();
        const diff = targetDate - now;

        setDays(Math.floor(diff / (1000 * 60 * 60 * 24)));
        setHours(Math.floor((diff / (1000 * 60 * 60)) % 24));
        setMinutes(Math.floor((diff / 1000 / 60) % 60));
        setSeconds(Math.floor((diff / 1000) % 60));
    };

    useEffect(() => {
        const interval = setInterval(count, 1000);
        return () => clearInterval(interval);
    }, []);
    return (
        <div>
        <div className="home_main">
            <div>
                <div className="exodus-hp">
                    EXODUS
                </div>

                <div className="maincontainer-hp">

                    <div className="background_image-hp"></div>


                    <img src={spiralVector} alt="Spiral Vector" className="spiral-vector-hp"/>
                    <div className="buildings-hp-div">
                    <svg className="buildings-hp" width="100%" height="567" viewBox="0 0 1690 567" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path d="M844.5 286H855L885 562.5H815L844.5 286Z" fill="url(#paint0_linear_27_25)"/>
                        <rect x="834" y="361" width="33" height="6" fill="#1F1B30"/>
                        <rect x="818" y="424" width="59" height="8" fill="#231E37"/>
                        <path d="M840 314H860V319H840V314Z" fill="#1C192A"/>
                        <path d="M838 286H862L1570 567H130L838 286Z" fill="url(#paint1_radial_27_25)" fill-opacity="1"/>
                        <path d="M838 286H862L1570 567H130L838 286Z" fill="url(#paint2_radial_27_25)"
                              fill-opacity="0.2"/>
                        <path d="M1318.76 362.268L1436.73 316.309V178.261L1318.76 224.22V362.268Z"
                              fill="url(#paint3_linear_27_25)" stroke="#865EFF"/>
                        <path d="M1199.79 316.309L1317.76 362.268V224.296L1199.79 178.261V316.309Z"
                              fill="url(#paint4_linear_27_25)" stroke="#865EFF"/>
                        <path d="M1318.26 223.342L1435.85 177.53L1318.26 131.718L1200.67 177.53L1318.26 223.342Z"
                              fill="url(#paint5_linear_27_25)" stroke="#E383FF"/>
                        <path d="M1318.76 217.354L1421.86 177.188V45.0099L1318.76 85.1752V217.354Z"
                              fill="url(#paint6_linear_27_25)" stroke="#E383FF"/>
                        <path d="M1214.66 177.188L1317.76 217.354V85.1752L1214.66 45.0099V177.188Z"
                              fill="url(#paint7_linear_27_25)" stroke="#E383FF"/>
                        <path d="M1318.26 84.2973L1420.99 44.2749L1318.26 3.53788L1215.53 44.2749L1318.26 84.2973Z"
                              fill="#E383FF" stroke="#E383FF"/>
                        <path d="M1318.26 78.5032L1404.54 44.2791L1318.26 10.055L1230.41 44.2791L1318.26 78.5032Z"
                              fill="url(#paint8_radial_27_25)" stroke="#E383FF"/>
                        <path d="M1318.26 78.5032L1404.54 44.2791L1318.26 10.055L1230.41 44.2791L1318.26 78.5032Z"
                              fill="url(#paint9_radial_27_25)" stroke="#E383FF"/>
                        <path d="M1318.26 50.2595L1346.62 39.209L1318.26 28.1585L1289.89 39.209L1318.26 50.2595Z"
                              fill="#E383FF" stroke="#E383FF"/>
                        <path d="M1347.5 44.6608V39.9405L1318.76 51.138V55.8583L1347.5 44.6608Z" fill="#5F7CBA"
                              stroke="#E383FF"/>
                        <path d="M1317.76 55.8583V51.138L1289.02 39.9405V44.6608L1317.76 55.8583Z"
                              fill="url(#paint10_radial_27_25)" stroke="#E383FF"/>
                        <path d="M1429.29 313.338V186.953L1419.14 190.909V317.294L1429.29 313.338Z"
                              fill="url(#paint11_radial_27_25)" stroke="#865EFF"/>
                        <path d="M1414.42 174.291V53.6995L1404.27 57.6551V178.246L1414.42 174.291Z"
                              fill="url(#paint12_radial_27_25)" stroke="#E383FF"/>
                        <path d="M1399.55 180.086V59.4943L1389.4 63.4499V184.041L1399.55 180.086Z"
                              fill="url(#paint13_radial_27_25)" stroke="#E383FF"/>
                        <path d="M1384.68 185.878V65.2868L1374.53 69.2423V189.834L1384.68 185.878Z"
                              fill="url(#paint14_radial_27_25)" stroke="#E383FF"/>
                        <path d="M1369.81 191.673V71.082L1359.66 75.0376V195.629L1369.81 191.673Z"
                              fill="url(#paint15_radial_27_25)" stroke="#E383FF"/>
                        <path d="M1354.94 197.466V76.8744L1344.78 80.83V201.421L1354.94 197.466Z"
                              fill="url(#paint16_radial_27_25)" stroke="#E383FF"/>
                        <path d="M1340.07 203.26V82.6683L1329.91 86.6239V207.215L1340.07 203.26Z"
                              fill="url(#paint17_radial_27_25)" stroke="#E383FF"/>
                        <path d="M1414.42 319.131V192.746L1404.27 196.701V323.086L1414.42 319.131Z"
                              fill="url(#paint18_radial_27_25)" stroke="#865EFF"/>
                        <path d="M1399.55 324.925V198.54L1389.4 202.495V328.88L1399.55 324.925Z"
                              fill="url(#paint19_radial_27_25)" stroke="#865EFF"/>
                        <path d="M1384.68 330.718V204.333L1374.53 208.289V334.674L1384.68 330.718Z"
                              fill="url(#paint20_radial_27_25)" stroke="#865EFF"/>
                        <path d="M1369.81 336.511V210.126L1359.66 214.082V340.467L1369.81 336.511Z"
                              fill="url(#paint21_radial_27_25)" stroke="#865EFF"/>
                        <path d="M1354.94 342.306V215.921L1344.78 219.877V346.262L1354.94 342.306Z"
                              fill="url(#paint22_radial_27_25)" stroke="#865EFF"/>
                        <path d="M1340.07 348.099V221.714L1329.91 225.67V352.055L1340.07 348.099Z"
                              fill="url(#paint23_radial_27_25)" stroke="#865EFF"/>
                        <path d="M1310.32 353.875V227.485L1300.7 223.877V350.267L1310.32 353.875Z"
                              fill="url(#paint24_radial_27_25)" stroke="#865EFF"/>
                        <path d="M1310.32 209.036V88.4392L1300.7 84.8311V205.428L1310.32 209.036Z"
                              fill="url(#paint25_radial_27_25)" stroke="#E383FF"/>
                        <path d="M1295.45 203.242V82.6454L1285.83 79.0373V199.634L1295.45 203.242Z"
                              fill="url(#paint26_radial_27_25)" stroke="#E383FF"/>
                        <path d="M1280.58 196.724V76.1271L1270.96 72.519V193.116L1280.58 196.724Z"
                              fill="url(#paint27_radial_27_25)" stroke="#E383FF"/>
                        <path d="M1265.71 191.654V71.0577L1256.09 67.4496V188.046L1265.71 191.654Z"
                              fill="url(#paint28_radial_27_25)" stroke="#E383FF"/>
                        <path d="M1250.84 185.861V65.264L1241.22 61.6559V182.253L1250.84 185.861Z"
                              fill="url(#paint29_radial_27_25)"/>
                        <path d="M1250.84 185.861V65.264L1241.22 61.6559V182.253L1250.84 185.861Z"
                              fill="url(#paint30_radial_27_25)"/>
                        <path d="M1250.84 185.861V65.264L1241.22 61.6559V182.253L1250.84 185.861Z"
                              fill="url(#paint31_radial_27_25)"/>
                        <path d="M1250.84 185.861V65.264L1241.22 61.6559V182.253L1250.84 185.861Z" stroke="#E383FF"/>
                        <path d="M1265.7 191.43V70.8331L1256.08 67.225V187.822L1265.7 191.43Z"
                              fill="url(#paint32_radial_27_25)"/>
                        <path d="M1265.7 191.43V70.8331L1256.08 67.225V187.822L1265.7 191.43Z"
                              fill="url(#paint33_radial_27_25)"/>
                        <path d="M1265.7 191.43V70.8331L1256.08 67.225V187.822L1265.7 191.43Z"
                              fill="url(#paint34_radial_27_25)"/>
                        <path d="M1265.7 191.43V70.8331L1256.08 67.225V187.822L1265.7 191.43Z" stroke="#E383FF"/>
                        <path d="M1280.05 195.861V75.2641L1270.43 71.656V192.253L1280.05 195.861Z"
                              fill="url(#paint35_radial_27_25)"/>
                        <path d="M1280.05 195.861V75.2641L1270.43 71.656V192.253L1280.05 195.861Z"
                              fill="url(#paint36_radial_27_25)"/>
                        <path d="M1280.05 195.861V75.2641L1270.43 71.656V192.253L1280.05 195.861Z"
                              fill="url(#paint37_radial_27_25)"/>
                        <path d="M1280.05 195.861V75.2641L1270.43 71.656V192.253L1280.05 195.861Z" stroke="#E383FF"/>
                        <path d="M1280.05 195.861V75.2641L1270.43 71.656V192.253L1280.05 195.861Z"
                              fill="url(#paint38_radial_27_25)"/>
                        <path d="M1280.05 195.861V75.2641L1270.43 71.656V192.253L1280.05 195.861Z"
                              fill="url(#paint39_radial_27_25)"/>
                        <path d="M1280.05 195.861V75.2641L1270.43 71.656V192.253L1280.05 195.861Z"
                              fill="url(#paint40_radial_27_25)"/>
                        <path d="M1280.05 195.861V75.2641L1270.43 71.656V192.253L1280.05 195.861Z" stroke="#E383FF"/>
                        <path d="M1280.05 195.861V75.2641L1270.43 71.656V192.253L1280.05 195.861Z"
                              fill="url(#paint41_radial_27_25)"/>
                        <path d="M1280.05 195.861V75.2641L1270.43 71.656V192.253L1280.05 195.861Z"
                              fill="url(#paint42_radial_27_25)"/>
                        <path d="M1280.05 195.861V75.2641L1270.43 71.656V192.253L1280.05 195.861Z"
                              fill="url(#paint43_radial_27_25)"/>
                        <path d="M1280.05 195.861V75.2641L1270.43 71.656V192.253L1280.05 195.861Z" stroke="#E383FF"/>
                        <path d="M1295.6 203.244V82.6469L1285.98 79.0388V199.635L1295.6 203.244Z"
                              fill="url(#paint44_radial_27_25)"/>
                        <path d="M1295.6 203.244V82.6469L1285.98 79.0388V199.635L1295.6 203.244Z"
                              fill="url(#paint45_radial_27_25)"/>
                        <path d="M1295.6 203.244V82.6469L1285.98 79.0388V199.635L1295.6 203.244Z"
                              fill="url(#paint46_radial_27_25)"/>
                        <path d="M1295.6 203.244V82.6469L1285.98 79.0388V199.635L1295.6 203.244Z" stroke="#E383FF"/>
                        <path d="M1309.95 207.675V87.078L1300.33 83.4699V204.067L1309.95 207.675Z"
                              fill="url(#paint47_radial_27_25)"/>
                        <path d="M1309.95 207.675V87.078L1300.33 83.4699V204.067L1309.95 207.675Z"
                              fill="url(#paint48_radial_27_25)"/>
                        <path d="M1309.95 207.675V87.078L1300.33 83.4699V204.067L1309.95 207.675Z"
                              fill="url(#paint49_radial_27_25)"/>
                        <path d="M1309.95 207.675V87.078L1300.33 83.4699V204.067L1309.95 207.675Z" stroke="#E383FF"/>
                        <path d="M1235.97 180.067V59.4704L1226.35 55.8624V176.459L1235.97 180.067Z"
                              fill="url(#paint50_radial_27_25)"/>
                        <path d="M1235.97 180.067V59.4704L1226.35 55.8624V176.459L1235.97 180.067Z"
                              fill="url(#paint51_radial_27_25)"/>
                        <path d="M1235.97 180.067V59.4704L1226.35 55.8624V176.459L1235.97 180.067Z"
                              fill="url(#paint52_radial_27_25)"/>
                        <path d="M1235.97 180.067V59.4704L1226.35 55.8624V176.459L1235.97 180.067Z" stroke="#E383FF"/>
                        <path d="M1235.97 180.067V59.4704L1226.35 55.8624V176.459L1235.97 180.067Z"
                              fill="url(#paint53_radial_27_25)"/>
                        <path d="M1235.97 180.067V59.4704L1226.35 55.8624V176.459L1235.97 180.067Z"
                              fill="url(#paint54_radial_27_25)"/>
                        <path d="M1235.97 180.067V59.4704L1226.35 55.8624V176.459L1235.97 180.067Z"
                              fill="url(#paint55_radial_27_25)"/>
                        <path d="M1235.97 180.067V59.4704L1226.35 55.8624V176.459L1235.97 180.067Z" stroke="#E383FF"/>
                        <path d="M1296.52 348.081V221.691L1286.89 218.083V344.473L1296.52 348.081Z"
                              fill="url(#paint56_radial_27_25)" stroke="#865EFF"/>
                        <path d="M1281.64 342.288V215.898L1272.02 212.29V338.68L1281.64 342.288Z"
                              fill="url(#paint57_radial_27_25)" stroke="#865EFF"/>
                        <path d="M1266.77 336.494V210.104L1257.15 206.496V332.886L1266.77 336.494Z"
                              fill="url(#paint58_radial_27_25)" stroke="#865EFF"/>
                        <path d="M1251.9 330.7V204.31L1242.28 200.702V327.092L1251.9 330.7Z"
                              fill="url(#paint59_radial_27_25)" stroke="#865EFF"/>
                        <path d="M1235.97 324.907V198.517L1226.35 194.909V321.299L1235.97 324.907Z"
                              fill="url(#paint60_radial_27_25)" stroke="#865EFF"/>
                        <path d="M1221.1 319.113V192.723L1211.47 189.115V315.505L1221.1 319.113Z"
                              fill="url(#paint61_radial_27_25)" stroke="#865EFF"/>
                        <path d="M1543.89 449.271L1688.73 393.254V56.6506L1543.89 112.544V449.271Z" fill="#1A1826"
                              stroke="#865EFF"/>
                        <path d="M1398.04 393.352L1542.89 449.271V112.56L1398.04 56.6507V393.352Z" fill="#1A1826"/>
                        <path d="M1398.04 393.352L1542.89 449.271V112.56L1398.04 56.6507V393.352Z"
                              fill="url(#paint62_linear_27_25)" fill-opacity="0.2"/>
                        <path d="M1398.04 393.352L1542.89 449.271V112.56L1398.04 56.6507V393.352Z" stroke="#865EFF"/>
                        <path d="M1531.23 125.938L1513.87 119.494V429.946L1531.23 436.39V125.938Z"
                              fill="url(#paint63_radial_27_25)"/>
                        <path d="M1495.64 112.465L1478.28 106.022L1477.41 415.888L1494.77 422.331L1495.64 112.465Z"
                              fill="url(#paint64_radial_27_25)"/>
                        <path d="M1460.05 98.9935L1442.68 92.5501V403.002L1460.05 409.445V98.9935Z"
                              fill="url(#paint65_radial_27_25)"/>
                        <path d="M1425.32 85.5216L1407.96 79.0783V388.944L1425.32 395.388V85.5216Z"
                              fill="url(#paint66_radial_27_25)"/>
                        <path d="M1425.32 85.5216L1407.96 79.0783V388.944L1425.32 395.388V85.5216Z"
                              fill="url(#paint67_radial_27_25)"/>
                        <path d="M1459.92 99.2879L1442.55 92.8446V402.711L1459.92 409.154V99.2879Z"
                              fill="url(#paint68_radial_27_25)"/>
                        <path d="M1494.51 113.053L1477.15 106.61V416.476L1494.51 422.919V113.053Z"
                              fill="url(#paint69_radial_27_25)"/>
                        <path d="M1531.45 126.889L1514.09 120.446V430.312L1531.45 436.755V126.889Z"
                              fill="url(#paint70_radial_27_25)"/>
                        <path d="M1678.81 78.4927L1660.58 85.5218V394.802L1678.81 387.773V78.4927Z" fill="#865EFF"/>
                        <path d="M1642.35 92.5501L1624.12 99.5792V408.86L1642.35 401.831V92.5501Z" fill="#865EFF"/>
                        <path d="M1605.89 106.023L1587.66 113.052V422.918L1605.89 415.889V106.023Z" fill="#865EFF"/>
                        <path d="M1571.16 119.494L1552.93 126.523V436.975L1571.16 429.946L1571.16 119.494Z"
                              fill="url(#paint71_radial_27_25)"/>
                        <path d="M1642.43 94.5163L1624.2 101.545V411.997L1642.43 404.968L1642.43 94.5163Z"
                              fill="url(#paint72_radial_27_25)"/>
                        <path d="M1678.67 77.7681L1660.44 84.7972V395.249L1678.67 388.22L1678.67 77.7681Z"
                              fill="url(#paint73_radial_27_25)"/>
                        <path d="M1606.33 106.228L1588.1 113.257V423.709L1606.33 416.68L1606.33 106.228Z"
                              fill="url(#paint74_radial_27_25)"/>
                        <path d="M1543.39 0L1397.54 55.9224L1543.39 111.845L1689.23 55.9224L1543.39 0Z" fill="#865EFF"/>
                        <path d="M1543.39 9.61215L1421.53 56.3598L1543.39 103.107L1665.24 56.3598L1543.39 9.61215Z"
                              fill="url(#paint75_radial_27_25)"/>
                        <path d="M1543.39 28.8351L1495.41 47.1847L1543.39 65.5342L1591.36 47.1847L1543.39 28.8351Z"
                              fill="#865EFF"/>
                        <path d="M1591.36 47.1837L1543.39 65.8245V75.1449L1591.36 56.5041V47.1837Z"
                              fill="url(#paint76_linear_27_25)"/>
                        <path d="M1543.39 65.8245L1495.41 47.1837V56.5041L1543.39 75.1449V65.8245Z"
                              fill="url(#paint77_linear_27_25)"/>
                        <path d="M994.114 332.27L1041.73 313.856V202.369L994.114 220.743V332.27Z" fill="#1A1826"
                              stroke="#865EFF"/>
                        <path d="M945.5 313.889L993.114 332.271V220.749L945.5 202.37V313.889Z" fill="#1A1826"/>
                        <path d="M945.5 313.889L993.114 332.271V220.749L945.5 202.37V313.889Z"
                              fill="url(#paint78_linear_27_25)" fill-opacity="0.2"/>
                        <path d="M945.5 313.889L993.114 332.271V220.749L945.5 202.37V313.889Z" stroke="#865EFF"/>
                        <path d="M989.562 224.979L983.775 222.832V326.315L989.562 328.463V224.979Z"
                              fill="url(#paint79_radial_27_25)"/>
                        <path d="M977.699 220.488L971.912 218.34L971.622 321.629L977.41 323.777L977.699 220.488Z"
                              fill="url(#paint80_radial_27_25)"/>
                        <path d="M965.835 215.998L960.048 213.85V317.334L965.835 319.482V215.998Z"
                              fill="url(#paint81_radial_27_25)"/>
                        <path d="M954.26 211.507L948.472 209.359V312.648L954.26 314.796V211.507Z"
                              fill="url(#paint82_radial_27_25)"/>
                        <path d="M954.26 211.507L948.472 209.359V312.648L954.26 314.796V211.507Z"
                              fill="url(#paint83_radial_27_25)"/>
                        <path d="M965.791 216.096L960.004 213.948V317.237L965.791 319.385V216.096Z"
                              fill="url(#paint84_radial_27_25)"/>
                        <path d="M977.322 220.684L971.535 218.537V321.825L977.322 323.973V220.684Z"
                              fill="url(#paint85_radial_27_25)"/>
                        <path d="M989.636 225.296L983.848 223.149V326.437L989.636 328.585V225.296Z"
                              fill="url(#paint86_radial_27_25)"/>
                        <path d="M1038.76 209.164L1032.68 211.507V314.601L1038.76 312.258V209.164Z" fill="#865EFF"/>
                        <path d="M1026.6 213.85L1020.53 216.193V319.286L1026.6 316.943V213.85Z" fill="#865EFF"/>
                        <path d="M1014.45 218.341L1008.37 220.684V323.973L1014.45 321.63V218.341Z" fill="#865EFF"/>
                        <path d="M1002.87 222.832L996.797 225.175V328.659L1002.87 326.315L1002.87 222.832Z"
                              fill="url(#paint87_radial_27_25)"/>
                        <path d="M1026.63 214.506L1020.55 216.849V320.332L1026.63 317.989L1026.63 214.506Z"
                              fill="url(#paint88_radial_27_25)"/>
                        <path d="M1038.71 208.922L1032.63 211.265V314.749L1038.71 312.406L1038.71 208.922Z"
                              fill="url(#paint89_radial_27_25)"/>
                        <path d="M1014.6 218.409L1008.52 220.752V324.236L1014.6 321.893L1014.6 218.409Z"
                              fill="url(#paint90_radial_27_25)"/>
                        <path d="M993.614 183L945 201.641L993.614 220.282L1042.23 201.641L993.614 183Z" fill="#865EFF"/>
                        <path d="M993.614 186.204L952.996 201.786L993.614 217.369L1034.23 201.786L993.614 186.204Z"
                              fill="url(#paint91_radial_27_25)"/>
                        <path d="M993.614 192.612L977.622 198.728L993.614 204.845L1009.61 198.728L993.614 192.612Z"
                              fill="#865EFF"/>
                        <path d="M1009.61 198.728L993.614 204.942V208.048L1009.61 201.835V198.728Z"
                              fill="url(#paint92_linear_27_25)"/>
                        <path d="M993.614 204.942L977.623 198.728V201.835L993.614 208.048V204.942Z"
                              fill="url(#paint93_linear_27_25)"/>
                        <path d="M1110.11 378.269L1191.73 346.472V250.933L1110.11 282.73V378.269Z"
                              fill="url(#paint94_linear_27_25)" stroke="#865EFF"/>
                        <path d="M1027.49 346.472L1109.11 378.269V282.782L1027.49 250.933V346.472Z"
                              fill="url(#paint95_linear_27_25)" stroke="#865EFF"/>
                        <path d="M1109.61 281.851L1190.85 250.201L1109.61 218.552L1028.37 250.201L1109.61 281.851Z"
                              fill="url(#paint96_linear_27_25)" stroke="#E383FF"/>
                        <path d="M1110.11 277.634L1181.4 249.86V158.398L1110.11 186.171V277.634Z"
                              fill="url(#paint97_linear_27_25)" stroke="#E383FF"/>
                        <path d="M1037.82 249.86L1109.11 277.634V186.171L1037.82 158.398V249.86Z"
                              fill="url(#paint98_linear_27_25)" stroke="#E383FF"/>
                        <path d="M1109.61 185.293L1180.53 157.662L1109.61 129.538L1038.69 157.662L1109.61 185.293Z"
                              fill="#E383FF" stroke="#E383FF"/>
                        <path d="M1109.61 181.269L1169.11 157.666L1109.61 134.064L1049.03 157.666L1109.61 181.269Z"
                              fill="url(#paint99_radial_27_25)" stroke="#E383FF"/>
                        <path d="M1109.61 181.269L1169.11 157.666L1109.61 134.064L1049.03 157.666L1109.61 181.269Z"
                              fill="url(#paint100_radial_27_25)" stroke="#E383FF"/>
                        <path d="M1109.61 161.655L1128.89 154.145L1109.61 146.635L1090.33 154.145L1109.61 161.655Z"
                              fill="#E383FF" stroke="#E383FF"/>
                        <path d="M1129.77 157.827V154.877L1110.11 162.534V165.484L1129.77 157.827Z" fill="#5F7CBA"
                              stroke="#E383FF"/>
                        <path d="M1109.11 165.484V162.534L1089.46 154.877V157.827L1109.11 165.484Z"
                              fill="url(#paint101_radial_27_25)" stroke="#E383FF"/>
                        <path d="M1186.57 344.408V256.969L1179.82 259.597V347.036L1186.57 344.408Z"
                              fill="url(#paint102_radial_27_25)" stroke="#865EFF"/>
                        <path d="M1176.24 247.848V164.432L1169.49 167.059V250.476L1176.24 247.848Z"
                              fill="url(#paint103_radial_27_25)" stroke="#E383FF"/>
                        <path d="M1165.91 251.872V168.456L1159.17 171.084V254.5L1165.91 251.872Z"
                              fill="url(#paint104_radial_27_25)" stroke="#E383FF"/>
                        <path d="M1155.58 255.895V172.478L1148.84 175.106V258.522L1155.58 255.895Z"
                              fill="url(#paint105_radial_27_25)" stroke="#E383FF"/>
                        <path d="M1145.26 259.919V176.503L1138.51 179.131V262.547L1145.26 259.919Z"
                              fill="url(#paint106_radial_27_25)" stroke="#E383FF"/>
                        <path d="M1134.93 263.941V180.525L1128.18 183.153V266.569L1134.93 263.941Z"
                              fill="url(#paint107_radial_27_25)" stroke="#E383FF"/>
                        <path d="M1124.6 267.965V184.549L1117.86 187.177V270.593L1124.6 267.965Z"
                              fill="url(#paint108_radial_27_25)" stroke="#E383FF"/>
                        <path d="M1176.24 348.431V260.992L1169.49 263.62V351.059L1176.24 348.431Z"
                              fill="url(#paint109_radial_27_25)" stroke="#865EFF"/>
                        <path d="M1165.91 352.455V265.015L1159.17 267.643V355.082L1165.91 352.455Z"
                              fill="url(#paint110_radial_27_25)" stroke="#865EFF"/>
                        <path d="M1155.58 356.478V269.039L1148.84 271.666V359.106L1155.58 356.478Z"
                              fill="url(#paint111_radial_27_25)" stroke="#865EFF"/>
                        <path d="M1145.26 360.501V273.061L1138.51 275.689V363.129L1145.26 360.501Z"
                              fill="url(#paint112_radial_27_25)" stroke="#865EFF"/>
                        <path d="M1134.93 364.525V277.085L1128.18 279.713V367.153L1134.93 364.525Z"
                              fill="url(#paint113_radial_27_25)" stroke="#865EFF"/>
                        <path d="M1124.6 368.548V281.109L1117.86 283.736V371.176L1124.6 368.548Z"
                              fill="url(#paint114_radial_27_25)" stroke="#865EFF"/>
                        <path d="M1103.95 372.443V284.998L1097.57 282.607V370.052L1103.95 372.443Z"
                              fill="url(#paint115_radial_27_25)" stroke="#865EFF"/>
                        <path d="M1103.95 271.86V188.439L1097.57 186.048V269.469L1103.95 271.86Z"
                              fill="url(#paint116_radial_27_25)" stroke="#E383FF"/>
                        <path d="M1093.62 267.837V184.415L1087.24 182.024V265.446L1093.62 267.837Z"
                              fill="url(#paint117_radial_27_25)" stroke="#E383FF"/>
                        <path d="M1083.29 263.31V179.889L1076.92 177.498V260.919L1083.29 263.31Z"
                              fill="url(#paint118_radial_27_25)" stroke="#E383FF"/>
                        <path d="M1072.97 259.79V176.368L1066.59 173.977V257.399L1072.97 259.79Z"
                              fill="url(#paint119_radial_27_25)" stroke="#E383FF"/>
                        <path d="M1062.64 255.766V172.345L1056.26 169.954V253.375L1062.64 255.766Z"
                              fill="url(#paint120_radial_27_25)"/>
                        <path d="M1062.64 255.766V172.345L1056.26 169.954V253.375L1062.64 255.766Z"
                              fill="url(#paint121_radial_27_25)"/>
                        <path d="M1062.64 255.766V172.345L1056.26 169.954V253.375L1062.64 255.766Z"
                              fill="url(#paint122_radial_27_25)"/>
                        <path d="M1062.64 255.766V172.345L1056.26 169.954V253.375L1062.64 255.766Z" stroke="#E383FF"/>
                        <path d="M1072.96 259.633V176.212L1066.58 173.821V257.242L1072.96 259.633Z"
                              fill="url(#paint123_radial_27_25)"/>
                        <path d="M1072.96 259.633V176.212L1066.58 173.821V257.242L1072.96 259.633Z"
                              fill="url(#paint124_radial_27_25)"/>
                        <path d="M1072.96 259.633V176.212L1066.58 173.821V257.242L1072.96 259.633Z"
                              fill="url(#paint125_radial_27_25)"/>
                        <path d="M1072.96 259.633V176.212L1066.58 173.821V257.242L1072.96 259.633Z" stroke="#E383FF"/>
                        <path d="M1082.92 262.71V179.289L1076.55 176.898V260.319L1082.92 262.71Z"
                              fill="url(#paint126_radial_27_25)"/>
                        <path d="M1082.92 262.71V179.289L1076.55 176.898V260.319L1082.92 262.71Z"
                              fill="url(#paint127_radial_27_25)"/>
                        <path d="M1082.92 262.71V179.289L1076.55 176.898V260.319L1082.92 262.71Z"
                              fill="url(#paint128_radial_27_25)"/>
                        <path d="M1082.92 262.71V179.289L1076.55 176.898V260.319L1082.92 262.71Z" stroke="#E383FF"/>
                        <path d="M1082.92 262.71V179.289L1076.55 176.898V260.319L1082.92 262.71Z"
                              fill="url(#paint129_radial_27_25)"/>
                        <path d="M1082.92 262.71V179.289L1076.55 176.898V260.319L1082.92 262.71Z"
                              fill="url(#paint130_radial_27_25)"/>
                        <path d="M1082.92 262.71V179.289L1076.55 176.898V260.319L1082.92 262.71Z"
                              fill="url(#paint131_radial_27_25)"/>
                        <path d="M1082.92 262.71V179.289L1076.55 176.898V260.319L1082.92 262.71Z" stroke="#E383FF"/>
                        <path d="M1082.92 262.71V179.289L1076.55 176.898V260.319L1082.92 262.71Z"
                              fill="url(#paint132_radial_27_25)"/>
                        <path d="M1082.92 262.71V179.289L1076.55 176.898V260.319L1082.92 262.71Z"
                              fill="url(#paint133_radial_27_25)"/>
                        <path d="M1082.92 262.71V179.289L1076.55 176.898V260.319L1082.92 262.71Z"
                              fill="url(#paint134_radial_27_25)"/>
                        <path d="M1082.92 262.71V179.289L1076.55 176.898V260.319L1082.92 262.71Z" stroke="#E383FF"/>
                        <path d="M1093.72 267.838V184.416L1087.35 182.025V265.447L1093.72 267.838Z"
                              fill="url(#paint135_radial_27_25)"/>
                        <path d="M1093.72 267.838V184.416L1087.35 182.025V265.447L1093.72 267.838Z"
                              fill="url(#paint136_radial_27_25)"/>
                        <path d="M1093.72 267.838V184.416L1087.35 182.025V265.447L1093.72 267.838Z"
                              fill="url(#paint137_radial_27_25)"/>
                        <path d="M1093.72 267.838V184.416L1087.35 182.025V265.447L1093.72 267.838Z" stroke="#E383FF"/>
                        <path d="M1103.69 270.915V187.493L1097.31 185.102V268.524L1103.69 270.915Z"
                              fill="url(#paint138_radial_27_25)"/>
                        <path d="M1103.69 270.915V187.493L1097.31 185.102V268.524L1103.69 270.915Z"
                              fill="url(#paint139_radial_27_25)"/>
                        <path d="M1103.69 270.915V187.493L1097.31 185.102V268.524L1103.69 270.915Z"
                              fill="url(#paint140_radial_27_25)"/>
                        <path d="M1103.69 270.915V187.493L1097.31 185.102V268.524L1103.69 270.915Z" stroke="#E383FF"/>
                        <path d="M1052.31 251.743V168.321L1045.93 165.93V249.352L1052.31 251.743Z"
                              fill="url(#paint141_radial_27_25)"/>
                        <path d="M1052.31 251.743V168.321L1045.93 165.93V249.352L1052.31 251.743Z"
                              fill="url(#paint142_radial_27_25)"/>
                        <path d="M1052.31 251.743V168.321L1045.93 165.93V249.352L1052.31 251.743Z"
                              fill="url(#paint143_radial_27_25)"/>
                        <path d="M1052.31 251.743V168.321L1045.93 165.93V249.352L1052.31 251.743Z" stroke="#E383FF"/>
                        <path d="M1052.31 251.743V168.321L1045.93 165.93V249.352L1052.31 251.743Z"
                              fill="url(#paint144_radial_27_25)"/>
                        <path d="M1052.31 251.743V168.321L1045.93 165.93V249.352L1052.31 251.743Z"
                              fill="url(#paint145_radial_27_25)"/>
                        <path d="M1052.31 251.743V168.321L1045.93 165.93V249.352L1052.31 251.743Z"
                              fill="url(#paint146_radial_27_25)"/>
                        <path d="M1052.31 251.743V168.321L1045.93 165.93V249.352L1052.31 251.743Z" stroke="#E383FF"/>
                        <path d="M1094.36 368.42V280.975L1087.98 278.584V366.029L1094.36 368.42Z"
                              fill="url(#paint147_radial_27_25)" stroke="#865EFF"/>
                        <path d="M1084.03 364.396V276.952L1077.65 274.561V362.005L1084.03 364.396Z"
                              fill="url(#paint148_radial_27_25)" stroke="#865EFF"/>
                        <path d="M1073.7 360.373V272.928L1067.33 270.537V357.982L1073.7 360.373Z"
                              fill="url(#paint149_radial_27_25)" stroke="#865EFF"/>
                        <path d="M1063.38 356.349V268.905L1057 266.514V353.958L1063.38 356.349Z"
                              fill="url(#paint150_radial_27_25)" stroke="#865EFF"/>
                        <path d="M1052.31 352.326V264.882L1045.93 262.491V349.935L1052.31 352.326Z"
                              fill="url(#paint151_radial_27_25)" stroke="#865EFF"/>
                        <path d="M1041.98 348.303V260.858L1035.61 258.467V345.912L1041.98 348.303Z"
                              fill="url(#paint152_radial_27_25)" stroke="#865EFF"/>
                        <path d="M1209.91 415.271L1273.73 390.589V241.583L1209.91 266.21V415.271Z" fill="#1A1826"
                              stroke="#865EFF"/>
                        <path d="M1145.09 390.633L1208.91 415.271V266.217L1145.09 241.583V390.633Z" fill="#1A1826"/>
                        <path d="M1145.09 390.633L1208.91 415.271V266.217L1145.09 241.583V390.633Z"
                              fill="url(#paint153_linear_27_25)" fill-opacity="0.2"/>
                        <path d="M1145.09 390.633L1208.91 415.271V266.217L1145.09 241.583V390.633Z" stroke="#865EFF"/>
                        <path d="M1204.01 271.973L1196.29 269.109V407.088L1204.01 409.951V271.973Z"
                              fill="url(#paint154_radial_27_25)"/>
                        <path d="M1188.19 265.984L1180.47 263.121L1180.09 400.839L1187.8 403.703L1188.19 265.984Z"
                              fill="url(#paint155_radial_27_25)"/>
                        <path d="M1172.37 259.997L1164.65 257.133V395.112L1172.37 397.975V259.997Z"
                              fill="url(#paint156_radial_27_25)"/>
                        <path d="M1156.94 254.009L1149.22 251.146V388.864L1156.94 391.728V254.009Z"
                              fill="url(#paint157_radial_27_25)"/>
                        <path d="M1156.94 254.009L1149.22 251.146V388.864L1156.94 391.728V254.009Z"
                              fill="url(#paint158_radial_27_25)"/>
                        <path d="M1172.31 260.128L1164.6 257.264V394.982L1172.31 397.846V260.128Z"
                              fill="url(#paint159_radial_27_25)"/>
                        <path d="M1187.69 266.246L1179.97 263.382V401.101L1187.69 403.964V266.246Z"
                              fill="url(#paint160_radial_27_25)"/>
                        <path d="M1204.11 272.395L1196.39 269.531V407.249L1204.11 410.113V272.395Z"
                              fill="url(#paint161_radial_27_25)"/>
                        <path d="M1269.6 250.885L1261.5 254.009V391.467L1269.6 388.343V250.885Z" fill="#865EFF"/>
                        <path d="M1253.39 257.133L1245.29 260.257V397.715L1253.39 394.591V257.133Z" fill="#865EFF"/>
                        <path d="M1237.19 263.121L1229.09 266.245V403.963L1237.19 400.839V263.121Z" fill="#865EFF"/>
                        <path d="M1221.76 269.109L1213.65 272.233V410.212L1221.76 407.088L1221.76 269.109Z"
                              fill="url(#paint162_radial_27_25)"/>
                        <path d="M1253.43 258.007L1245.33 261.131V399.11L1253.43 395.986L1253.43 258.007Z"
                              fill="url(#paint163_radial_27_25)"/>
                        <path d="M1269.54 250.563L1261.44 253.687V391.666L1269.54 388.542L1269.54 250.563Z"
                              fill="url(#paint164_radial_27_25)"/>
                        <path d="M1237.38 263.212L1229.28 266.336V404.315L1237.38 401.191L1237.38 263.212Z"
                              fill="url(#paint165_radial_27_25)"/>
                        <path d="M1209.41 216L1144.59 240.854L1209.41 265.709L1274.23 240.854L1209.41 216Z"
                              fill="#865EFF"/>
                        <path d="M1209.41 220.272L1155.25 241.049L1209.41 261.825L1263.57 241.049L1209.41 220.272Z"
                              fill="url(#paint166_radial_27_25)"/>
                        <path d="M1209.41 228.816L1188.09 236.971L1209.41 245.127L1230.73 236.971L1209.41 228.816Z"
                              fill="#865EFF"/>
                        <path d="M1230.73 236.971L1209.41 245.256V249.398L1230.73 241.113V236.971Z"
                              fill="url(#paint167_linear_27_25)"/>
                        <path d="M1209.41 245.256L1188.09 236.971V241.113L1209.41 249.398V245.256Z"
                              fill="url(#paint168_linear_27_25)"/>
                        <path d="M1298.39 450.27L1355.73 428.096V294.098L1298.39 316.223V450.27Z" fill="#1A1826"
                              stroke="#865EFF"/>
                        <path d="M1240.05 428.135L1297.39 450.271V316.23L1240.05 294.098V428.135Z" fill="#1A1826"/>
                        <path d="M1240.05 428.135L1297.39 450.271V316.23L1240.05 294.098V428.135Z"
                              fill="url(#paint169_linear_27_25)" fill-opacity="0.2"/>
                        <path d="M1240.05 428.135L1297.39 450.271V316.23L1240.05 294.098V428.135Z" stroke="#865EFF"/>
                        <path d="M1293.03 321.375L1286.09 318.798V442.979L1293.03 445.556V321.375Z"
                              fill="url(#paint170_radial_27_25)"/>
                        <path d="M1278.79 315.986L1271.85 313.409L1271.5 437.355L1278.45 439.933L1278.79 315.986Z"
                              fill="url(#paint171_radial_27_25)"/>
                        <path d="M1264.56 310.597L1257.61 308.02V432.201L1264.56 434.778V310.597Z"
                              fill="url(#paint172_radial_27_25)"/>
                        <path d="M1250.67 305.209L1243.72 302.631V426.578L1250.67 429.155V305.209Z"
                              fill="url(#paint173_radial_27_25)"/>
                        <path d="M1250.67 305.209L1243.72 302.631V426.578L1250.67 429.155V305.209Z"
                              fill="url(#paint174_radial_27_25)"/>
                        <path d="M1264.5 310.715L1257.56 308.138V432.084L1264.5 434.661V310.715Z"
                              fill="url(#paint175_radial_27_25)"/>
                        <path d="M1278.34 316.222L1271.4 313.644V437.591L1278.34 440.168V316.222Z"
                              fill="url(#paint176_radial_27_25)"/>
                        <path d="M1293.12 321.756L1286.17 319.178V443.125L1293.12 445.702V321.756Z"
                              fill="url(#paint177_radial_27_25)"/>
                        <path d="M1352.06 302.397L1344.77 305.209V428.921L1352.06 426.109V302.397Z" fill="#865EFF"/>
                        <path d="M1337.48 308.02L1330.18 310.831V434.544L1337.48 431.732V308.02Z" fill="#865EFF"/>
                        <path d="M1322.89 313.409L1315.6 316.221V440.167L1322.89 437.356V313.409Z" fill="#865EFF"/>
                        <path d="M1309 318.798L1301.71 321.61V445.79L1309 442.979L1309 318.798Z"
                              fill="url(#paint178_radial_27_25)"/>
                        <path d="M1337.51 308.807L1330.22 311.618V435.799L1337.51 432.987L1337.51 308.807Z"
                              fill="url(#paint179_radial_27_25)"/>
                        <path d="M1352.01 302.107L1344.71 304.919V429.099L1352.01 426.288L1352.01 302.107Z"
                              fill="url(#paint180_radial_27_25)"/>
                        <path d="M1323.07 313.491L1315.78 316.303V440.483L1323.07 437.672L1323.07 313.491Z"
                              fill="url(#paint181_radial_27_25)"/>
                        <path d="M1297.89 271L1239.55 293.369L1297.89 315.738L1356.23 293.369L1297.89 271Z"
                              fill="#865EFF"/>
                        <path d="M1297.89 274.845L1249.15 293.544L1297.89 312.243L1346.63 293.544L1297.89 274.845Z"
                              fill="url(#paint182_radial_27_25)"/>
                        <path d="M1297.89 282.534L1278.7 289.874L1297.89 297.214L1317.08 289.874L1297.89 282.534Z"
                              fill="#865EFF"/>
                        <path d="M1317.08 289.874L1297.89 297.33V301.058L1317.08 293.602V289.874Z"
                              fill="url(#paint183_linear_27_25)"/>
                        <path d="M1297.89 297.33L1278.7 289.874V293.602L1297.89 301.058V297.33Z"
                              fill="url(#paint184_linear_27_25)"/>
                        <path d="M1417.11 496.269L1498.73 464.472V368.933L1417.11 400.73V496.269Z"
                              fill="url(#paint185_linear_27_25)" stroke="#865EFF"/>
                        <path d="M1334.49 464.472L1416.11 496.269V400.782L1334.49 368.933V464.472Z"
                              fill="url(#paint186_linear_27_25)" stroke="#865EFF"/>
                        <path d="M1416.61 399.851L1497.85 368.201L1416.61 336.552L1335.37 368.201L1416.61 399.851Z"
                              fill="url(#paint187_linear_27_25)" stroke="#E383FF"/>
                        <path d="M1417.11 395.634L1488.4 367.86V276.398L1417.11 304.171V395.634Z"
                              fill="url(#paint188_linear_27_25)" stroke="#E383FF"/>
                        <path d="M1344.82 367.86L1416.11 395.634V304.171L1344.82 276.398V367.86Z"
                              fill="url(#paint189_linear_27_25)" stroke="#E383FF"/>
                        <path d="M1416.61 303.293L1487.53 275.662L1416.61 247.538L1345.69 275.662L1416.61 303.293Z"
                              fill="#E383FF" stroke="#E383FF"/>
                        <path d="M1416.61 299.269L1476.11 275.666L1416.61 252.064L1356.03 275.666L1416.61 299.269Z"
                              fill="url(#paint190_radial_27_25)" stroke="#E383FF"/>
                        <path d="M1416.61 299.269L1476.11 275.666L1416.61 252.064L1356.03 275.666L1416.61 299.269Z"
                              fill="url(#paint191_radial_27_25)" stroke="#E383FF"/>
                        <path d="M1416.61 279.655L1435.89 272.145L1416.61 264.635L1397.33 272.145L1416.61 279.655Z"
                              fill="#E383FF" stroke="#E383FF"/>
                        <path d="M1436.77 275.827V272.877L1417.11 280.534V283.484L1436.77 275.827Z" fill="#5F7CBA"
                              stroke="#E383FF"/>
                        <path d="M1416.11 283.484V280.534L1396.46 272.877V275.827L1416.11 283.484Z"
                              fill="url(#paint192_radial_27_25)" stroke="#E383FF"/>
                        <path d="M1493.57 462.408V374.969L1486.82 377.597V465.036L1493.57 462.408Z"
                              fill="url(#paint193_radial_27_25)" stroke="#865EFF"/>
                        <path d="M1483.24 365.848V282.432L1476.49 285.059V368.476L1483.24 365.848Z"
                              fill="url(#paint194_radial_27_25)" stroke="#E383FF"/>
                        <path d="M1472.91 369.872V286.456L1466.17 289.084V372.5L1472.91 369.872Z"
                              fill="url(#paint195_radial_27_25)" stroke="#E383FF"/>
                        <path d="M1462.58 373.895V290.478L1455.84 293.106V376.522L1462.58 373.895Z"
                              fill="url(#paint196_radial_27_25)" stroke="#E383FF"/>
                        <path d="M1452.26 377.919V294.503L1445.51 297.131V380.547L1452.26 377.919Z"
                              fill="url(#paint197_radial_27_25)" stroke="#E383FF"/>
                        <path d="M1441.93 381.941V298.525L1435.18 301.153V384.569L1441.93 381.941Z"
                              fill="url(#paint198_radial_27_25)" stroke="#E383FF"/>
                        <path d="M1431.6 385.965V302.549L1424.86 305.177V388.593L1431.6 385.965Z"
                              fill="url(#paint199_radial_27_25)" stroke="#E383FF"/>
                        <path d="M1483.24 466.431V378.992L1476.49 381.62V469.059L1483.24 466.431Z"
                              fill="url(#paint200_radial_27_25)" stroke="#865EFF"/>
                        <path d="M1472.91 470.455V383.015L1466.17 385.643V473.082L1472.91 470.455Z"
                              fill="url(#paint201_radial_27_25)" stroke="#865EFF"/>
                        <path d="M1462.58 474.478V387.039L1455.84 389.666V477.106L1462.58 474.478Z"
                              fill="url(#paint202_radial_27_25)" stroke="#865EFF"/>
                        <path d="M1452.26 478.501V391.061L1445.51 393.689V481.129L1452.26 478.501Z"
                              fill="url(#paint203_radial_27_25)" stroke="#865EFF"/>
                        <path d="M1441.93 482.525V395.085L1435.18 397.713V485.153L1441.93 482.525Z"
                              fill="url(#paint204_radial_27_25)" stroke="#865EFF"/>
                        <path d="M1431.6 486.548V399.109L1424.86 401.736V489.176L1431.6 486.548Z"
                              fill="url(#paint205_radial_27_25)" stroke="#865EFF"/>
                        <path d="M1410.95 490.443V402.998L1404.57 400.607V488.052L1410.95 490.443Z"
                              fill="url(#paint206_radial_27_25)" stroke="#865EFF"/>
                        <path d="M1410.95 389.86V306.439L1404.57 304.048V387.469L1410.95 389.86Z"
                              fill="url(#paint207_radial_27_25)" stroke="#E383FF"/>
                        <path d="M1400.62 385.837V302.415L1394.24 300.024V383.446L1400.62 385.837Z"
                              fill="url(#paint208_radial_27_25)" stroke="#E383FF"/>
                        <path d="M1390.29 381.31V297.889L1383.92 295.498V378.919L1390.29 381.31Z"
                              fill="url(#paint209_radial_27_25)" stroke="#E383FF"/>
                        <path d="M1379.97 377.79V294.368L1373.59 291.977V375.399L1379.97 377.79Z"
                              fill="url(#paint210_radial_27_25)" stroke="#E383FF"/>
                        <path d="M1369.64 373.766V290.345L1363.26 287.954V371.375L1369.64 373.766Z"
                              fill="url(#paint211_radial_27_25)"/>
                        <path d="M1369.64 373.766V290.345L1363.26 287.954V371.375L1369.64 373.766Z"
                              fill="url(#paint212_radial_27_25)"/>
                        <path d="M1369.64 373.766V290.345L1363.26 287.954V371.375L1369.64 373.766Z"
                              fill="url(#paint213_radial_27_25)"/>
                        <path d="M1369.64 373.766V290.345L1363.26 287.954V371.375L1369.64 373.766Z" stroke="#E383FF"/>
                        <path d="M1379.96 377.633V294.212L1373.58 291.821V375.242L1379.96 377.633Z"
                              fill="url(#paint214_radial_27_25)"/>
                        <path d="M1379.96 377.633V294.212L1373.58 291.821V375.242L1379.96 377.633Z"
                              fill="url(#paint215_radial_27_25)"/>
                        <path d="M1379.96 377.633V294.212L1373.58 291.821V375.242L1379.96 377.633Z"
                              fill="url(#paint216_radial_27_25)"/>
                        <path d="M1379.96 377.633V294.212L1373.58 291.821V375.242L1379.96 377.633Z" stroke="#E383FF"/>
                        <path d="M1389.92 380.71V297.289L1383.55 294.898V378.319L1389.92 380.71Z"
                              fill="url(#paint217_radial_27_25)"/>
                        <path d="M1389.92 380.71V297.289L1383.55 294.898V378.319L1389.92 380.71Z"
                              fill="url(#paint218_radial_27_25)"/>
                        <path d="M1389.92 380.71V297.289L1383.55 294.898V378.319L1389.92 380.71Z"
                              fill="url(#paint219_radial_27_25)"/>
                        <path d="M1389.92 380.71V297.289L1383.55 294.898V378.319L1389.92 380.71Z" stroke="#E383FF"/>
                        <path d="M1389.92 380.71V297.289L1383.55 294.898V378.319L1389.92 380.71Z"
                              fill="url(#paint220_radial_27_25)"/>
                        <path d="M1389.92 380.71V297.289L1383.55 294.898V378.319L1389.92 380.71Z"
                              fill="url(#paint221_radial_27_25)"/>
                        <path d="M1389.92 380.71V297.289L1383.55 294.898V378.319L1389.92 380.71Z"
                              fill="url(#paint222_radial_27_25)"/>
                        <path d="M1389.92 380.71V297.289L1383.55 294.898V378.319L1389.92 380.71Z" stroke="#E383FF"/>
                        <path d="M1389.92 380.71V297.289L1383.55 294.898V378.319L1389.92 380.71Z"
                              fill="url(#paint223_radial_27_25)"/>
                        <path d="M1389.92 380.71V297.289L1383.55 294.898V378.319L1389.92 380.71Z"
                              fill="url(#paint224_radial_27_25)"/>
                        <path d="M1389.92 380.71V297.289L1383.55 294.898V378.319L1389.92 380.71Z"
                              fill="url(#paint225_radial_27_25)"/>
                        <path d="M1389.92 380.71V297.289L1383.55 294.898V378.319L1389.92 380.71Z" stroke="#E383FF"/>
                        <path d="M1400.72 385.838V302.416L1394.35 300.025V383.447L1400.72 385.838Z"
                              fill="url(#paint226_radial_27_25)"/>
                        <path d="M1400.72 385.838V302.416L1394.35 300.025V383.447L1400.72 385.838Z"
                              fill="url(#paint227_radial_27_25)"/>
                        <path d="M1400.72 385.838V302.416L1394.35 300.025V383.447L1400.72 385.838Z"
                              fill="url(#paint228_radial_27_25)"/>
                        <path d="M1400.72 385.838V302.416L1394.35 300.025V383.447L1400.72 385.838Z" stroke="#E383FF"/>
                        <path d="M1410.69 388.915V305.493L1404.31 303.102V386.524L1410.69 388.915Z"
                              fill="url(#paint229_radial_27_25)"/>
                        <path d="M1410.69 388.915V305.493L1404.31 303.102V386.524L1410.69 388.915Z"
                              fill="url(#paint230_radial_27_25)"/>
                        <path d="M1410.69 388.915V305.493L1404.31 303.102V386.524L1410.69 388.915Z"
                              fill="url(#paint231_radial_27_25)"/>
                        <path d="M1410.69 388.915V305.493L1404.31 303.102V386.524L1410.69 388.915Z" stroke="#E383FF"/>
                        <path d="M1359.31 369.743V286.321L1352.93 283.93V367.352L1359.31 369.743Z"
                              fill="url(#paint232_radial_27_25)"/>
                        <path d="M1359.31 369.743V286.321L1352.93 283.93V367.352L1359.31 369.743Z"
                              fill="url(#paint233_radial_27_25)"/>
                        <path d="M1359.31 369.743V286.321L1352.93 283.93V367.352L1359.31 369.743Z"
                              fill="url(#paint234_radial_27_25)"/>
                        <path d="M1359.31 369.743V286.321L1352.93 283.93V367.352L1359.31 369.743Z" stroke="#E383FF"/>
                        <path d="M1359.31 369.743V286.321L1352.93 283.93V367.352L1359.31 369.743Z"
                              fill="url(#paint235_radial_27_25)"/>
                        <path d="M1359.31 369.743V286.321L1352.93 283.93V367.352L1359.31 369.743Z"
                              fill="url(#paint236_radial_27_25)"/>
                        <path d="M1359.31 369.743V286.321L1352.93 283.93V367.352L1359.31 369.743Z"
                              fill="url(#paint237_radial_27_25)"/>
                        <path d="M1359.31 369.743V286.321L1352.93 283.93V367.352L1359.31 369.743Z" stroke="#E383FF"/>
                        <path d="M1401.36 486.42V398.975L1394.98 396.584V484.029L1401.36 486.42Z"
                              fill="url(#paint238_radial_27_25)" stroke="#865EFF"/>
                        <path d="M1391.03 482.396V394.952L1384.65 392.561V480.005L1391.03 482.396Z"
                              fill="url(#paint239_radial_27_25)" stroke="#865EFF"/>
                        <path d="M1380.7 478.373V390.928L1374.33 388.537V475.982L1380.7 478.373Z"
                              fill="url(#paint240_radial_27_25)" stroke="#865EFF"/>
                        <path d="M1370.38 474.349V386.905L1364 384.514V471.958L1370.38 474.349Z"
                              fill="url(#paint241_radial_27_25)" stroke="#865EFF"/>
                        <path d="M1359.31 470.326V382.882L1352.93 380.491V467.935L1359.31 470.326Z"
                              fill="url(#paint242_radial_27_25)" stroke="#865EFF"/>
                        <path d="M1348.98 466.303V378.858L1342.61 376.467V463.912L1348.98 466.303Z"
                              fill="url(#paint243_radial_27_25)" stroke="#865EFF"/>
                        <path d="M1536.71 544.27L1616.73 513.322V326.797L1536.71 357.677V544.27Z" fill="#1A1826"
                              stroke="#865EFF"/>
                        <path d="M1455.68 513.377L1535.71 544.271V357.686L1455.68 326.797V513.377Z" fill="#1A1826"/>
                        <path d="M1455.68 513.377L1535.71 544.271V357.686L1455.68 326.797V513.377Z"
                              fill="url(#paint244_linear_27_25)" fill-opacity="0.2"/>
                        <path d="M1455.68 513.377L1535.71 544.271V357.686L1455.68 326.797V513.377Z" stroke="#865EFF"/>
                        <path d="M1529.45 364.965L1519.81 361.386V533.859L1529.45 537.438V364.965Z"
                              fill="url(#paint245_radial_27_25)"/>
                        <path d="M1509.68 357.481L1500.03 353.901L1499.55 526.049L1509.2 529.628L1509.68 357.481Z"
                              fill="url(#paint246_radial_27_25)"/>
                        <path d="M1489.91 349.996L1480.26 346.416V518.889L1489.91 522.469V349.996Z"
                              fill="url(#paint247_radial_27_25)"/>
                        <path d="M1470.61 342.512L1460.97 338.932V511.08L1470.61 514.659V342.512Z"
                              fill="url(#paint248_radial_27_25)"/>
                        <path d="M1470.61 342.512L1460.97 338.932V511.08L1470.61 514.659V342.512Z"
                              fill="url(#paint249_radial_27_25)"/>
                        <path d="M1489.83 350.16L1480.19 346.58V518.728L1489.83 522.307V350.16Z"
                              fill="url(#paint250_radial_27_25)"/>
                        <path d="M1509.05 357.808L1499.41 354.228V526.376L1509.05 529.955V357.808Z"
                              fill="url(#paint251_radial_27_25)"/>
                        <path d="M1529.57 365.494L1519.93 361.914V534.062L1529.57 537.642V365.494Z"
                              fill="url(#paint252_radial_27_25)"/>
                        <path d="M1611.44 338.607L1601.31 342.512V514.334L1611.44 510.429V338.607Z" fill="#865EFF"/>
                        <path d="M1591.18 346.416L1581.06 350.321V522.144L1591.18 518.239V346.416Z" fill="#865EFF"/>
                        <path d="M1570.93 353.901L1560.8 357.806V529.954L1570.93 526.049V353.901Z" fill="#865EFF"/>
                        <path d="M1551.64 361.386L1541.51 365.291V537.764L1551.64 533.859L1551.64 361.386Z"
                              fill="url(#paint253_radial_27_25)"/>
                        <path d="M1591.23 347.509L1581.1 351.414V523.887L1591.23 519.982L1591.23 347.509Z"
                              fill="url(#paint254_radial_27_25)"/>
                        <path d="M1611.37 338.204L1601.24 342.109V514.582L1611.37 510.677L1611.37 338.204Z"
                              fill="url(#paint255_radial_27_25)"/>
                        <path d="M1571.17 354.015L1561.04 357.92V530.393L1571.17 526.488L1571.17 354.015Z"
                              fill="url(#paint256_radial_27_25)"/>
                        <path d="M1536.21 295L1455.18 326.068L1536.21 357.136L1617.23 326.068L1536.21 295Z"
                              fill="#865EFF"/>
                        <path d="M1536.21 300.34L1468.51 326.311L1536.21 352.281L1603.9 326.311L1536.21 300.34Z"
                              fill="url(#paint257_radial_27_25)"/>
                        <path d="M1536.2 311.02L1509.55 321.214L1536.2 331.408L1562.86 321.214L1536.2 311.02Z"
                              fill="#865EFF"/>
                        <path d="M1562.86 321.214L1536.2 331.57V336.748L1562.86 326.392V321.214Z"
                              fill="url(#paint258_linear_27_25)"/>
                        <path d="M1536.21 331.57L1509.55 321.214V326.392L1536.21 336.748V331.57Z"
                              fill="url(#paint259_linear_27_25)"/>
                        <path d="M370.469 366.268L252.5 320.309V182.261L370.469 228.22V366.268Z"
                              fill="url(#paint260_linear_27_25)" stroke="#865EFF"/>
                        <path d="M489.438 320.309L371.469 366.268V228.296L489.438 182.261V320.309Z"
                              fill="url(#paint261_linear_27_25)" stroke="#865EFF"/>
                        <path d="M370.969 227.342L253.377 181.53L370.969 135.718L488.561 181.53L370.969 227.342Z"
                              fill="url(#paint262_linear_27_25)" stroke="#E383FF"/>
                        <path d="M370.469 221.354L267.371 181.188V49.0099L370.469 89.1752V221.354Z"
                              fill="url(#paint263_linear_27_25)" stroke="#E383FF"/>
                        <path d="M474.567 181.188L371.469 221.354V89.1752L474.567 49.0099V181.188Z"
                              fill="url(#paint264_linear_27_25)" stroke="#E383FF"/>
                        <path d="M370.969 88.2973L268.238 48.2749L370.969 7.53788L473.7 48.2749L370.969 88.2973Z"
                              fill="#E383FF" stroke="#E383FF"/>
                        <path d="M370.971 82.5032L284.692 48.2791L370.971 14.055L458.819 48.2791L370.971 82.5032Z"
                              fill="url(#paint265_radial_27_25)" stroke="#E383FF"/>
                        <path d="M370.971 82.5032L284.692 48.2791L370.971 14.055L458.819 48.2791L370.971 82.5032Z"
                              fill="url(#paint266_radial_27_25)" stroke="#E383FF"/>
                        <path d="M370.969 54.2595L342.604 43.209L370.969 32.1585L399.334 43.209L370.969 54.2595Z"
                              fill="#E383FF" stroke="#E383FF"/>
                        <path d="M341.727 48.6608V43.9405L370.469 55.138V59.8583L341.727 48.6608Z" fill="#5F7CBA"
                              stroke="#E383FF"/>
                        <path d="M371.469 59.8583V55.138L400.211 43.9405V48.6608L371.469 59.8583Z"
                              fill="url(#paint267_radial_27_25)" stroke="#E383FF"/>
                        <path d="M259.936 317.338V190.953L270.089 194.909V321.294L259.936 317.338Z"
                              fill="url(#paint268_radial_27_25)" stroke="#865EFF"/>
                        <path d="M274.807 178.291V57.6995L284.96 61.6551V182.246L274.807 178.291Z"
                              fill="url(#paint269_radial_27_25)" stroke="#E383FF"/>
                        <path d="M289.678 184.086V63.4943L299.831 67.4499V188.041L289.678 184.086Z"
                              fill="url(#paint270_radial_27_25)" stroke="#E383FF"/>
                        <path d="M304.549 189.878V69.2868L314.702 73.2423V193.834L304.549 189.878Z"
                              fill="url(#paint271_radial_27_25)" stroke="#E383FF"/>
                        <path d="M319.42 195.673V75.082L329.573 79.0376V199.629L319.42 195.673Z"
                              fill="url(#paint272_radial_27_25)" stroke="#E383FF"/>
                        <path d="M334.291 201.466V80.8744L344.444 84.83V205.421L334.291 201.466Z"
                              fill="url(#paint273_radial_27_25)" stroke="#E383FF"/>
                        <path d="M349.162 207.26V86.6683L359.315 90.6239V211.215L349.162 207.26Z"
                              fill="url(#paint274_radial_27_25)" stroke="#E383FF"/>
                        <path d="M274.807 323.131V196.746L284.96 200.701V327.086L274.807 323.131Z"
                              fill="url(#paint275_radial_27_25)" stroke="#865EFF"/>
                        <path d="M289.678 328.925V202.54L299.831 206.495V332.88L289.678 328.925Z"
                              fill="url(#paint276_radial_27_25)" stroke="#865EFF"/>
                        <path d="M304.549 334.718V208.333L314.702 212.289V338.674L304.549 334.718Z"
                              fill="url(#paint277_radial_27_25)" stroke="#865EFF"/>
                        <path d="M319.42 340.511V214.126L329.573 218.082V344.467L319.42 340.511Z"
                              fill="url(#paint278_radial_27_25)" stroke="#865EFF"/>
                        <path d="M334.291 346.306V219.921L344.444 223.877V350.262L334.291 346.306Z"
                              fill="url(#paint279_radial_27_25)" stroke="#865EFF"/>
                        <path d="M349.162 352.099V225.714L359.315 229.67V356.055L349.162 352.099Z"
                              fill="url(#paint280_radial_27_25)" stroke="#865EFF"/>
                        <path d="M378.904 357.875V231.485L388.527 227.877V354.267L378.904 357.875Z"
                              fill="url(#paint281_radial_27_25)" stroke="#865EFF"/>
                        <path d="M378.904 213.036V92.4392L388.527 88.8311V209.428L378.904 213.036Z"
                              fill="url(#paint282_radial_27_25)" stroke="#E383FF"/>
                        <path d="M393.775 207.242V86.6454L403.398 83.0373V203.634L393.775 207.242Z"
                              fill="url(#paint283_radial_27_25)" stroke="#E383FF"/>
                        <path d="M408.646 200.724V80.1271L418.269 76.519V197.116L408.646 200.724Z"
                              fill="url(#paint284_radial_27_25)" stroke="#E383FF"/>
                        <path d="M423.518 195.654V75.0577L433.14 71.4496V192.046L423.518 195.654Z"
                              fill="url(#paint285_radial_27_25)" stroke="#E383FF"/>
                        <path d="M438.39 189.861V69.264L448.012 65.6559V186.253L438.39 189.861Z"
                              fill="url(#paint286_radial_27_25)"/>
                        <path d="M438.39 189.861V69.264L448.012 65.6559V186.253L438.39 189.861Z"
                              fill="url(#paint287_radial_27_25)"/>
                        <path d="M438.39 189.861V69.264L448.012 65.6559V186.253L438.39 189.861Z"
                              fill="url(#paint288_radial_27_25)"/>
                        <path d="M438.39 189.861V69.264L448.012 65.6559V186.253L438.39 189.861Z" stroke="#E383FF"/>
                        <path d="M423.53 195.43V74.8331L433.153 71.225V191.822L423.53 195.43Z"
                              fill="url(#paint289_radial_27_25)"/>
                        <path d="M423.53 195.43V74.8331L433.153 71.225V191.822L423.53 195.43Z"
                              fill="url(#paint290_radial_27_25)"/>
                        <path d="M423.53 195.43V74.8331L433.153 71.225V191.822L423.53 195.43Z"
                              fill="url(#paint291_radial_27_25)"/>
                        <path d="M423.53 195.43V74.8331L433.153 71.225V191.822L423.53 195.43Z" stroke="#E383FF"/>
                        <path d="M409.178 199.861V79.2641L418.8 75.656V196.253L409.178 199.861Z"
                              fill="url(#paint292_radial_27_25)"/>
                        <path d="M409.178 199.861V79.2641L418.8 75.656V196.253L409.178 199.861Z"
                              fill="url(#paint293_radial_27_25)"/>
                        <path d="M409.178 199.861V79.2641L418.8 75.656V196.253L409.178 199.861Z"
                              fill="url(#paint294_radial_27_25)"/>
                        <path d="M409.178 199.861V79.2641L418.8 75.656V196.253L409.178 199.861Z" stroke="#E383FF"/>
                        <path d="M409.178 199.861V79.2641L418.8 75.656V196.253L409.178 199.861Z"
                              fill="url(#paint295_radial_27_25)"/>
                        <path d="M409.178 199.861V79.2641L418.8 75.656V196.253L409.178 199.861Z"
                              fill="url(#paint296_radial_27_25)"/>
                        <path d="M409.178 199.861V79.2641L418.8 75.656V196.253L409.178 199.861Z"
                              fill="url(#paint297_radial_27_25)"/>
                        <path d="M409.178 199.861V79.2641L418.8 75.656V196.253L409.178 199.861Z" stroke="#E383FF"/>
                        <path d="M409.178 199.861V79.2641L418.8 75.656V196.253L409.178 199.861Z"
                              fill="url(#paint298_radial_27_25)"/>
                        <path d="M409.178 199.861V79.2641L418.8 75.656V196.253L409.178 199.861Z"
                              fill="url(#paint299_radial_27_25)"/>
                        <path d="M409.178 199.861V79.2641L418.8 75.656V196.253L409.178 199.861Z"
                              fill="url(#paint300_radial_27_25)"/>
                        <path d="M409.178 199.861V79.2641L418.8 75.656V196.253L409.178 199.861Z" stroke="#E383FF"/>
                        <path d="M393.63 207.244V86.6469L403.252 83.0388V203.635L393.63 207.244Z"
                              fill="url(#paint301_radial_27_25)"/>
                        <path d="M393.63 207.244V86.6469L403.252 83.0388V203.635L393.63 207.244Z"
                              fill="url(#paint302_radial_27_25)"/>
                        <path d="M393.63 207.244V86.6469L403.252 83.0388V203.635L393.63 207.244Z"
                              fill="url(#paint303_radial_27_25)"/>
                        <path d="M393.63 207.244V86.6469L403.252 83.0388V203.635L393.63 207.244Z" stroke="#E383FF"/>
                        <path d="M379.277 211.675V91.078L388.9 87.4699V208.067L379.277 211.675Z"
                              fill="url(#paint304_radial_27_25)"/>
                        <path d="M379.277 211.675V91.078L388.9 87.4699V208.067L379.277 211.675Z"
                              fill="url(#paint305_radial_27_25)"/>
                        <path d="M379.277 211.675V91.078L388.9 87.4699V208.067L379.277 211.675Z"
                              fill="url(#paint306_radial_27_25)"/>
                        <path d="M379.277 211.675V91.078L388.9 87.4699V208.067L379.277 211.675Z" stroke="#E383FF"/>
                        <path d="M453.26 184.067V63.4704L462.882 59.8624V180.459L453.26 184.067Z"
                              fill="url(#paint307_radial_27_25)"/>
                        <path d="M453.26 184.067V63.4704L462.882 59.8624V180.459L453.26 184.067Z"
                              fill="url(#paint308_radial_27_25)"/>
                        <path d="M453.26 184.067V63.4704L462.882 59.8624V180.459L453.26 184.067Z"
                              fill="url(#paint309_radial_27_25)"/>
                        <path d="M453.26 184.067V63.4704L462.882 59.8624V180.459L453.26 184.067Z" stroke="#E383FF"/>
                        <path d="M453.26 184.067V63.4704L462.882 59.8624V180.459L453.26 184.067Z"
                              fill="url(#paint310_radial_27_25)"/>
                        <path d="M453.26 184.067V63.4704L462.882 59.8624V180.459L453.26 184.067Z"
                              fill="url(#paint311_radial_27_25)"/>
                        <path d="M453.26 184.067V63.4704L462.882 59.8624V180.459L453.26 184.067Z"
                              fill="url(#paint312_radial_27_25)"/>
                        <path d="M453.26 184.067V63.4704L462.882 59.8624V180.459L453.26 184.067Z" stroke="#E383FF"/>
                        <path d="M392.713 352.081V225.691L402.335 222.083V348.473L392.713 352.081Z"
                              fill="url(#paint313_radial_27_25)" stroke="#865EFF"/>
                        <path d="M407.584 346.288V219.898L417.206 216.29V342.68L407.584 346.288Z"
                              fill="url(#paint314_radial_27_25)" stroke="#865EFF"/>
                        <path d="M422.455 340.494V214.104L432.077 210.496V336.886L422.455 340.494Z"
                              fill="url(#paint315_radial_27_25)" stroke="#865EFF"/>
                        <path d="M437.327 334.7V208.31L446.949 204.702V331.092L437.327 334.7Z"
                              fill="url(#paint316_radial_27_25)" stroke="#865EFF"/>
                        <path d="M453.26 328.907V202.517L462.882 198.909V325.299L453.26 328.907Z"
                              fill="url(#paint317_radial_27_25)" stroke="#865EFF"/>
                        <path d="M468.132 323.113V196.723L477.754 193.115V319.505L468.132 323.113Z"
                              fill="url(#paint318_radial_27_25)" stroke="#865EFF"/>
                        <path d="M145.342 453.271L0.5 397.254V60.6506L145.342 116.544V453.271Z" fill="#1A1826"
                              stroke="#865EFF"/>
                        <path d="M291.185 397.352L146.343 453.271V116.56L291.185 60.6507V397.352Z" fill="#1A1826"/>
                        <path d="M291.185 397.352L146.343 453.271V116.56L291.185 60.6507V397.352Z"
                              fill="url(#paint319_linear_27_25)" fill-opacity="0.2"/>
                        <path d="M291.185 397.352L146.343 453.271V116.56L291.185 60.6507V397.352Z" stroke="#865EFF"/>
                        <path d="M157.997 129.938L175.359 123.494V433.946L157.997 440.39V129.938Z"
                              fill="url(#paint320_radial_27_25)"/>
                        <path d="M193.589 116.465L210.951 110.022L211.819 419.888L194.457 426.331L193.589 116.465Z"
                              fill="url(#paint321_radial_27_25)"/>
                        <path d="M229.182 102.993L246.544 96.5501V407.002L229.182 413.445V102.993Z"
                              fill="url(#paint322_radial_27_25)"/>
                        <path d="M263.905 89.5216L281.267 83.0783V392.944L263.905 399.388V89.5216Z"
                              fill="url(#paint323_radial_27_25)"/>
                        <path d="M263.905 89.5216L281.267 83.0783V392.944L263.905 399.388V89.5216Z"
                              fill="url(#paint324_radial_27_25)"/>
                        <path d="M229.312 103.288L246.675 96.8446V406.711L229.312 413.154V103.288Z"
                              fill="url(#paint325_radial_27_25)"/>
                        <path d="M194.719 117.053L212.081 110.61V420.476L194.719 426.919V117.053Z"
                              fill="url(#paint326_radial_27_25)"/>
                        <path d="M157.777 130.889L175.139 124.446V434.312L157.777 440.755V130.889Z"
                              fill="url(#paint327_radial_27_25)"/>
                        <path d="M10.418 82.4927L28.6482 89.5218V398.802L10.418 391.773V82.4927Z" fill="#865EFF"/>
                        <path d="M46.8789 96.5501L65.1092 103.579V412.86L46.8789 405.831V96.5501Z" fill="#865EFF"/>
                        <path d="M83.3389 110.023L101.569 117.052V426.918L83.3389 419.889V110.023Z" fill="#865EFF"/>
                        <path d="M118.064 123.494L136.295 130.523V440.975L118.064 433.946L118.064 123.494Z"
                              fill="url(#paint328_radial_27_25)"/>
                        <path d="M46.7979 98.5163L65.0281 105.545V415.997L46.7979 408.968L46.7979 98.5163Z"
                              fill="url(#paint329_radial_27_25)"/>
                        <path d="M10.5537 81.7681L28.784 88.7972V399.249L10.5537 392.22L10.5537 81.7681Z"
                              fill="url(#paint330_radial_27_25)"/>
                        <path d="M82.8994 110.228L101.13 117.257V427.709L82.8994 420.68L82.8994 110.228Z"
                              fill="url(#paint331_radial_27_25)"/>
                        <path d="M145.842 4L291.685 59.9224L145.842 115.845L0 59.9224L145.842 4Z" fill="#865EFF"/>
                        <path d="M145.842 13.6122L267.698 60.3598L145.842 107.107L23.9873 60.3598L145.842 13.6122Z"
                              fill="url(#paint332_radial_27_25)"/>
                        <path d="M145.843 32.8351L193.817 51.1847L145.843 69.5342L97.8682 51.1847L145.843 32.8351Z"
                              fill="#865EFF"/>
                        <path d="M97.8682 51.1837L145.843 69.8245V79.1449L97.8682 60.5041V51.1837Z"
                              fill="url(#paint333_linear_27_25)"/>
                        <path d="M145.843 69.8245L193.817 51.1837V60.5041L145.843 79.1449V69.8245Z"
                              fill="url(#paint334_linear_27_25)"/>
                        <path d="M695.114 336.27L647.5 317.856V206.369L695.114 224.743V336.27Z" fill="#1A1826"
                              stroke="#865EFF"/>
                        <path d="M743.728 317.889L696.114 336.271V224.749L743.728 206.37V317.889Z" fill="#1A1826"/>
                        <path d="M743.728 317.889L696.114 336.271V224.749L743.728 206.37V317.889Z"
                              fill="url(#paint335_linear_27_25)" fill-opacity="0.2"/>
                        <path d="M743.728 317.889L696.114 336.271V224.749L743.728 206.37V317.889Z" stroke="#865EFF"/>
                        <path d="M699.666 228.979L705.453 226.832V330.315L699.666 332.463V228.979Z"
                              fill="url(#paint336_radial_27_25)"/>
                        <path d="M711.529 224.488L717.317 222.34L717.606 325.629L711.819 327.777L711.529 224.488Z"
                              fill="url(#paint337_radial_27_25)"/>
                        <path d="M723.394 219.998L729.181 217.85V321.334L723.394 323.482V219.998Z"
                              fill="url(#paint338_radial_27_25)"/>
                        <path d="M734.969 215.507L740.756 213.359V316.648L734.969 318.796V215.507Z"
                              fill="url(#paint339_radial_27_25)"/>
                        <path d="M734.969 215.507L740.756 213.359V316.648L734.969 318.796V215.507Z"
                              fill="url(#paint340_radial_27_25)"/>
                        <path d="M723.438 220.096L729.225 217.948V321.237L723.438 323.385V220.096Z"
                              fill="url(#paint341_radial_27_25)"/>
                        <path d="M711.906 224.684L717.694 222.537V325.825L711.906 327.973V224.684Z"
                              fill="url(#paint342_radial_27_25)"/>
                        <path d="M699.593 229.296L705.38 227.149V330.437L699.593 332.585V229.296Z"
                              fill="url(#paint343_radial_27_25)"/>
                        <path d="M650.473 213.164L656.549 215.507V318.601L650.473 316.258V213.164Z" fill="#865EFF"/>
                        <path d="M662.626 217.85L668.703 220.193V323.286L662.626 320.943V217.85Z" fill="#865EFF"/>
                        <path d="M674.779 222.341L680.856 224.684V327.973L674.779 325.63V222.341Z" fill="#865EFF"/>
                        <path d="M686.354 226.832L692.431 229.175V332.659L686.354 330.315L686.354 226.832Z"
                              fill="url(#paint344_radial_27_25)"/>
                        <path d="M662.6 218.506L668.676 220.849V324.332L662.6 321.989L662.6 218.506Z"
                              fill="url(#paint345_radial_27_25)"/>
                        <path d="M650.518 212.922L656.594 215.265V318.749L650.518 316.406L650.518 212.922Z"
                              fill="url(#paint346_radial_27_25)"/>
                        <path d="M674.633 222.409L680.71 224.752V328.236L674.633 325.893L674.633 222.409Z"
                              fill="url(#paint347_radial_27_25)"/>
                        <path d="M695.614 187L744.228 205.641L695.614 224.282L647 205.641L695.614 187Z" fill="#865EFF"/>
                        <path d="M695.614 190.204L736.233 205.786L695.614 221.369L654.996 205.786L695.614 190.204Z"
                              fill="url(#paint348_radial_27_25)"/>
                        <path d="M695.615 196.612L711.606 202.728L695.615 208.845L679.623 202.728L695.615 196.612Z"
                              fill="#865EFF"/>
                        <path d="M679.623 202.728L695.615 208.942V212.048L679.623 205.835V202.728Z"
                              fill="url(#paint349_linear_27_25)"/>
                        <path d="M695.614 208.942L711.606 202.728V205.835L695.614 212.048V208.942Z"
                              fill="url(#paint350_linear_27_25)"/>
                        <path d="M579.117 382.269L497.5 350.472V254.933L579.117 286.73V382.269Z"
                              fill="url(#paint351_linear_27_25)" stroke="#865EFF"/>
                        <path d="M661.735 350.472L580.117 382.269V286.782L661.735 254.933V350.472Z"
                              fill="url(#paint352_linear_27_25)" stroke="#865EFF"/>
                        <path d="M579.617 285.851L498.377 254.201L579.617 222.552L660.857 254.201L579.617 285.851Z"
                              fill="url(#paint353_linear_27_25)" stroke="#E383FF"/>
                        <path d="M579.117 281.634L507.827 253.86V162.398L579.117 190.171V281.634Z"
                              fill="url(#paint354_linear_27_25)" stroke="#E383FF"/>
                        <path d="M651.407 253.86L580.117 281.634V190.171L651.407 162.398V253.86Z"
                              fill="url(#paint355_linear_27_25)" stroke="#E383FF"/>
                        <path d="M579.617 189.293L508.694 161.662L579.617 133.538L650.541 161.662L579.617 189.293Z"
                              fill="#E383FF" stroke="#E383FF"/>
                        <path d="M579.619 185.269L520.117 161.666L579.619 138.064L640.203 161.666L579.619 185.269Z"
                              fill="url(#paint356_radial_27_25)" stroke="#E383FF"/>
                        <path d="M579.619 185.269L520.117 161.666L579.619 138.064L640.203 161.666L579.619 185.269Z"
                              fill="url(#paint357_radial_27_25)" stroke="#E383FF"/>
                        <path d="M579.617 165.655L560.34 158.145L579.617 150.635L598.894 158.145L579.617 165.655Z"
                              fill="#E383FF" stroke="#E383FF"/>
                        <path d="M559.463 161.827V158.877L579.117 166.534V169.484L559.463 161.827Z" fill="#5F7CBA"
                              stroke="#E383FF"/>
                        <path d="M580.117 169.484V166.534L599.772 158.877V161.827L580.117 169.484Z"
                              fill="url(#paint358_radial_27_25)" stroke="#E383FF"/>
                        <path d="M502.663 348.408V260.969L509.408 263.597V351.036L502.663 348.408Z"
                              fill="url(#paint359_radial_27_25)" stroke="#865EFF"/>
                        <path d="M512.99 251.848V168.432L519.736 171.059V254.476L512.99 251.848Z"
                              fill="url(#paint360_radial_27_25)" stroke="#E383FF"/>
                        <path d="M523.317 255.872V172.456L530.063 175.084V258.5L523.317 255.872Z"
                              fill="url(#paint361_radial_27_25)" stroke="#E383FF"/>
                        <path d="M533.645 259.895V176.478L540.39 179.106V262.522L533.645 259.895Z"
                              fill="url(#paint362_radial_27_25)" stroke="#E383FF"/>
                        <path d="M543.973 263.919V180.503L550.718 183.131V266.547L543.973 263.919Z"
                              fill="url(#paint363_radial_27_25)" stroke="#E383FF"/>
                        <path d="M554.3 267.941V184.525L561.045 187.153V270.569L554.3 267.941Z"
                              fill="url(#paint364_radial_27_25)" stroke="#E383FF"/>
                        <path d="M564.627 271.965V188.549L571.372 191.177V274.593L564.627 271.965Z"
                              fill="url(#paint365_radial_27_25)" stroke="#E383FF"/>
                        <path d="M512.99 352.431V264.992L519.736 267.62V355.059L512.99 352.431Z"
                              fill="url(#paint366_radial_27_25)" stroke="#865EFF"/>
                        <path d="M523.317 356.455V269.015L530.063 271.643V359.082L523.317 356.455Z"
                              fill="url(#paint367_radial_27_25)" stroke="#865EFF"/>
                        <path d="M533.645 360.478V273.039L540.39 275.666V363.106L533.645 360.478Z"
                              fill="url(#paint368_radial_27_25)" stroke="#865EFF"/>
                        <path d="M543.972 364.501V277.061L550.717 279.689V367.129L543.972 364.501Z"
                              fill="url(#paint369_radial_27_25)" stroke="#865EFF"/>
                        <path d="M554.299 368.525V281.085L561.044 283.713V371.153L554.299 368.525Z"
                              fill="url(#paint370_radial_27_25)" stroke="#865EFF"/>
                        <path d="M564.627 372.548V285.109L571.372 287.736V375.176L564.627 372.548Z"
                              fill="url(#paint371_radial_27_25)" stroke="#865EFF"/>
                        <path d="M585.281 376.443V288.998L591.658 286.607V374.052L585.281 376.443Z"
                              fill="url(#paint372_radial_27_25)" stroke="#865EFF"/>
                        <path d="M585.281 275.86V192.439L591.658 190.048V273.469L585.281 275.86Z"
                              fill="url(#paint373_radial_27_25)" stroke="#E383FF"/>
                        <path d="M595.608 271.837V188.415L601.985 186.024V269.446L595.608 271.837Z"
                              fill="url(#paint374_radial_27_25)" stroke="#E383FF"/>
                        <path d="M605.936 267.31V183.889L612.312 181.498V264.919L605.936 267.31Z"
                              fill="url(#paint375_radial_27_25)" stroke="#E383FF"/>
                        <path d="M616.263 263.79V180.368L622.639 177.977V261.399L616.263 263.79Z"
                              fill="url(#paint376_radial_27_25)" stroke="#E383FF"/>
                        <path d="M626.59 259.766V176.345L632.966 173.954V257.375L626.59 259.766Z"
                              fill="url(#paint377_radial_27_25)"/>
                        <path d="M626.59 259.766V176.345L632.966 173.954V257.375L626.59 259.766Z"
                              fill="url(#paint378_radial_27_25)"/>
                        <path d="M626.59 259.766V176.345L632.966 173.954V257.375L626.59 259.766Z"
                              fill="url(#paint379_radial_27_25)"/>
                        <path d="M626.59 259.766V176.345L632.966 173.954V257.375L626.59 259.766Z" stroke="#E383FF"/>
                        <path d="M616.271 263.633V180.212L622.648 177.821V261.242L616.271 263.633Z"
                              fill="url(#paint380_radial_27_25)"/>
                        <path d="M616.271 263.633V180.212L622.648 177.821V261.242L616.271 263.633Z"
                              fill="url(#paint381_radial_27_25)"/>
                        <path d="M616.271 263.633V180.212L622.648 177.821V261.242L616.271 263.633Z"
                              fill="url(#paint382_radial_27_25)"/>
                        <path d="M616.271 263.633V180.212L622.648 177.821V261.242L616.271 263.633Z" stroke="#E383FF"/>
                        <path d="M606.305 266.71V183.289L612.681 180.898V264.319L606.305 266.71Z"
                              fill="url(#paint383_radial_27_25)"/>
                        <path d="M606.305 266.71V183.289L612.681 180.898V264.319L606.305 266.71Z"
                              fill="url(#paint384_radial_27_25)"/>
                        <path d="M606.305 266.71V183.289L612.681 180.898V264.319L606.305 266.71Z"
                              fill="url(#paint385_radial_27_25)"/>
                        <path d="M606.305 266.71V183.289L612.681 180.898V264.319L606.305 266.71Z" stroke="#E383FF"/>
                        <path d="M606.305 266.71V183.289L612.681 180.898V264.319L606.305 266.71Z"
                              fill="url(#paint386_radial_27_25)"/>
                        <path d="M606.305 266.71V183.289L612.681 180.898V264.319L606.305 266.71Z"
                              fill="url(#paint387_radial_27_25)"/>
                        <path d="M606.305 266.71V183.289L612.681 180.898V264.319L606.305 266.71Z"
                              fill="url(#paint388_radial_27_25)"/>
                        <path d="M606.305 266.71V183.289L612.681 180.898V264.319L606.305 266.71Z" stroke="#E383FF"/>
                        <path d="M606.305 266.71V183.289L612.681 180.898V264.319L606.305 266.71Z"
                              fill="url(#paint389_radial_27_25)"/>
                        <path d="M606.305 266.71V183.289L612.681 180.898V264.319L606.305 266.71Z"
                              fill="url(#paint390_radial_27_25)"/>
                        <path d="M606.305 266.71V183.289L612.681 180.898V264.319L606.305 266.71Z"
                              fill="url(#paint391_radial_27_25)"/>
                        <path d="M606.305 266.71V183.289L612.681 180.898V264.319L606.305 266.71Z" stroke="#E383FF"/>
                        <path d="M595.507 271.838V188.416L601.883 186.025V269.447L595.507 271.838Z"
                              fill="url(#paint392_radial_27_25)"/>
                        <path d="M595.507 271.838V188.416L601.883 186.025V269.447L595.507 271.838Z"
                              fill="url(#paint393_radial_27_25)"/>
                        <path d="M595.507 271.838V188.416L601.883 186.025V269.447L595.507 271.838Z"
                              fill="url(#paint394_radial_27_25)"/>
                        <path d="M595.507 271.838V188.416L601.883 186.025V269.447L595.507 271.838Z" stroke="#E383FF"/>
                        <path d="M585.54 274.915V191.493L591.917 189.102V272.524L585.54 274.915Z"
                              fill="url(#paint395_radial_27_25)"/>
                        <path d="M585.54 274.915V191.493L591.917 189.102V272.524L585.54 274.915Z"
                              fill="url(#paint396_radial_27_25)"/>
                        <path d="M585.54 274.915V191.493L591.917 189.102V272.524L585.54 274.915Z"
                              fill="url(#paint397_radial_27_25)"/>
                        <path d="M585.54 274.915V191.493L591.917 189.102V272.524L585.54 274.915Z" stroke="#E383FF"/>
                        <path d="M636.917 255.743V172.321L643.294 169.93V253.352L636.917 255.743Z"
                              fill="url(#paint398_radial_27_25)"/>
                        <path d="M636.917 255.743V172.321L643.294 169.93V253.352L636.917 255.743Z"
                              fill="url(#paint399_radial_27_25)"/>
                        <path d="M636.917 255.743V172.321L643.294 169.93V253.352L636.917 255.743Z"
                              fill="url(#paint400_radial_27_25)"/>
                        <path d="M636.917 255.743V172.321L643.294 169.93V253.352L636.917 255.743Z" stroke="#E383FF"/>
                        <path d="M636.917 255.743V172.321L643.294 169.93V253.352L636.917 255.743Z"
                              fill="url(#paint401_radial_27_25)"/>
                        <path d="M636.917 255.743V172.321L643.294 169.93V253.352L636.917 255.743Z"
                              fill="url(#paint402_radial_27_25)"/>
                        <path d="M636.917 255.743V172.321L643.294 169.93V253.352L636.917 255.743Z"
                              fill="url(#paint403_radial_27_25)"/>
                        <path d="M636.917 255.743V172.321L643.294 169.93V253.352L636.917 255.743Z" stroke="#E383FF"/>
                        <path d="M594.87 372.42V284.975L601.247 282.584V370.029L594.87 372.42Z"
                              fill="url(#paint404_radial_27_25)" stroke="#865EFF"/>
                        <path d="M605.197 368.396V280.952L611.574 278.561V366.005L605.197 368.396Z"
                              fill="url(#paint405_radial_27_25)" stroke="#865EFF"/>
                        <path d="M615.524 364.373V276.928L621.901 274.537V361.982L615.524 364.373Z"
                              fill="url(#paint406_radial_27_25)" stroke="#865EFF"/>
                        <path d="M625.852 360.349V272.905L632.228 270.514V357.958L625.852 360.349Z"
                              fill="url(#paint407_radial_27_25)" stroke="#865EFF"/>
                        <path d="M636.917 356.326V268.882L643.294 266.491V353.935L636.917 356.326Z"
                              fill="url(#paint408_radial_27_25)" stroke="#865EFF"/>
                        <path d="M647.244 352.303V264.858L653.621 262.467V349.912L647.244 352.303Z"
                              fill="url(#paint409_radial_27_25)" stroke="#865EFF"/>
                        <path d="M479.319 419.271L415.5 394.589V245.583L479.319 270.21V419.271Z" fill="#1A1826"
                              stroke="#865EFF"/>
                        <path d="M544.137 394.633L480.318 419.271V270.217L544.137 245.583V394.633Z" fill="#1A1826"/>
                        <path d="M544.137 394.633L480.318 419.271V270.217L544.137 245.583V394.633Z"
                              fill="url(#paint410_linear_27_25)" fill-opacity="0.2"/>
                        <path d="M544.137 394.633L480.318 419.271V270.217L544.137 245.583V394.633Z" stroke="#865EFF"/>
                        <path d="M485.221 275.973L492.937 273.109V411.088L485.221 413.951V275.973Z"
                              fill="url(#paint411_radial_27_25)"/>
                        <path d="M501.039 269.984L508.756 267.121L509.141 404.839L501.425 407.703L501.039 269.984Z"
                              fill="url(#paint412_radial_27_25)"/>
                        <path d="M516.858 263.997L524.575 261.133V399.112L516.858 401.975V263.997Z"
                              fill="url(#paint413_radial_27_25)"/>
                        <path d="M532.291 258.009L540.008 255.146V392.864L532.291 395.728V258.009Z"
                              fill="url(#paint414_radial_27_25)"/>
                        <path d="M532.291 258.009L540.008 255.146V392.864L532.291 395.728V258.009Z"
                              fill="url(#paint415_radial_27_25)"/>
                        <path d="M516.917 264.128L524.633 261.264V398.982L516.917 401.846V264.128Z"
                              fill="url(#paint416_radial_27_25)"/>
                        <path d="M501.542 270.246L509.258 267.382V405.101L501.542 407.964V270.246Z"
                              fill="url(#paint417_radial_27_25)"/>
                        <path d="M485.123 276.395L492.84 273.531V411.249L485.123 414.113V276.395Z"
                              fill="url(#paint418_radial_27_25)"/>
                        <path d="M419.63 254.885L427.732 258.009V395.467L419.63 392.343V254.885Z" fill="#865EFF"/>
                        <path d="M435.835 261.133L443.937 264.257V401.715L435.835 398.591V261.133Z" fill="#865EFF"/>
                        <path d="M452.04 267.121L460.142 270.245V407.963L452.04 404.839V267.121Z" fill="#865EFF"/>
                        <path d="M467.473 273.109L475.575 276.233V414.212L467.473 411.088L467.473 273.109Z"
                              fill="url(#paint419_radial_27_25)"/>
                        <path d="M435.799 262.007L443.901 265.131V403.11L435.799 399.986L435.799 262.007Z"
                              fill="url(#paint420_radial_27_25)"/>
                        <path d="M419.69 254.563L427.793 257.687V395.666L419.69 392.542L419.69 254.563Z"
                              fill="url(#paint421_radial_27_25)"/>
                        <path d="M451.845 267.212L459.947 270.336V408.315L451.845 405.191L451.845 267.212Z"
                              fill="url(#paint422_radial_27_25)"/>
                        <path d="M479.819 220L544.638 244.854L479.819 269.709L415 244.854L479.819 220Z" fill="#865EFF"/>
                        <path d="M479.819 224.272L533.977 245.049L479.819 265.825L425.661 245.049L479.819 224.272Z"
                              fill="url(#paint423_radial_27_25)"/>
                        <path d="M479.819 232.816L501.141 240.971L479.819 249.127L458.497 240.971L479.819 232.816Z"
                              fill="#865EFF"/>
                        <path d="M458.497 240.971L479.819 249.256V253.398L458.497 245.113V240.971Z"
                              fill="url(#paint424_linear_27_25)"/>
                        <path d="M479.818 249.256L501.14 240.971V245.113L479.818 253.398V249.256Z"
                              fill="url(#paint425_linear_27_25)"/>
                        <path d="M390.837 454.27L333.5 432.096V298.098L390.837 320.223V454.27Z" fill="#1A1826"
                              stroke="#865EFF"/>
                        <path d="M449.174 432.135L391.837 454.271V320.23L449.174 298.098V432.135Z" fill="#1A1826"/>
                        <path d="M449.174 432.135L391.837 454.271V320.23L449.174 298.098V432.135Z"
                              fill="url(#paint426_linear_27_25)" fill-opacity="0.2"/>
                        <path d="M449.174 432.135L391.837 454.271V320.23L449.174 298.098V432.135Z" stroke="#865EFF"/>
                        <path d="M396.198 325.375L403.143 322.798V446.979L396.198 449.556V325.375Z"
                              fill="url(#paint427_radial_27_25)"/>
                        <path d="M410.436 319.986L417.38 317.409L417.728 441.355L410.783 443.933L410.436 319.986Z"
                              fill="url(#paint428_radial_27_25)"/>
                        <path d="M424.673 314.597L431.618 312.02V436.201L424.673 438.778V314.597Z"
                              fill="url(#paint429_radial_27_25)"/>
                        <path d="M438.562 309.209L445.507 306.631V430.578L438.562 433.155V309.209Z"
                              fill="url(#paint430_radial_27_25)"/>
                        <path d="M438.562 309.209L445.507 306.631V430.578L438.562 433.155V309.209Z"
                              fill="url(#paint431_radial_27_25)"/>
                        <path d="M424.725 314.715L431.669 312.138V436.084L424.725 438.661V314.715Z"
                              fill="url(#paint432_radial_27_25)"/>
                        <path d="M410.888 320.222L417.833 317.644V441.591L410.888 444.168V320.222Z"
                              fill="url(#paint433_radial_27_25)"/>
                        <path d="M396.111 325.756L403.056 323.178V447.125L396.111 449.702V325.756Z"
                              fill="url(#paint434_radial_27_25)"/>
                        <path d="M337.167 306.397L344.459 309.209V432.921L337.167 430.109V306.397Z" fill="#865EFF"/>
                        <path d="M351.752 312.02L359.044 314.831V438.544L351.752 435.732V312.02Z" fill="#865EFF"/>
                        <path d="M366.336 317.409L373.628 320.221V444.167L366.336 441.356V317.409Z" fill="#865EFF"/>
                        <path d="M380.226 322.798L387.518 325.61V449.79L380.226 446.979L380.226 322.798Z"
                              fill="url(#paint435_radial_27_25)"/>
                        <path d="M351.72 312.807L359.012 315.618V439.799L351.72 436.987L351.72 312.807Z"
                              fill="url(#paint436_radial_27_25)"/>
                        <path d="M337.222 306.107L344.514 308.919V433.099L337.222 430.288L337.222 306.107Z"
                              fill="url(#paint437_radial_27_25)"/>
                        <path d="M366.16 317.491L373.452 320.303V444.483L366.16 441.672L366.16 317.491Z"
                              fill="url(#paint438_radial_27_25)"/>
                        <path d="M391.337 275L449.674 297.369L391.337 319.738L333 297.369L391.337 275Z" fill="#865EFF"/>
                        <path d="M391.337 278.845L440.079 297.544L391.337 316.243L342.595 297.544L391.337 278.845Z"
                              fill="url(#paint439_radial_27_25)"/>
                        <path d="M391.337 286.534L410.527 293.874L391.337 301.214L372.147 293.874L391.337 286.534Z"
                              fill="#865EFF"/>
                        <path d="M372.147 293.874L391.337 301.33V305.058L372.147 297.602V293.874Z"
                              fill="url(#paint440_linear_27_25)"/>
                        <path d="M391.337 301.33L410.527 293.874V297.602L391.337 305.058V301.33Z"
                              fill="url(#paint441_linear_27_25)"/>
                        <path d="M272.117 500.269L190.5 468.472V372.933L272.117 404.73V500.269Z"
                              fill="url(#paint442_linear_27_25)" stroke="#865EFF"/>
                        <path d="M354.735 468.472L273.117 500.269V404.782L354.735 372.933V468.472Z"
                              fill="url(#paint443_linear_27_25)" stroke="#865EFF"/>
                        <path d="M272.617 403.851L191.377 372.201L272.617 340.552L353.857 372.201L272.617 403.851Z"
                              fill="url(#paint444_linear_27_25)" stroke="#E383FF"/>
                        <path d="M272.117 399.634L200.827 371.86V280.398L272.117 308.171V399.634Z"
                              fill="url(#paint445_linear_27_25)" stroke="#E383FF"/>
                        <path d="M344.407 371.86L273.117 399.634V308.171L344.407 280.398V371.86Z"
                              fill="url(#paint446_linear_27_25)" stroke="#E383FF"/>
                        <path d="M272.617 307.293L201.694 279.662L272.617 251.538L343.541 279.662L272.617 307.293Z"
                              fill="#E383FF" stroke="#E383FF"/>
                        <path d="M272.619 303.269L213.117 279.666L272.619 256.064L333.203 279.666L272.619 303.269Z"
                              fill="url(#paint447_radial_27_25)" stroke="#E383FF"/>
                        <path d="M272.619 303.269L213.117 279.666L272.619 256.064L333.203 279.666L272.619 303.269Z"
                              fill="url(#paint448_radial_27_25)" stroke="#E383FF"/>
                        <path d="M272.617 283.655L253.34 276.145L272.617 268.635L291.894 276.145L272.617 283.655Z"
                              fill="#E383FF" stroke="#E383FF"/>
                        <path d="M252.463 279.827V276.877L272.117 284.534V287.484L252.463 279.827Z" fill="#5F7CBA"
                              stroke="#E383FF"/>
                        <path d="M273.117 287.484V284.534L292.772 276.877V279.827L273.117 287.484Z"
                              fill="url(#paint449_radial_27_25)" stroke="#E383FF"/>
                        <path d="M195.663 466.408V378.969L202.408 381.597V469.036L195.663 466.408Z"
                              fill="url(#paint450_radial_27_25)" stroke="#865EFF"/>
                        <path d="M205.99 369.848V286.432L212.736 289.059V372.476L205.99 369.848Z"
                              fill="url(#paint451_radial_27_25)" stroke="#E383FF"/>
                        <path d="M216.317 373.872V290.456L223.063 293.084V376.5L216.317 373.872Z"
                              fill="url(#paint452_radial_27_25)" stroke="#E383FF"/>
                        <path d="M226.645 377.895V294.478L233.39 297.106V380.522L226.645 377.895Z"
                              fill="url(#paint453_radial_27_25)" stroke="#E383FF"/>
                        <path d="M236.973 381.919V298.503L243.718 301.131V384.547L236.973 381.919Z"
                              fill="url(#paint454_radial_27_25)" stroke="#E383FF"/>
                        <path d="M247.3 385.941V302.525L254.045 305.153V388.569L247.3 385.941Z"
                              fill="url(#paint455_radial_27_25)" stroke="#E383FF"/>
                        <path d="M257.627 389.965V306.549L264.372 309.177V392.593L257.627 389.965Z"
                              fill="url(#paint456_radial_27_25)" stroke="#E383FF"/>
                        <path d="M205.99 470.431V382.992L212.736 385.62V473.059L205.99 470.431Z"
                              fill="url(#paint457_radial_27_25)" stroke="#865EFF"/>
                        <path d="M216.317 474.455V387.015L223.063 389.643V477.082L216.317 474.455Z"
                              fill="url(#paint458_radial_27_25)" stroke="#865EFF"/>
                        <path d="M226.645 478.478V391.039L233.39 393.666V481.106L226.645 478.478Z"
                              fill="url(#paint459_radial_27_25)" stroke="#865EFF"/>
                        <path d="M236.972 482.501V395.061L243.717 397.689V485.129L236.972 482.501Z"
                              fill="url(#paint460_radial_27_25)" stroke="#865EFF"/>
                        <path d="M247.299 486.525V399.085L254.044 401.713V489.153L247.299 486.525Z"
                              fill="url(#paint461_radial_27_25)" stroke="#865EFF"/>
                        <path d="M257.627 490.548V403.109L264.372 405.736V493.176L257.627 490.548Z"
                              fill="url(#paint462_radial_27_25)" stroke="#865EFF"/>
                        <path d="M278.281 494.443V406.998L284.658 404.607V492.052L278.281 494.443Z"
                              fill="url(#paint463_radial_27_25)" stroke="#865EFF"/>
                        <path d="M278.281 393.86V310.439L284.658 308.048V391.469L278.281 393.86Z"
                              fill="url(#paint464_radial_27_25)" stroke="#E383FF"/>
                        <path d="M288.608 389.837V306.415L294.985 304.024V387.446L288.608 389.837Z"
                              fill="url(#paint465_radial_27_25)" stroke="#E383FF"/>
                        <path d="M298.936 385.31V301.889L305.312 299.498V382.919L298.936 385.31Z"
                              fill="url(#paint466_radial_27_25)" stroke="#E383FF"/>
                        <path d="M309.263 381.79V298.368L315.639 295.977V379.399L309.263 381.79Z"
                              fill="url(#paint467_radial_27_25)" stroke="#E383FF"/>
                        <path d="M319.59 377.766V294.345L325.966 291.954V375.375L319.59 377.766Z"
                              fill="url(#paint468_radial_27_25)"/>
                        <path d="M319.59 377.766V294.345L325.966 291.954V375.375L319.59 377.766Z"
                              fill="url(#paint469_radial_27_25)"/>
                        <path d="M319.59 377.766V294.345L325.966 291.954V375.375L319.59 377.766Z"
                              fill="url(#paint470_radial_27_25)"/>
                        <path d="M319.59 377.766V294.345L325.966 291.954V375.375L319.59 377.766Z" stroke="#E383FF"/>
                        <path d="M309.271 381.633V298.212L315.648 295.821V379.242L309.271 381.633Z"
                              fill="url(#paint471_radial_27_25)"/>
                        <path d="M309.271 381.633V298.212L315.648 295.821V379.242L309.271 381.633Z"
                              fill="url(#paint472_radial_27_25)"/>
                        <path d="M309.271 381.633V298.212L315.648 295.821V379.242L309.271 381.633Z"
                              fill="url(#paint473_radial_27_25)"/>
                        <path d="M309.271 381.633V298.212L315.648 295.821V379.242L309.271 381.633Z" stroke="#E383FF"/>
                        <path d="M299.305 384.71V301.289L305.681 298.898V382.319L299.305 384.71Z"
                              fill="url(#paint474_radial_27_25)"/>
                        <path d="M299.305 384.71V301.289L305.681 298.898V382.319L299.305 384.71Z"
                              fill="url(#paint475_radial_27_25)"/>
                        <path d="M299.305 384.71V301.289L305.681 298.898V382.319L299.305 384.71Z"
                              fill="url(#paint476_radial_27_25)"/>
                        <path d="M299.305 384.71V301.289L305.681 298.898V382.319L299.305 384.71Z" stroke="#E383FF"/>
                        <path d="M299.305 384.71V301.289L305.681 298.898V382.319L299.305 384.71Z"
                              fill="url(#paint477_radial_27_25)"/>
                        <path d="M299.305 384.71V301.289L305.681 298.898V382.319L299.305 384.71Z"
                              fill="url(#paint478_radial_27_25)"/>
                        <path d="M299.305 384.71V301.289L305.681 298.898V382.319L299.305 384.71Z"
                              fill="url(#paint479_radial_27_25)"/>
                        <path d="M299.305 384.71V301.289L305.681 298.898V382.319L299.305 384.71Z" stroke="#E383FF"/>
                        <path d="M299.305 384.71V301.289L305.681 298.898V382.319L299.305 384.71Z"
                              fill="url(#paint480_radial_27_25)"/>
                        <path d="M299.305 384.71V301.289L305.681 298.898V382.319L299.305 384.71Z"
                              fill="url(#paint481_radial_27_25)"/>
                        <path d="M299.305 384.71V301.289L305.681 298.898V382.319L299.305 384.71Z"
                              fill="url(#paint482_radial_27_25)"/>
                        <path d="M299.305 384.71V301.289L305.681 298.898V382.319L299.305 384.71Z" stroke="#E383FF"/>
                        <path d="M288.507 389.838V306.416L294.883 304.025V387.447L288.507 389.838Z"
                              fill="url(#paint483_radial_27_25)"/>
                        <path d="M288.507 389.838V306.416L294.883 304.025V387.447L288.507 389.838Z"
                              fill="url(#paint484_radial_27_25)"/>
                        <path d="M288.507 389.838V306.416L294.883 304.025V387.447L288.507 389.838Z"
                              fill="url(#paint485_radial_27_25)"/>
                        <path d="M288.507 389.838V306.416L294.883 304.025V387.447L288.507 389.838Z" stroke="#E383FF"/>
                        <path d="M278.54 392.915V309.493L284.917 307.102V390.524L278.54 392.915Z"
                              fill="url(#paint486_radial_27_25)"/>
                        <path d="M278.54 392.915V309.493L284.917 307.102V390.524L278.54 392.915Z"
                              fill="url(#paint487_radial_27_25)"/>
                        <path d="M278.54 392.915V309.493L284.917 307.102V390.524L278.54 392.915Z"
                              fill="url(#paint488_radial_27_25)"/>
                        <path d="M278.54 392.915V309.493L284.917 307.102V390.524L278.54 392.915Z" stroke="#E383FF"/>
                        <path d="M329.917 373.743V290.321L336.294 287.93V371.352L329.917 373.743Z"
                              fill="url(#paint489_radial_27_25)"/>
                        <path d="M329.917 373.743V290.321L336.294 287.93V371.352L329.917 373.743Z"
                              fill="url(#paint490_radial_27_25)"/>
                        <path d="M329.917 373.743V290.321L336.294 287.93V371.352L329.917 373.743Z"
                              fill="url(#paint491_radial_27_25)"/>
                        <path d="M329.917 373.743V290.321L336.294 287.93V371.352L329.917 373.743Z" stroke="#E383FF"/>
                        <path d="M329.917 373.743V290.321L336.294 287.93V371.352L329.917 373.743Z"
                              fill="url(#paint492_radial_27_25)"/>
                        <path d="M329.917 373.743V290.321L336.294 287.93V371.352L329.917 373.743Z"
                              fill="url(#paint493_radial_27_25)"/>
                        <path d="M329.917 373.743V290.321L336.294 287.93V371.352L329.917 373.743Z"
                              fill="url(#paint494_radial_27_25)"/>
                        <path d="M329.917 373.743V290.321L336.294 287.93V371.352L329.917 373.743Z" stroke="#E383FF"/>
                        <path d="M287.87 490.42V402.975L294.247 400.584V488.029L287.87 490.42Z"
                              fill="url(#paint495_radial_27_25)" stroke="#865EFF"/>
                        <path d="M298.197 486.396V398.952L304.574 396.561V484.005L298.197 486.396Z"
                              fill="url(#paint496_radial_27_25)" stroke="#865EFF"/>
                        <path d="M308.524 482.373V394.928L314.901 392.537V479.982L308.524 482.373Z"
                              fill="url(#paint497_radial_27_25)" stroke="#865EFF"/>
                        <path d="M318.852 478.349V390.905L325.228 388.514V475.958L318.852 478.349Z"
                              fill="url(#paint498_radial_27_25)" stroke="#865EFF"/>
                        <path d="M329.917 474.326V386.882L336.294 384.491V471.935L329.917 474.326Z"
                              fill="url(#paint499_radial_27_25)" stroke="#865EFF"/>
                        <path d="M340.244 470.303V382.858L346.621 380.467V467.912L340.244 470.303Z"
                              fill="url(#paint500_radial_27_25)" stroke="#865EFF"/>
                        <path d="M152.523 548.27L72.5 517.322V330.797L152.523 361.677V548.27Z" fill="#1A1826"
                              stroke="#865EFF"/>
                        <path d="M233.547 517.377L153.523 548.271V361.686L233.547 330.797V517.377Z" fill="#1A1826"/>
                        <path d="M233.547 517.377L153.523 548.271V361.686L233.547 330.797V517.377Z"
                              fill="url(#paint501_linear_27_25)" fill-opacity="0.2"/>
                        <path d="M233.547 517.377L153.523 548.271V361.686L233.547 330.797V517.377Z" stroke="#865EFF"/>
                        <path d="M159.776 368.965L169.422 365.386V537.859L159.776 541.438V368.965Z"
                              fill="url(#paint502_radial_27_25)"/>
                        <path d="M179.549 361.481L189.194 357.901L189.677 530.049L180.031 533.628L179.549 361.481Z"
                              fill="url(#paint503_radial_27_25)"/>
                        <path d="M199.323 353.996L208.969 350.416V522.889L199.323 526.469V353.996Z"
                              fill="url(#paint504_radial_27_25)"/>
                        <path d="M218.614 346.512L228.26 342.932V515.08L218.614 518.659V346.512Z"
                              fill="url(#paint505_radial_27_25)"/>
                        <path d="M218.614 346.512L228.26 342.932V515.08L218.614 518.659V346.512Z"
                              fill="url(#paint506_radial_27_25)"/>
                        <path d="M199.396 354.16L209.041 350.58V522.728L199.396 526.307V354.16Z"
                              fill="url(#paint507_radial_27_25)"/>
                        <path d="M180.177 361.808L189.822 358.228V530.376L180.177 533.955V361.808Z"
                              fill="url(#paint508_radial_27_25)"/>
                        <path d="M159.654 369.494L169.3 365.914V538.062L159.654 541.642V369.494Z"
                              fill="url(#paint509_radial_27_25)"/>
                        <path d="M77.7881 342.607L87.916 346.512V518.334L77.7881 514.429V342.607Z" fill="#865EFF"/>
                        <path d="M98.0439 350.416L108.172 354.321V526.144L98.0439 522.239V350.416Z" fill="#865EFF"/>
                        <path d="M118.3 357.901L128.428 361.806V533.954L118.3 530.049V357.901Z" fill="#865EFF"/>
                        <path d="M137.591 365.386L147.719 369.291V541.764L137.591 537.859L137.591 365.386Z"
                              fill="url(#paint510_radial_27_25)"/>
                        <path d="M97.999 351.509L108.127 355.414V527.887L97.999 523.982L97.999 351.509Z"
                              fill="url(#paint511_radial_27_25)"/>
                        <path d="M77.8633 342.204L87.9912 346.109V518.582L77.8633 514.677L77.8633 342.204Z"
                              fill="url(#paint512_radial_27_25)"/>
                        <path d="M118.056 358.015L128.184 361.92V534.393L118.056 530.488L118.056 358.015Z"
                              fill="url(#paint513_radial_27_25)"/>
                        <path d="M153.023 299L234.047 330.068L153.023 361.136L72 330.068L153.023 299Z" fill="#865EFF"/>
                        <path d="M153.023 304.34L220.721 330.311L153.023 356.281L85.3262 330.311L153.023 304.34Z"
                              fill="url(#paint514_radial_27_25)"/>
                        <path d="M153.024 315.02L179.676 325.214L153.024 335.408L126.371 325.214L153.024 315.02Z"
                              fill="#865EFF"/>
                        <path d="M126.371 325.214L153.024 335.57V340.748L126.371 330.392V325.214Z"
                              fill="url(#paint515_linear_27_25)"/>
                        <path d="M153.023 335.57L179.676 325.214V330.392L153.023 340.748V335.57Z"
                              fill="url(#paint516_linear_27_25)"/>
                        <defs>
                            <linearGradient id="paint0_linear_27_25" x1="850" y1="260" x2="850" y2="552"
                                            gradientUnits="userSpaceOnUse">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#865EFF" stop-opacity="0"/>
                            </linearGradient>
                            <radialGradient id="paint1_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(841.623 877.117) rotate(90) scale(2015.94 760.706)">
                                <stop stop-color="#2F2353" stop-opacity="0"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint2_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(846.5 448) rotate(90) scale(413.5 131.305)">
                                <stop stop-color="#E383FF"/>
                                <stop offset="0.82" stop-opacity="0"/>
                            </radialGradient>
                            <linearGradient id="paint3_linear_27_25" x1="1377.74" y1="177.529" x2="1377.26" y2="491.831"
                                            gradientUnits="userSpaceOnUse">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#E383FF"/>
                            </linearGradient>
                            <linearGradient id="paint4_linear_27_25" x1="1259.02" y1="101.209" x2="1259.02" y2="354.486"
                                            gradientUnits="userSpaceOnUse">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#E383FF"/>
                            </linearGradient>
                            <linearGradient id="paint5_linear_27_25" x1="1437.23" y1="177.266" x2="1199.22" y2="177.266"
                                            gradientUnits="userSpaceOnUse">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#E5A2FF"/>
                            </linearGradient>
                            <linearGradient id="paint6_linear_27_25" x1="1370.31" y1="44.2785" x2="1370.22" y2="347.84"
                                            gradientUnits="userSpaceOnUse">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#E383FF"/>
                            </linearGradient>
                            <linearGradient id="paint7_linear_27_25" x1="1266.21" y1="44.2785" x2="1266.21" y2="218.085"
                                            gradientUnits="userSpaceOnUse">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#E383FF"/>
                            </linearGradient>
                            <radialGradient id="paint8_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1317.46 44.2791) rotate(90) scale(34.7613 88.4301)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint9_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1317.46 44.2791) rotate(90) scale(34.7613 88.4301)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint10_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1303.39 47.8994) rotate(90) scale(8.69033 14.8711)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint11_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1424.22 252.124) rotate(90) scale(65.9017 5.57667)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint12_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1409.35 115.973) rotate(90) scale(63.0049 5.57667)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint13_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1394.47 121.768) rotate(90) scale(63.0049 5.57667)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint14_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1379.6 127.56) rotate(90) scale(63.0049 5.57667)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint15_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1364.73 133.355) rotate(90) scale(63.0049 5.57667)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint16_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1349.86 139.148) rotate(90) scale(63.0049 5.57667)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint17_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1334.99 144.942) rotate(90) scale(63.0049 5.57667)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint18_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1409.35 257.916) rotate(90) scale(65.9017 5.57667)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint19_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1394.47 263.71) rotate(90) scale(65.9017 5.57667)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint20_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1379.6 269.504) rotate(90) scale(65.9017 5.57667)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint21_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1364.73 275.297) rotate(90) scale(65.9017 5.57667)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint22_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1349.86 281.091) rotate(90) scale(65.9017 5.57667)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint23_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1334.99 286.884) rotate(90) scale(65.9017 5.57667)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint24_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1305.51 288.876) rotate(90) scale(65.7206 102.922)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint25_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1305.51 146.933) rotate(90) scale(62.8238 5.31112)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint26_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1290.64 141.14) rotate(90) scale(62.8238 5.31112)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint27_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1275.77 134.621) rotate(90) scale(62.8238 5.31112)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint28_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1260.9 129.552) rotate(90) scale(62.8238 5.31112)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint29_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1246.03 123.758) rotate(90.2188) scale(42.4313 31.638)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint30_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1246.03 123.758) rotate(90.2188) scale(42.4313 31.638)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint31_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1246.03 123.758) rotate(90.2188) scale(42.4313 31.638)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint32_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1260.89 129.327) rotate(90.2188) scale(42.4313 31.638)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint33_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1260.89 129.327) rotate(90.2188) scale(42.4313 31.638)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint34_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1260.89 129.327) rotate(90.2188) scale(42.4313 31.638)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint35_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1275.24 133.758) rotate(90.2188) scale(42.4313 31.638)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint36_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1275.24 133.758) rotate(90.2188) scale(42.4313 31.638)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint37_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1275.24 133.758) rotate(90.2188) scale(42.4313 31.638)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint38_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1275.24 133.758) rotate(90.2188) scale(42.4313 31.638)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint39_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1275.24 133.758) rotate(90.2188) scale(42.4313 31.638)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint40_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1275.24 133.758) rotate(90.2188) scale(42.4313 31.638)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint41_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1275.24 133.758) rotate(90.2188) scale(42.4313 31.638)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint42_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1275.24 133.758) rotate(90.2188) scale(42.4313 31.638)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint43_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1275.24 133.758) rotate(90.2188) scale(42.4313 31.638)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint44_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1290.79 141.141) rotate(90.2188) scale(42.4313 31.638)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint45_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1290.79 141.141) rotate(90.2188) scale(42.4313 31.638)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint46_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1290.79 141.141) rotate(90.2188) scale(42.4313 31.638)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint47_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1305.14 145.572) rotate(90.2188) scale(42.4313 31.638)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint48_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1305.14 145.572) rotate(90.2188) scale(42.4313 31.638)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint49_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1305.14 145.572) rotate(90.2188) scale(42.4313 31.638)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint50_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1231.16 117.965) rotate(90.3155) scale(43.7945 59.7067)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint51_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1231.16 117.965) rotate(90.3155) scale(43.7945 59.7067)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint52_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1231.16 117.965) rotate(90.3155) scale(43.7945 59.7067)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint53_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1231.16 117.965) rotate(90.3155) scale(43.7945 59.7067)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint54_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1231.16 117.965) rotate(90.3155) scale(43.7945 59.7067)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint55_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1231.16 117.965) rotate(90.3155) scale(43.7945 59.7067)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint56_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1291.7 283.082) rotate(90) scale(65.7206 116.26)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint57_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1276.83 277.289) rotate(90) scale(65.7206 130.426)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint58_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1261.96 271.495) rotate(90) scale(65.7206 107.687)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint59_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1247.09 265.701) rotate(90) scale(65.7206 125.614)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint60_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1231.16 259.908) rotate(90) scale(65.7206 150.601)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint61_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1216.29 254.114) rotate(90) scale(65.7206 110.297)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <linearGradient id="paint62_linear_27_25" x1="1470.46" y1="203.592" x2="1470.46"
                                            y2="387.087" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-opacity="0"/>
                            </linearGradient>
                            <radialGradient id="paint63_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1522.55 277.942) rotate(90) scale(158.448 8.68107)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint64_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1486.52 264.176) rotate(90) scale(158.155 9.11513)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint65_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1451.37 250.998) rotate(90.0713) scale(112.851 42.6545)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint66_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1416.64 237.233) rotate(90.1111) scale(93.5769 41.105)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint67_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1416.64 237.233) rotate(90.1111) scale(93.5769 41.105)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint68_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1451.23 250.999) rotate(90.1111) scale(93.5769 41.105)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint69_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1485.83 264.765) rotate(90.1111) scale(93.5769 41.105)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint70_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1522.77 278.6) rotate(90.1111) scale(93.5769 41.105)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint71_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1562.05 278.235) rotate(89.9108) scale(99.3693 84.4589)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint72_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1633.32 253.257) rotate(89.9108) scale(99.3693 84.4589)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint73_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1669.56 236.509) rotate(89.9108) scale(99.3693 84.4589)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint74_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1597.21 264.968) rotate(89.9108) scale(99.3693 84.4589)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint75_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1543.39 56.3598) rotate(90) scale(46.7476 115.126)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <linearGradient id="paint76_linear_27_25" x1="1565.45" y1="57.6691" x2="1573.23"
                                            y2="76.9943" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#1A1826"/>
                                <stop offset="1" stop-color="#865EFF" stop-opacity="0"/>
                            </linearGradient>
                            <linearGradient id="paint77_linear_27_25" x1="1517.96" y1="55.9215" x2="1511.85"
                                            y2="72.9145" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#1A1826"/>
                                <stop offset="1" stop-color="#865EFF" stop-opacity="0"/>
                            </linearGradient>
                            <linearGradient id="paint78_linear_27_25" x1="969.307" y1="250.864" x2="969.307"
                                            y2="312.029" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-opacity="0"/>
                            </linearGradient>
                            <radialGradient id="paint79_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(986.669 275.647) rotate(90) scale(52.8159 2.89369)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint80_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(974.661 271.059) rotate(90) scale(52.7182 3.03837)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint81_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(962.941 266.666) rotate(90.0713) scale(37.6168 14.2182)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint82_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(951.366 262.078) rotate(90.1111) scale(31.1923 13.7017)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint83_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(951.366 262.078) rotate(90.1111) scale(31.1923 13.7017)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint84_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(962.897 266.666) rotate(90.1111) scale(31.1923 13.7017)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint85_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(974.429 271.255) rotate(90.1111) scale(31.1923 13.7017)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint86_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(986.742 275.867) rotate(90.1111) scale(31.1923 13.7017)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint87_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(999.836 275.745) rotate(89.9108) scale(33.1231 28.1529)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint88_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1023.59 267.419) rotate(89.9108) scale(33.1231 28.1529)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint89_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1035.67 261.836) rotate(89.9108) scale(33.1231 28.1529)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint90_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1011.56 271.323) rotate(89.9108) scale(33.1231 28.1529)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint91_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(993.614 201.786) rotate(90) scale(15.5825 38.3755)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <linearGradient id="paint92_linear_27_25" x1="1000.97" y1="202.223" x2="1003.56"
                                            y2="208.665" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#1A1826"/>
                                <stop offset="1" stop-color="#865EFF" stop-opacity="0"/>
                            </linearGradient>
                            <linearGradient id="paint93_linear_27_25" x1="985.139" y1="201.641" x2="983.103"
                                            y2="207.305" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#1A1826"/>
                                <stop offset="1" stop-color="#865EFF" stop-opacity="0"/>
                            </linearGradient>
                            <linearGradient id="paint94_linear_27_25" x1="1150.92" y1="250.201" x2="1150.58"
                                            y2="468.466" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#E383FF"/>
                            </linearGradient>
                            <linearGradient id="paint95_linear_27_25" x1="1068.47" y1="197.201" x2="1068.47"
                                            y2="373.088" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#E383FF"/>
                            </linearGradient>
                            <linearGradient id="paint96_linear_27_25" x1="1192.23" y1="250.018" x2="1026.95"
                                            y2="250.018" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#E5A2FF"/>
                            </linearGradient>
                            <linearGradient id="paint97_linear_27_25" x1="1145.76" y1="157.666" x2="1145.7" y2="368.473"
                                            gradientUnits="userSpaceOnUse">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#E383FF"/>
                            </linearGradient>
                            <linearGradient id="paint98_linear_27_25" x1="1073.47" y1="157.666" x2="1073.47"
                                            y2="278.365" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#E383FF"/>
                            </linearGradient>
                            <radialGradient id="paint99_radial_27_25" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1109.06 157.666) rotate(90) scale(24.1398 61.4098)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint100_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1109.06 157.666) rotate(90) scale(24.1398 61.4098)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint101_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1099.28 160.18) rotate(90) scale(6.03495 10.3272)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint102_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1183.19 302.003) rotate(90) scale(45.765 3.87269)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint103_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1172.87 207.454) rotate(90) scale(43.7534 3.87269)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint104_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1162.54 211.478) rotate(90) scale(43.7534 3.87269)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint105_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1152.21 215.5) rotate(90) scale(43.7534 3.87269)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint106_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1141.88 219.525) rotate(90) scale(43.7534 3.87269)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint107_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1131.56 223.547) rotate(90) scale(43.7534 3.87269)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint108_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1121.23 227.571) rotate(90) scale(43.7534 3.87269)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint109_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1172.87 306.025) rotate(90) scale(45.765 3.87269)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint110_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1162.54 310.049) rotate(90) scale(45.765 3.87269)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint111_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1152.21 314.072) rotate(90) scale(45.765 3.87269)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint112_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1141.88 318.095) rotate(90) scale(45.765 3.87269)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint113_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1131.56 322.119) rotate(90) scale(45.765 3.87269)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint114_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1121.23 326.142) rotate(90) scale(45.765 3.87269)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint115_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1100.76 327.525) rotate(90) scale(45.6393 71.4734)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint116_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1100.76 228.954) rotate(90) scale(43.6277 3.68827)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint117_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1090.43 224.93) rotate(90) scale(43.6277 3.68827)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint118_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1080.1 220.404) rotate(90) scale(43.6277 3.68827)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint119_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1069.78 216.883) rotate(90) scale(43.6277 3.68827)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint120_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1059.45 212.86) rotate(90.2188) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint121_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1059.45 212.86) rotate(90.2188) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint122_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1059.45 212.86) rotate(90.2188) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint123_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1069.77 216.727) rotate(90.2188) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint124_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1069.77 216.727) rotate(90.2188) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint125_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1069.77 216.727) rotate(90.2188) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint126_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1079.74 219.804) rotate(90.2188) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint127_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1079.74 219.804) rotate(90.2188) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint128_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1079.74 219.804) rotate(90.2188) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint129_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1079.74 219.804) rotate(90.2188) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint130_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1079.74 219.804) rotate(90.2188) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint131_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1079.74 219.804) rotate(90.2188) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint132_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1079.74 219.804) rotate(90.2188) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint133_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1079.74 219.804) rotate(90.2188) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint134_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1079.74 219.804) rotate(90.2188) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint135_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1090.53 224.932) rotate(90.2188) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint136_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1090.53 224.932) rotate(90.2188) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint137_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1090.53 224.932) rotate(90.2188) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint138_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1100.5 228.009) rotate(90.2188) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint139_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1100.5 228.009) rotate(90.2188) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint140_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1100.5 228.009) rotate(90.2188) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint141_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1049.12 208.837) rotate(90.3155) scale(30.4129 41.463)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint142_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1049.12 208.837) rotate(90.3155) scale(30.4129 41.463)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint143_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1049.12 208.837) rotate(90.3155) scale(30.4129 41.463)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint144_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1049.12 208.837) rotate(90.3155) scale(30.4129 41.463)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint145_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1049.12 208.837) rotate(90.3155) scale(30.4129 41.463)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint146_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1049.12 208.837) rotate(90.3155) scale(30.4129 41.463)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint147_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1091.17 323.502) rotate(90) scale(45.6393 80.7363)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint148_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1080.84 319.478) rotate(90) scale(45.6393 90.5737)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint149_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1070.52 315.455) rotate(90) scale(45.6393 74.7829)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint150_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1060.19 311.431) rotate(90) scale(45.6393 87.2323)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint151_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1049.12 307.408) rotate(90) scale(45.6393 104.584)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint152_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1038.8 303.385) rotate(90) scale(45.6393 76.595)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <linearGradient id="paint153_linear_27_25" x1="1177" y1="306.485" x2="1177" y2="388.039"
                                            gradientUnits="userSpaceOnUse">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-opacity="0"/>
                            </linearGradient>
                            <radialGradient id="paint154_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1200.15 339.53) rotate(90) scale(70.4211 3.85825)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint155_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1184.14 333.412) rotate(90) scale(70.291 4.05117)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint156_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1168.51 327.554) rotate(90.0713) scale(50.1558 18.9575)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint157_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1153.08 321.437) rotate(90.1111) scale(41.5897 18.2689)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint158_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1153.08 321.437) rotate(90.1111) scale(41.5897 18.2689)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint159_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1168.45 327.555) rotate(90.1111) scale(41.5897 18.2689)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint160_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1183.83 333.673) rotate(90.1111) scale(41.5897 18.2689)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint161_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1200.25 339.822) rotate(90.1111) scale(41.5897 18.2689)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint162_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1217.7 339.66) rotate(89.9108) scale(44.1641 37.5373)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint163_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1249.38 328.559) rotate(89.9108) scale(44.1641 37.5373)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint164_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1265.49 321.114) rotate(89.9108) scale(44.1641 37.5373)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint165_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1233.33 333.763) rotate(89.9108) scale(44.1641 37.5373)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint166_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1209.41 241.049) rotate(90) scale(20.7767 51.1673)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <linearGradient id="paint167_linear_27_25" x1="1219.22" y1="241.631" x2="1222.67"
                                            y2="250.22" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#1A1826"/>
                                <stop offset="1" stop-color="#865EFF" stop-opacity="0"/>
                            </linearGradient>
                            <linearGradient id="paint168_linear_27_25" x1="1198.11" y1="240.854" x2="1195.39"
                                            y2="248.407" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#1A1826"/>
                                <stop offset="1" stop-color="#865EFF" stop-opacity="0"/>
                            </linearGradient>
                            <linearGradient id="paint169_linear_27_25" x1="1268.72" y1="352.437" x2="1268.72"
                                            y2="425.835" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-opacity="0"/>
                            </linearGradient>
                            <radialGradient id="paint170_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1289.56 382.177) rotate(90) scale(63.379 3.47243)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint171_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1275.15 376.671) rotate(90) scale(63.2619 3.64605)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint172_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1261.08 371.399) rotate(90.0713) scale(45.1402 17.0618)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint173_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1247.19 365.893) rotate(90.1111) scale(37.4307 16.442)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint174_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1247.19 365.893) rotate(90.1111) scale(37.4307 16.442)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint175_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1261.03 371.399) rotate(90.1111) scale(37.4307 16.442)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint176_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1274.87 376.906) rotate(90.1111) scale(37.4307 16.442)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint177_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1289.64 382.44) rotate(90.1111) scale(37.4307 16.442)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint178_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1305.36 382.294) rotate(89.9108) scale(39.7477 33.7835)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint179_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1333.86 372.303) rotate(89.9108) scale(39.7477 33.7835)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint180_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1348.36 365.603) rotate(89.9108) scale(39.7477 33.7835)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint181_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1319.42 376.987) rotate(89.9108) scale(39.7477 33.7835)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint182_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1297.89 293.544) rotate(90) scale(18.699 46.0506)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <linearGradient id="paint183_linear_27_25" x1="1306.72" y1="294.068" x2="1309.83"
                                            y2="301.798" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#1A1826"/>
                                <stop offset="1" stop-color="#865EFF" stop-opacity="0"/>
                            </linearGradient>
                            <linearGradient id="paint184_linear_27_25" x1="1287.72" y1="293.369" x2="1285.28"
                                            y2="300.166" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#1A1826"/>
                                <stop offset="1" stop-color="#865EFF" stop-opacity="0"/>
                            </linearGradient>
                            <linearGradient id="paint185_linear_27_25" x1="1457.92" y1="368.201" x2="1457.58"
                                            y2="586.466" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#E383FF"/>
                            </linearGradient>
                            <linearGradient id="paint186_linear_27_25" x1="1375.47" y1="315.201" x2="1375.47"
                                            y2="491.088" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#E383FF"/>
                            </linearGradient>
                            <linearGradient id="paint187_linear_27_25" x1="1499.23" y1="368.018" x2="1333.95"
                                            y2="368.018" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#E5A2FF"/>
                            </linearGradient>
                            <linearGradient id="paint188_linear_27_25" x1="1452.76" y1="275.666" x2="1452.7"
                                            y2="486.473" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#E383FF"/>
                            </linearGradient>
                            <linearGradient id="paint189_linear_27_25" x1="1380.47" y1="275.666" x2="1380.47"
                                            y2="396.365" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#E383FF"/>
                            </linearGradient>
                            <radialGradient id="paint190_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1416.06 275.666) rotate(90) scale(24.1398 61.4098)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint191_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1416.06 275.666) rotate(90) scale(24.1398 61.4098)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint192_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1406.28 278.18) rotate(90) scale(6.03495 10.3272)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint193_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1490.19 420.003) rotate(90) scale(45.765 3.87269)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint194_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1479.87 325.454) rotate(90) scale(43.7534 3.87269)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint195_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1469.54 329.478) rotate(90) scale(43.7534 3.87269)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint196_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1459.21 333.5) rotate(90) scale(43.7534 3.87269)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint197_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1448.88 337.525) rotate(90) scale(43.7534 3.87269)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint198_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1438.56 341.547) rotate(90) scale(43.7534 3.87269)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint199_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1428.23 345.571) rotate(90) scale(43.7534 3.87269)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint200_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1479.87 424.025) rotate(90) scale(45.765 3.87269)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint201_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1469.54 428.049) rotate(90) scale(45.765 3.87269)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint202_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1459.21 432.072) rotate(90) scale(45.765 3.87269)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint203_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1448.88 436.095) rotate(90) scale(45.765 3.87269)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint204_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1438.56 440.119) rotate(90) scale(45.765 3.87269)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint205_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1428.23 444.142) rotate(90) scale(45.765 3.87269)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint206_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1407.76 445.525) rotate(90) scale(45.6393 71.4734)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint207_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1407.76 346.954) rotate(90) scale(43.6277 3.68827)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint208_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1397.43 342.93) rotate(90) scale(43.6277 3.68827)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint209_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1387.1 338.404) rotate(90) scale(43.6277 3.68827)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint210_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1376.78 334.883) rotate(90) scale(43.6277 3.68827)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint211_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1366.45 330.86) rotate(90.2188) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint212_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1366.45 330.86) rotate(90.2188) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint213_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1366.45 330.86) rotate(90.2188) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint214_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1376.77 334.727) rotate(90.2188) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint215_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1376.77 334.727) rotate(90.2188) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint216_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1376.77 334.727) rotate(90.2188) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint217_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1386.74 337.804) rotate(90.2188) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint218_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1386.74 337.804) rotate(90.2188) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint219_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1386.74 337.804) rotate(90.2188) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint220_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1386.74 337.804) rotate(90.2188) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint221_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1386.74 337.804) rotate(90.2188) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint222_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1386.74 337.804) rotate(90.2188) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint223_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1386.74 337.804) rotate(90.2188) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint224_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1386.74 337.804) rotate(90.2188) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint225_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1386.74 337.804) rotate(90.2188) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint226_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1397.53 342.932) rotate(90.2188) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint227_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1397.53 342.932) rotate(90.2188) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint228_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1397.53 342.932) rotate(90.2188) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint229_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1407.5 346.009) rotate(90.2188) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint230_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1407.5 346.009) rotate(90.2188) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint231_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1407.5 346.009) rotate(90.2188) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint232_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1356.12 326.837) rotate(90.3155) scale(30.4129 41.463)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint233_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1356.12 326.837) rotate(90.3155) scale(30.4129 41.463)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint234_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1356.12 326.837) rotate(90.3155) scale(30.4129 41.463)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint235_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1356.12 326.837) rotate(90.3155) scale(30.4129 41.463)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint236_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1356.12 326.837) rotate(90.3155) scale(30.4129 41.463)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint237_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1356.12 326.837) rotate(90.3155) scale(30.4129 41.463)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint238_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1398.17 441.502) rotate(90) scale(45.6393 80.7363)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint239_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1387.84 437.478) rotate(90) scale(45.6393 90.5737)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint240_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1377.52 433.455) rotate(90) scale(45.6393 74.7829)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint241_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1367.19 429.431) rotate(90) scale(45.6393 87.2323)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint242_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1356.12 425.408) rotate(90) scale(45.6393 104.584)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint243_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1345.8 421.385) rotate(90) scale(45.6393 76.595)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <linearGradient id="paint244_linear_27_25" x1="1495.69" y1="408.107" x2="1495.69"
                                            y2="510.048" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-opacity="0"/>
                            </linearGradient>
                            <radialGradient id="paint245_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1524.63 449.412) rotate(90) scale(88.0264 4.82281)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint246_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1504.62 441.765) rotate(90) scale(87.8637 5.06395)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint247_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1485.08 434.443) rotate(90.0713) scale(62.6947 23.6969)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint248_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1465.79 426.796) rotate(90.1111) scale(51.9871 22.8361)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint249_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1465.79 426.796) rotate(90.1111) scale(51.9871 22.8361)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint250_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1485.01 434.444) rotate(90.1111) scale(51.9871 22.8361)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint251_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1504.23 442.092) rotate(90.1111) scale(51.9871 22.8361)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint252_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1524.75 449.778) rotate(90.1111) scale(51.9871 22.8361)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint253_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1546.57 449.575) rotate(89.9108) scale(55.2051 46.9216)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint254_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1586.17 435.698) rotate(89.9108) scale(55.2051 46.9216)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint255_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1606.3 426.393) rotate(89.9108) scale(55.2051 46.9216)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint256_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1566.11 442.204) rotate(89.9108) scale(55.2051 46.9216)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint257_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(1536.21 326.311) rotate(90) scale(25.9709 63.9591)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <linearGradient id="paint258_linear_27_25" x1="1548.47" y1="327.039" x2="1552.79"
                                            y2="337.775" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#1A1826"/>
                                <stop offset="1" stop-color="#865EFF" stop-opacity="0"/>
                            </linearGradient>
                            <linearGradient id="paint259_linear_27_25" x1="1522.08" y1="326.068" x2="1518.69"
                                            y2="335.509" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#1A1826"/>
                                <stop offset="1" stop-color="#865EFF" stop-opacity="0"/>
                            </linearGradient>
                            <linearGradient id="paint260_linear_27_25" x1="311.485" y1="181.529" x2="311.967"
                                            y2="495.831" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#E383FF"/>
                            </linearGradient>
                            <linearGradient id="paint261_linear_27_25" x1="430.207" y1="105.209" x2="430.207"
                                            y2="358.486" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#E383FF"/>
                            </linearGradient>
                            <linearGradient id="paint262_linear_27_25" x1="252" y1="181.266" x2="490.007" y2="181.266"
                                            gradientUnits="userSpaceOnUse">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#E5A2FF"/>
                            </linearGradient>
                            <linearGradient id="paint263_linear_27_25" x1="318.92" y1="48.2785" x2="319.007" y2="351.84"
                                            gradientUnits="userSpaceOnUse">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#E383FF"/>
                            </linearGradient>
                            <linearGradient id="paint264_linear_27_25" x1="423.018" y1="48.2785" x2="423.018"
                                            y2="222.085" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#E383FF"/>
                            </linearGradient>
                            <radialGradient id="paint265_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(371.766 48.2791) rotate(90) scale(34.7613 88.4301)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint266_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(371.766 48.2791) rotate(90) scale(34.7613 88.4301)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint267_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(385.84 51.8994) rotate(90) scale(8.69033 14.8711)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint268_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(265.012 256.124) rotate(90) scale(65.9017 5.57667)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint269_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(279.883 119.973) rotate(90) scale(63.0049 5.57667)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint270_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(294.754 125.768) rotate(90) scale(63.0049 5.57667)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint271_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(309.626 131.56) rotate(90) scale(63.0049 5.57667)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint272_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(324.497 137.355) rotate(90) scale(63.0049 5.57667)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint273_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(339.368 143.148) rotate(90) scale(63.0049 5.57667)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint274_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(354.239 148.942) rotate(90) scale(63.0049 5.57667)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint275_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(279.883 261.916) rotate(90) scale(65.9017 5.57667)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint276_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(294.754 267.71) rotate(90) scale(65.9017 5.57667)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint277_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(309.626 273.504) rotate(90) scale(65.9017 5.57667)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint278_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(324.497 279.297) rotate(90) scale(65.9017 5.57667)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint279_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(339.368 285.091) rotate(90) scale(65.9017 5.57667)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint280_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(354.239 290.884) rotate(90) scale(65.9017 5.57667)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint281_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(383.715 292.876) rotate(90) scale(65.7206 102.922)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint282_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(383.715 150.933) rotate(90) scale(62.8238 5.31112)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint283_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(398.587 145.14) rotate(90) scale(62.8238 5.31112)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint284_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(413.458 138.621) rotate(90) scale(62.8238 5.31112)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint285_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(428.329 133.552) rotate(90) scale(62.8238 5.31112)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint286_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(443.201 127.758) rotate(89.7812) scale(42.4313 31.638)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint287_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(443.201 127.758) rotate(89.7812) scale(42.4313 31.638)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint288_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(443.201 127.758) rotate(89.7812) scale(42.4313 31.638)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint289_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(428.341 133.327) rotate(89.7812) scale(42.4313 31.638)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint290_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(428.341 133.327) rotate(89.7812) scale(42.4313 31.638)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint291_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(428.341 133.327) rotate(89.7812) scale(42.4313 31.638)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint292_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(413.989 137.758) rotate(89.7812) scale(42.4313 31.638)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint293_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(413.989 137.758) rotate(89.7812) scale(42.4313 31.638)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint294_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(413.989 137.758) rotate(89.7812) scale(42.4313 31.638)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint295_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(413.989 137.758) rotate(89.7812) scale(42.4313 31.638)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint296_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(413.989 137.758) rotate(89.7812) scale(42.4313 31.638)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint297_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(413.989 137.758) rotate(89.7812) scale(42.4313 31.638)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint298_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(413.989 137.758) rotate(89.7812) scale(42.4313 31.638)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint299_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(413.989 137.758) rotate(89.7812) scale(42.4313 31.638)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint300_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(413.989 137.758) rotate(89.7812) scale(42.4313 31.638)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint301_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(398.441 145.141) rotate(89.7812) scale(42.4313 31.638)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint302_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(398.441 145.141) rotate(89.7812) scale(42.4313 31.638)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint303_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(398.441 145.141) rotate(89.7812) scale(42.4313 31.638)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint304_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(384.088 149.572) rotate(89.7812) scale(42.4313 31.638)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint305_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(384.088 149.572) rotate(89.7812) scale(42.4313 31.638)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint306_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(384.088 149.572) rotate(89.7812) scale(42.4313 31.638)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint307_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(458.071 121.965) rotate(89.6845) scale(43.7945 59.7067)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint308_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(458.071 121.965) rotate(89.6845) scale(43.7945 59.7067)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint309_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(458.071 121.965) rotate(89.6845) scale(43.7945 59.7067)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint310_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(458.071 121.965) rotate(89.6845) scale(43.7945 59.7067)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint311_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(458.071 121.965) rotate(89.6845) scale(43.7945 59.7067)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint312_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(458.071 121.965) rotate(89.6845) scale(43.7945 59.7067)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint313_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(397.524 287.082) rotate(90) scale(65.7206 116.26)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint314_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(412.395 281.289) rotate(90) scale(65.7206 130.426)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint315_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(427.266 275.495) rotate(90) scale(65.7206 107.687)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint316_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(442.138 269.701) rotate(90) scale(65.7206 125.614)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint317_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(458.071 263.908) rotate(90) scale(65.7206 150.601)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint318_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(472.943 258.114) rotate(90) scale(65.7206 110.297)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <linearGradient id="paint319_linear_27_25" x1="218.764" y1="207.592" x2="218.764"
                                            y2="391.087" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-opacity="0"/>
                            </linearGradient>
                            <radialGradient id="paint320_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(166.678 281.942) rotate(90) scale(158.448 8.68107)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint321_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(202.704 268.176) rotate(90) scale(158.155 9.11513)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint322_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(237.863 254.998) rotate(89.9287) scale(112.851 42.6545)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint323_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(272.586 241.233) rotate(89.8889) scale(93.5769 41.105)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint324_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(272.586 241.233) rotate(89.8889) scale(93.5769 41.105)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint325_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(237.994 254.999) rotate(89.8889) scale(93.5769 41.105)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint326_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(203.4 268.765) rotate(89.8889) scale(93.5769 41.105)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint327_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(166.458 282.6) rotate(89.8889) scale(93.5769 41.105)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint328_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(127.18 282.235) rotate(90.0892) scale(99.3693 84.4589)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint329_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(55.913 257.257) rotate(90.0892) scale(99.3693 84.4589)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint330_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(19.6688 240.509) rotate(90.0892) scale(99.3693 84.4589)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint331_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(92.0145 268.968) rotate(90.0892) scale(99.3693 84.4589)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint332_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(145.842 60.3598) rotate(90) scale(46.7476 115.126)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <linearGradient id="paint333_linear_27_25" x1="123.774" y1="61.6691" x2="115.998"
                                            y2="80.9943" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#1A1826"/>
                                <stop offset="1" stop-color="#865EFF" stop-opacity="0"/>
                            </linearGradient>
                            <linearGradient id="paint334_linear_27_25" x1="171.269" y1="59.9215" x2="177.378"
                                            y2="76.9145" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#1A1826"/>
                                <stop offset="1" stop-color="#865EFF" stop-opacity="0"/>
                            </linearGradient>
                            <linearGradient id="paint335_linear_27_25" x1="719.921" y1="254.864" x2="719.921"
                                            y2="316.029" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-opacity="0"/>
                            </linearGradient>
                            <radialGradient id="paint336_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(702.56 279.647) rotate(90) scale(52.8159 2.89369)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint337_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(714.568 275.059) rotate(90) scale(52.7182 3.03837)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint338_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(726.287 270.666) rotate(89.9287) scale(37.6168 14.2182)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint339_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(737.862 266.078) rotate(89.8889) scale(31.1923 13.7017)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint340_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(737.862 266.078) rotate(89.8889) scale(31.1923 13.7017)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint341_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(726.331 270.666) rotate(89.8889) scale(31.1923 13.7017)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint342_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(714.8 275.255) rotate(89.8889) scale(31.1923 13.7017)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint343_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(702.486 279.867) rotate(89.8889) scale(31.1923 13.7017)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint344_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(689.393 279.745) rotate(90.0892) scale(33.1231 28.1529)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint345_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(665.638 271.419) rotate(90.0892) scale(33.1231 28.1529)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint346_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(653.556 265.836) rotate(90.0892) scale(33.1231 28.1529)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint347_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(677.671 275.323) rotate(90.0892) scale(33.1231 28.1529)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint348_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(695.614 205.786) rotate(90) scale(15.5825 38.3755)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <linearGradient id="paint349_linear_27_25" x1="688.258" y1="206.223" x2="685.666"
                                            y2="212.665" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#1A1826"/>
                                <stop offset="1" stop-color="#865EFF" stop-opacity="0"/>
                            </linearGradient>
                            <linearGradient id="paint350_linear_27_25" x1="704.09" y1="205.641" x2="706.126"
                                            y2="211.305" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#1A1826"/>
                                <stop offset="1" stop-color="#865EFF" stop-opacity="0"/>
                            </linearGradient>
                            <linearGradient id="paint351_linear_27_25" x1="538.309" y1="254.201" x2="538.644"
                                            y2="472.466" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#E383FF"/>
                            </linearGradient>
                            <linearGradient id="paint352_linear_27_25" x1="620.755" y1="201.201" x2="620.755"
                                            y2="377.088" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#E383FF"/>
                            </linearGradient>
                            <linearGradient id="paint353_linear_27_25" x1="497" y1="254.018" x2="662.283" y2="254.018"
                                            gradientUnits="userSpaceOnUse">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#E5A2FF"/>
                            </linearGradient>
                            <linearGradient id="paint354_linear_27_25" x1="543.472" y1="161.666" x2="543.532"
                                            y2="372.473" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#E383FF"/>
                            </linearGradient>
                            <linearGradient id="paint355_linear_27_25" x1="615.762" y1="161.666" x2="615.762"
                                            y2="282.365" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#E383FF"/>
                            </linearGradient>
                            <radialGradient id="paint356_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(580.171 161.666) rotate(90) scale(24.1398 61.4098)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint357_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(580.171 161.666) rotate(90) scale(24.1398 61.4098)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint358_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(589.944 164.18) rotate(90) scale(6.03495 10.3272)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint359_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(506.036 306.003) rotate(90) scale(45.765 3.87269)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint360_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(516.363 211.454) rotate(90) scale(43.7534 3.87269)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint361_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(526.69 215.478) rotate(90) scale(43.7534 3.87269)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint362_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(537.017 219.5) rotate(90) scale(43.7534 3.87269)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint363_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(547.345 223.525) rotate(90) scale(43.7534 3.87269)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint364_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(557.672 227.547) rotate(90) scale(43.7534 3.87269)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint365_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(568 231.571) rotate(90) scale(43.7534 3.87269)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint366_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(516.363 310.025) rotate(90) scale(45.765 3.87269)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint367_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(526.69 314.049) rotate(90) scale(45.765 3.87269)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint368_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(537.017 318.072) rotate(90) scale(45.765 3.87269)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint369_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(547.344 322.095) rotate(90) scale(45.765 3.87269)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint370_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(557.672 326.119) rotate(90) scale(45.765 3.87269)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint371_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(568 330.142) rotate(90) scale(45.765 3.87269)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint372_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(588.47 331.525) rotate(90) scale(45.6393 71.4734)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint373_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(588.47 232.954) rotate(90) scale(43.6277 3.68827)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint374_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(598.797 228.93) rotate(90) scale(43.6277 3.68827)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint375_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(609.124 224.404) rotate(90) scale(43.6277 3.68827)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint376_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(619.451 220.883) rotate(90) scale(43.6277 3.68827)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint377_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(629.778 216.86) rotate(89.7812) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint378_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(629.778 216.86) rotate(89.7812) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint379_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(629.778 216.86) rotate(89.7812) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint380_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(619.46 220.727) rotate(89.7812) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint381_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(619.46 220.727) rotate(89.7812) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint382_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(619.46 220.727) rotate(89.7812) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint383_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(609.493 223.804) rotate(89.7812) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint384_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(609.493 223.804) rotate(89.7812) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint385_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(609.493 223.804) rotate(89.7812) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint386_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(609.493 223.804) rotate(89.7812) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint387_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(609.493 223.804) rotate(89.7812) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint388_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(609.493 223.804) rotate(89.7812) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint389_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(609.493 223.804) rotate(89.7812) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint390_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(609.493 223.804) rotate(89.7812) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint391_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(609.493 223.804) rotate(89.7812) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint392_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(598.695 228.932) rotate(89.7812) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint393_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(598.695 228.932) rotate(89.7812) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint394_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(598.695 228.932) rotate(89.7812) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint395_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(588.728 232.009) rotate(89.7812) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint396_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(588.728 232.009) rotate(89.7812) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint397_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(588.728 232.009) rotate(89.7812) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint398_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(640.105 212.837) rotate(89.6845) scale(30.4129 41.463)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint399_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(640.105 212.837) rotate(89.6845) scale(30.4129 41.463)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint400_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(640.105 212.837) rotate(89.6845) scale(30.4129 41.463)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint401_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(640.105 212.837) rotate(89.6845) scale(30.4129 41.463)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint402_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(640.105 212.837) rotate(89.6845) scale(30.4129 41.463)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint403_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(640.105 212.837) rotate(89.6845) scale(30.4129 41.463)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint404_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(598.058 327.502) rotate(90) scale(45.6393 80.7363)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint405_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(608.386 323.478) rotate(90) scale(45.6393 90.5737)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint406_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(618.713 319.455) rotate(90) scale(45.6393 74.7829)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint407_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(629.04 315.431) rotate(90) scale(45.6393 87.2323)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint408_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(640.105 311.408) rotate(90) scale(45.6393 104.584)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint409_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(650.432 307.385) rotate(90) scale(45.6393 76.595)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <linearGradient id="paint410_linear_27_25" x1="512.228" y1="310.485" x2="512.228"
                                            y2="392.039" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-opacity="0"/>
                            </linearGradient>
                            <radialGradient id="paint411_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(489.079 343.53) rotate(90) scale(70.4211 3.85825)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint412_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(505.09 337.412) rotate(90) scale(70.291 4.05117)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint413_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(520.717 331.554) rotate(89.9287) scale(50.1558 18.9575)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint414_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(536.149 325.437) rotate(89.8889) scale(41.5897 18.2689)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint415_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(536.149 325.437) rotate(89.8889) scale(41.5897 18.2689)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint416_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(520.775 331.555) rotate(89.8889) scale(41.5897 18.2689)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint417_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(505.4 337.673) rotate(89.8889) scale(41.5897 18.2689)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint418_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(488.981 343.822) rotate(89.8889) scale(41.5897 18.2689)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint419_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(471.524 343.66) rotate(90.0892) scale(44.1641 37.5373)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint420_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(439.85 332.559) rotate(90.0892) scale(44.1641 37.5373)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint421_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(423.742 325.114) rotate(90.0892) scale(44.1641 37.5373)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint422_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(455.896 337.763) rotate(90.0892) scale(44.1641 37.5373)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint423_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(479.819 245.049) rotate(90) scale(20.7767 51.1673)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <linearGradient id="paint424_linear_27_25" x1="470.011" y1="245.631" x2="466.555"
                                            y2="254.22" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#1A1826"/>
                                <stop offset="1" stop-color="#865EFF" stop-opacity="0"/>
                            </linearGradient>
                            <linearGradient id="paint425_linear_27_25" x1="491.119" y1="244.854" x2="493.834"
                                            y2="252.407" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#1A1826"/>
                                <stop offset="1" stop-color="#865EFF" stop-opacity="0"/>
                            </linearGradient>
                            <linearGradient id="paint426_linear_27_25" x1="420.505" y1="356.437" x2="420.505"
                                            y2="429.835" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-opacity="0"/>
                            </linearGradient>
                            <radialGradient id="paint427_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(399.671 386.177) rotate(90) scale(63.379 3.47243)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint428_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(414.082 380.671) rotate(90) scale(63.2619 3.64605)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint429_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(428.145 375.399) rotate(89.9287) scale(45.1402 17.0618)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint430_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(442.035 369.893) rotate(89.8889) scale(37.4307 16.442)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint431_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(442.035 369.893) rotate(89.8889) scale(37.4307 16.442)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint432_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(428.197 375.399) rotate(89.8889) scale(37.4307 16.442)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint433_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(414.36 380.906) rotate(89.8889) scale(37.4307 16.442)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint434_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(399.584 386.44) rotate(89.8889) scale(37.4307 16.442)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint435_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(383.872 386.294) rotate(90.0892) scale(39.7477 33.7835)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint436_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(355.366 376.303) rotate(90.0892) scale(39.7477 33.7835)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint437_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(340.868 369.603) rotate(90.0892) scale(39.7477 33.7835)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint438_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(369.806 380.987) rotate(90.0892) scale(39.7477 33.7835)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint439_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(391.337 297.544) rotate(90) scale(18.699 46.0506)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <linearGradient id="paint440_linear_27_25" x1="382.51" y1="298.068" x2="379.399"
                                            y2="305.798" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#1A1826"/>
                                <stop offset="1" stop-color="#865EFF" stop-opacity="0"/>
                            </linearGradient>
                            <linearGradient id="paint441_linear_27_25" x1="401.507" y1="297.369" x2="403.951"
                                            y2="304.166" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#1A1826"/>
                                <stop offset="1" stop-color="#865EFF" stop-opacity="0"/>
                            </linearGradient>
                            <linearGradient id="paint442_linear_27_25" x1="231.309" y1="372.201" x2="231.644"
                                            y2="590.466" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#E383FF"/>
                            </linearGradient>
                            <linearGradient id="paint443_linear_27_25" x1="313.755" y1="319.201" x2="313.755"
                                            y2="495.088" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#E383FF"/>
                            </linearGradient>
                            <linearGradient id="paint444_linear_27_25" x1="190" y1="372.018" x2="355.283" y2="372.018"
                                            gradientUnits="userSpaceOnUse">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#E5A2FF"/>
                            </linearGradient>
                            <linearGradient id="paint445_linear_27_25" x1="236.472" y1="279.666" x2="236.532"
                                            y2="490.473" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#E383FF"/>
                            </linearGradient>
                            <linearGradient id="paint446_linear_27_25" x1="308.762" y1="279.666" x2="308.762"
                                            y2="400.365" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#E383FF"/>
                            </linearGradient>
                            <radialGradient id="paint447_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(273.171 279.666) rotate(90) scale(24.1398 61.4098)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint448_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(273.171 279.666) rotate(90) scale(24.1398 61.4098)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint449_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(282.944 282.18) rotate(90) scale(6.03495 10.3272)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint450_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(199.036 424.003) rotate(90) scale(45.765 3.87269)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint451_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(209.363 329.454) rotate(90) scale(43.7534 3.87269)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint452_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(219.69 333.478) rotate(90) scale(43.7534 3.87269)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint453_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(230.017 337.5) rotate(90) scale(43.7534 3.87269)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint454_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(240.345 341.525) rotate(90) scale(43.7534 3.87269)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint455_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(250.672 345.547) rotate(90) scale(43.7534 3.87269)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint456_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(261 349.571) rotate(90) scale(43.7534 3.87269)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint457_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(209.363 428.025) rotate(90) scale(45.765 3.87269)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint458_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(219.69 432.049) rotate(90) scale(45.765 3.87269)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint459_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(230.017 436.072) rotate(90) scale(45.765 3.87269)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint460_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(240.344 440.095) rotate(90) scale(45.765 3.87269)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint461_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(250.672 444.119) rotate(90) scale(45.765 3.87269)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint462_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(261 448.142) rotate(90) scale(45.765 3.87269)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint463_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(281.47 449.525) rotate(90) scale(45.6393 71.4734)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint464_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(281.47 350.954) rotate(90) scale(43.6277 3.68827)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint465_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(291.797 346.93) rotate(90) scale(43.6277 3.68827)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint466_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(302.124 342.404) rotate(90) scale(43.6277 3.68827)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint467_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(312.451 338.883) rotate(90) scale(43.6277 3.68827)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint468_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(322.778 334.86) rotate(89.7812) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint469_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(322.778 334.86) rotate(89.7812) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint470_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(322.778 334.86) rotate(89.7812) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint471_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(312.46 338.727) rotate(89.7812) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint472_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(312.46 338.727) rotate(89.7812) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint473_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(312.46 338.727) rotate(89.7812) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint474_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(302.493 341.804) rotate(89.7812) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint475_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(302.493 341.804) rotate(89.7812) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint476_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(302.493 341.804) rotate(89.7812) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint477_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(302.493 341.804) rotate(89.7812) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint478_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(302.493 341.804) rotate(89.7812) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint479_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(302.493 341.804) rotate(89.7812) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint480_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(302.493 341.804) rotate(89.7812) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint481_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(302.493 341.804) rotate(89.7812) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint482_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(302.493 341.804) rotate(89.7812) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint483_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(291.695 346.932) rotate(89.7812) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint484_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(291.695 346.932) rotate(89.7812) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint485_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(291.695 346.932) rotate(89.7812) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint486_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(281.728 350.009) rotate(89.7812) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint487_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(281.728 350.009) rotate(89.7812) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint488_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(281.728 350.009) rotate(89.7812) scale(29.4662 21.9708)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint489_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(333.105 330.837) rotate(89.6845) scale(30.4129 41.463)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint490_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(333.105 330.837) rotate(89.6845) scale(30.4129 41.463)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint491_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(333.105 330.837) rotate(89.6845) scale(30.4129 41.463)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint492_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(333.105 330.837) rotate(89.6845) scale(30.4129 41.463)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint493_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(333.105 330.837) rotate(89.6845) scale(30.4129 41.463)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint494_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(333.105 330.837) rotate(89.6845) scale(30.4129 41.463)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint495_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(291.058 445.502) rotate(90) scale(45.6393 80.7363)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint496_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(301.386 441.478) rotate(90) scale(45.6393 90.5737)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint497_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(311.713 437.455) rotate(90) scale(45.6393 74.7829)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint498_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(322.04 433.431) rotate(90) scale(45.6393 87.2323)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint499_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(333.105 429.408) rotate(90) scale(45.6393 104.584)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <radialGradient id="paint500_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(343.432 425.385) rotate(90) scale(45.6393 76.595)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <linearGradient id="paint501_linear_27_25" x1="193.535" y1="412.107" x2="193.535"
                                            y2="514.048" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-opacity="0"/>
                            </linearGradient>
                            <radialGradient id="paint502_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(164.599 453.412) rotate(90) scale(88.0264 4.82281)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint503_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(184.613 445.765) rotate(90) scale(87.8637 5.06395)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint504_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(204.146 438.443) rotate(89.9287) scale(62.6947 23.6969)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint505_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(223.437 430.796) rotate(89.8889) scale(51.9871 22.8361)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint506_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(223.437 430.796) rotate(89.8889) scale(51.9871 22.8361)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint507_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(204.218 438.444) rotate(89.8889) scale(51.9871 22.8361)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint508_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(185 446.092) rotate(89.8889) scale(51.9871 22.8361)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint509_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(164.477 453.778) rotate(89.8889) scale(51.9871 22.8361)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint510_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(142.655 453.575) rotate(90.0892) scale(55.2051 46.9216)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint511_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(103.063 439.698) rotate(90.0892) scale(55.2051 46.9216)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint512_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(82.9272 430.393) rotate(90.0892) scale(55.2051 46.9216)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint513_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(123.12 446.204) rotate(90.0892) scale(55.2051 46.9216)">
                                <stop stop-color="#FFA2FD"/>
                                <stop offset="1" stop-color="#865EFF"/>
                            </radialGradient>
                            <radialGradient id="paint514_radial_27_25" cx="0" cy="0" r="1"
                                            gradientUnits="userSpaceOnUse"
                                            gradientTransform="translate(153.023 330.311) rotate(90) scale(25.9709 63.9591)">
                                <stop stop-color="#865EFF"/>
                                <stop offset="1" stop-color="#1A1826"/>
                            </radialGradient>
                            <linearGradient id="paint515_linear_27_25" x1="140.763" y1="331.039" x2="136.443"
                                            y2="341.775" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#1A1826"/>
                                <stop offset="1" stop-color="#865EFF" stop-opacity="0"/>
                            </linearGradient>
                            <linearGradient id="paint516_linear_27_25" x1="167.149" y1="330.068" x2="170.543"
                                            y2="339.509" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#1A1826"/>
                                <stop offset="1" stop-color="#865EFF" stop-opacity="0"/>
                            </linearGradient>
                        </defs>
                    </svg>
                    </div>


                </div>


                {/* <div className="timer-container">
        <table className="timer-hp">
            <tr className="timervalues-hp">
                <td id="days">00</td>
                <td id="hours">00</td>
                <td id="min">00</td>
                <td id="sec">00</td>
            </tr>
            <tr className="timertitles-hp" >
                <td>&ensp;&ensp;&ensp;&ensp;DAYS&ensp;&ensp;</td>
                <td>&ensp;&ensp;HOURS&ensp;</td>
                <td>&ensp;MINUTES&ensp;</td>
                <td>SECONDS</td>
            </tr>
        </table>
    </div>  */}


                <div className="timer-container">
                    <table className="timer-hp">
                        <tr>
                            <td>
                                <div className="timervalue-hp" id="days">0</div>
                                <div className="timertitle-hp" id="days">DAYS</div>
                            </td>
                            <td className="colon">:</td>
                            <td>
                                <div className="timervalue-hp" id="hours">00</div>
                                <div className="timertitle-hp">HOURS</div>
                            </td>
                            <td className="colon">:</td>
                            <td>
                                <div className="timervalue-hp" id="min">00</div>
                                <div className="timertitle-hp">MINUTES</div>
                            </td>
                            <td className="colon">:</td>
                            <td>
                                <div className="timervalue-hp" id="sec">00</div>
                                <div className="timertitle-hp" id="sec">SECONDS</div>
                            </td>
                        </tr>
                    </table>
                </div>

            </div>

        </div>
        </div>
    );
}

export default Home;