import React, {useState} from 'react';
import './Submission.css';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {TailSpin} from "react-loading-icons";
import Navbar from "../../components/Navbar";
import {Toast} from "react-bootstrap";
const Submission = () => {
    const loadPage = false;
  const userData  = JSON.parse(localStorage.getItem("userData"));
  const [url, setUrl] = useState("");
  const [submitterID, setSubmitterID] = useState(userData.id);
    const [topic, setTopic] = useState("");
    const [submissionLink, setSubmissionLink] = useState("");
    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const handleSubmit = (e) => {
        e.preventDefault();
        setIsSubmitLoading(true)
        try {
            ////console.log(userData);
            const data = {
                topic: topic,
                url: url,
                teamID: userData.teamID,
                submitterID: userData.id,
            };
            ////console.log(data);
            fetch("https://exodus-24-joiv.onrender.com/submission/add", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            })
                .then((data) => {
                    ////console.log(data);
                    if (data.status === 201) {
                        toast.success("Submission successful");
                        // navigate("/login");
                    } else {
                        toast.error("Submission failed");
                    }
                });
        } catch (err) {
            ////console.log(err);

        } finally {
            setIsSubmitLoading(false);
        }
    }
  return (
<>
    <ToastContainer/>
      <div class="submission-page">
        <img class="gears" src="images/gears.png" alt="Decorative gears" />
        <div class="gradient-box"></div>
        <div class="submission-box-background"></div>
        <div class="submission-box">
            {loadPage && (<div class="submission-title">Submission Form</div>)}
            {loadPage && (<form class="full-form" method="post" target="_blank">
                <div class="inputs">
                    {/*<label for="groupName">Group Name:</label>*/}
                    {/*<input type="text" name="groupName" id="groupName" required />*/}
                    {/*<label for="appId">Application Id:</label>*/}
                    {/*<input type="text" name="appId" id="appId" required />*/}
                    <label for="topic">Topic:</label>
                    <input type="text" name="topic" id="topic" value={topic} onChange={(e) => setTopic(e.target.value)}
                           required/>
                    <label for="subLink">Submission Link:</label>
                    <input type="url" value={url} onChange={(e) => setUrl(e.target.value)} name="subLink" id="subLink"
                           required/>
                </div>
                <div>
                    <input type="submit" value="SUBMIT" onClick={handleSubmit}/>
                    {isSubmitLoading && <TailSpin/>}
                </div>
            </form>)}
            {!loadPage && (<span className="not-submission">Submissions are closed now</span>)}
        </div>
        <div class="header-bar"></div>
        <div class="footer-bar"></div>
      </div>
</>
  );
}

export default Submission;