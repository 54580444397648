import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <div>
            <div className="foot_container-main">
                <div className="foot_container1">
                    <img className="foot_vectorsvg" src="../../images/Vector.svg" alt="" />
                    <div className="footer_Exodus">Exodus</div>
                </div>
                <div className="foot_container2">
                    <span className="foot_follow-text">Follow Us</span>
                    <div className="foot_social">
                        <a href="#"><img src="../../images/linkedin.svg" alt="linkedin" /></a
                        ><a href="#"><img src="../images/facebook.svg" alt="facebook" /></a
                        ><a href="#"><img src="../../images/Instagram.svg" alt="instagram" /></a
                        ><a href=""><img src="../../images/Frame.svg" alt="frame" /></a>
                    </div>
                </div>
                <div className="foot_container3">
                    <span><a href="#">Contact Us</a></span>
                </div>
            </div>
        </div>
    );
}

export default Footer;