import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import About from './pages/About/About';
import Home from './pages/Home/Home';
import Login from './pages/Login/Login';
import Dashboard from "./pages/Dashboard/Dashboard";
import Faq from "./pages/Faq/Faq";
import Timeline from "./pages/Timeline/TImeline";
import Signup from "./pages/Signup/Signup";
import Signup2 from "./pages/Signup/Signup2";
import Rules from "./pages/Rules/Rules";
import Submission from "./pages/Submission/Submission";
import Footer from "./components/Footer";
import React from "react";
import Navbar from "./components/Navbar";
function App() {
    return (
        <>
        <Router>
            <Navbar/>
            <Routes>
                <Route path="/" element={<Home />}/>
                <Route path="/about" element={<About />}/>
                <Route path="/login" element={<Login />}/>
                <Route path="/dashboard" element={<Dashboard />}/>
                <Route path="/faq" element={<Faq />}/>
                <Route path="/timeline" element={<Timeline />}/>
                <Route path="/signup" element={<Signup />}/>
                {/*<Route path="/signup_main" element={<Signup2 />}/>*/}
                <Route path="/rules" element={<Rules />}/>
                <Route path="/submission" element={<Submission />}/>
            </Routes>
        </Router>
            </>
    );
}

export default App;
