import React, {useState, useEffect} from "react";
import "./Dashboard.css"; // Assuming you have a separate CSS file
import {TailSpin} from "react-loading-icons";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {setupScopedFocus} from "react-modal/lib/helpers/focusManager";
const Dashboard = () => {

    //console.log("dashboard");
    const [userData, setUserData] = useState(JSON.parse(localStorage.getItem("userData"))); // Add this line
    //console.log(userData);
    // if teamID is null, redirect to login page
    const [inTeam, setInTeam] = useState(userData.teamID != null);

    const [actualTeamName, setActualTeamName] = useState(userData.teamName);
    const [teamId, setTeamId] = useState(userData.teamID);
    const [isLeader, setIsLeader] = useState(userData.isTeamLeader);

    //for creating team
    const [newTeamName, setNewTeamName] = useState("");
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [isSaveLoading, setIsSaveLoading] = useState(false);
    //for deleting team
    const [isDeleteLoading, setIsDeleteLoading] = useState(false);
    //for joining team
    const [isJoinLoading, setIsJoinLoading] = useState(false);
    const [isJoinModalOpen, setIsJoinModalOpen] = useState(false);
    const [newTeamId, setNewTeamId] = useState("");
    //for leaving team
    const [isLeaveLoading, setIsLeaveLoading] = useState(false);
    const email = userData.email;
    const firstPart = email.slice(0, 3);
    const lastPart = email.slice(email.lastIndexOf("@"), email.length);
    const maskedEmail = `${firstPart}****${lastPart}`;

    //change password
    const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState(false);
    const [isChangePasswordLoading, setIsChangePasswordLoading] = useState(false);
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");

    const openJoinModal = () => {
        setIsJoinModalOpen(true);
    }

    const closeJoinModal = () => {

        setIsJoinModalOpen(false);
    }

    const openCreateModal = () => {
        setIsCreateModalOpen(true);
    };

    const closeCreateModal = async (e) => {
        setIsCreateModalOpen(false);
    };



    const updateData = async () => {
        try {
            //console.log("updating data");
            let res = await fetch("https://exodus-24-joiv.onrender.com/team/getNewData", {
                method: "POST",
                headers: {
                    'Content-type': 'application/json'
                },
                body: JSON.stringify({
                    email: userData.email,
                }),
            });
            let resJson = await res.json();
            if (res.status === 201) {
                //update local storage
                localStorage.removeItem('userData');
                localStorage.setItem('userData', JSON.stringify(resJson));
                try{
                    if (resJson.teamID != null) {
                        setTeamId(resJson.teamID);
                    }else{
                        setTeamId(null);
                    }
                } catch (err) {
                    setTeamId(null);
                }
                setActualTeamName(resJson.teamName);
                setIsLeader(resJson.isTeamLeader);
                if (resJson.teamID != null) {
                    setInTeam(true);
                } else {
                    setInTeam(false);
                }
                //update state
                // Handle successful registration
            } else {
                // Handle failed registration
                //console.log("update failed")
                //console.log(resJson);
            }
        } catch (err) {
            //console.log(err);
        }
    }

    const deleteTeam = async () => {
        setIsDeleteLoading(true);
        try {
            //console.log("deleting team");
            let res = await fetch("https://exodus-24-joiv.onrender.com/team/deleteTeam", {
                method: "POST",
                headers: {
                    'Content-type': 'application/json'
                },
                body: JSON.stringify({
                    givenTeamID: teamId,
                }),
            });
            if (res.status === 201) {
                //console.log("team deleted");
                await updateData();

                // Handle successful registration
            } else {
                // Handle failed registration
                //console.log(res);
            }

            setTeamId(null);
            setActualTeamName(null);
            setInTeam(false);

        } catch (err) {
            //console.log(err);
        } finally {
            setIsDeleteLoading(false);
        }
    }


    const closeCreateModalSave = async (e) => {
        e.preventDefault();
        //console.log("close modal");
        setIsSaveLoading(true);
        // call api in backend to create team
        try{
            let res = await fetch("https://exodus-24-joiv.onrender.com/team/createTeam", {
                method: "POST",
                headers: {
                    'Content-type': 'application/json'
                },
                body: JSON.stringify({
                    teamName: newTeamName,
                    teamLeaderID: userData.id,
                }),
            });
            let resJson = await res.json();
            if (res.status === 201) {
                //console.log("team created");
                //console.log(resJson);
                await updateData();
                // Handle successful registration
            } else {
                // Handle failed registration
                //console.log(resJson);
                toast.error("Team creation failed!")
            }
        } catch (err) {
            //console.log(err);
        } finally {
            setIsSaveLoading(false);
            setIsCreateModalOpen(false);
        }

    };

    const leaveTeam = async () => {
        setIsLeaveLoading(true);
        try {
            //console.log("leaving team");
            let res = await fetch("https://exodus-24-joiv.onrender.com/team/leaveTeam", {
                method: "POST",
                headers: {
                    'Content-type': 'application/json'
                },
                body: JSON.stringify({
                    givenTeamID: teamId,
                    teamMemberID: userData.id,
                }),
            });
            // let resJson = await res.json();
            if (res.status === 201) {
                //console.log("team left");
                //console.log(res);
                setInTeam(false);
                setIsLeader(false);
                await updateData();
                // Handle successful registration
            } else {
                // Handle failed registration
                //console.log(res);
                toast.error("Team leaving failed!")
            }
        } catch (err) {
            //console.log(err);
        } finally {
            setIsLeaveLoading(false);
        }
    }

    const joinTeam = async () => {
        setIsJoinLoading(true);
        try {
            //console.log("joining team");
            let res = await fetch("https://exodus-24-joiv.onrender.com/team/joinTeam", {
                method: "POST",
                headers: {
                    'Content-type': 'application/json'
                },
                body: JSON.stringify({
                    givenTeamID: newTeamId,
                    teamMemberID: userData.id,
                }),
            });
            // let resJson = await res.json();
            if (res.status === 201) {
                //console.log("team joined");
                //console.log(res);
                setIsLeader(false);
                await updateData();
                // Handle successful registration
            } else {
                let error
                toast.error("Error joining team!")
                // Handle failed registration
                //console.log(res.body);
            }
        } catch (err) {
            //console.log(err);
        } finally {
            setIsJoinLoading(false);
            setIsJoinModalOpen(false);
        }
    }

    const changePassword = async () => {
        setIsChangePasswordLoading(true);
        try {
            //console.log("changing password");
            let res = await fetch("https://exodus-24-joiv.onrender.com/user/changePassword", {
                method: "POST",
                headers: {
                    'Content-type': 'application/json'
                },
                body: JSON.stringify({
                    email: userData.email,
                    oldPassword: oldPassword,
                    newPassword: newPassword,
                }),
            });
            // let resJson = await res.json();
            if (res.status === 201) {
                //console.log("password changed");
                //console.log(res);
                // Handle successful registration
            } else {
                toast.error("Password change failed!");
                // Handle failed registration
                //console.log(res);
            }
        } catch (err) {
            //console.log(err);
        }
    };

    return (
        <section className="section--dashboard">
            <ToastContainer/>
            <div className="container--dashboard">
                <div className="team-outer-box--dashboard">
                    { inTeam && (<div className="team-info-box--dashboard">
                        <div className="team-dashboard-info-div">
                            <div className="team-name--dashboard">Team Name</div>
                            <div className="team-data--dashboard">{actualTeamName}</div>
                        </div>
                        <div className="team-dashboard-info-div">
                            <div className="team-name--dashboard">Team ID</div>
                            <div className="team-data--dashboard">{teamId}</div>
                        </div>
                        {/*<span className="label--dashboard">Team ID : </span>*/}
                        {/*<span className="text-field--dashboard">{teamId}</span>*/}
                        {/*<span className="label--dashboard">Team Leader : </span>*/}
                        {/*<span className="text-field--dashboard">{userData.leaderName}</span>*/}
                    </div>)}
                    { !inTeam && (<div className="team-info-box--dashboard">
                        <div className="team-dashboard-info-div">
                            <div className="team-name--dashboard">Looks like you're not a part of any team</div>
                        </div>
                    </div>)}
                    <div className="team-buttons-box--dashboard">
                        {!isCreateModalOpen && !isJoinModalOpen && !inTeam && (
                            <>
                                <div className="button--dashboard" onClick={openCreateModal}>Create a team</div>
                                <div className="button--dashboard" onClick={openJoinModal}>Join a team</div>
                            </>
                        )}
                        {!isCreateModalOpen && inTeam && isLeader && (
                            <>
                                {/*<div className="button--dashboard">Add member</div>*/}
                                <div className="button--dashboard" onClick={deleteTeam}>{isDeleteLoading ? <div className="tailspin-black">
                                    <TailSpin/>
                                </div> : "Delete Team"}</div>
                            </>
                        )}

                        {!isCreateModalOpen && !isJoinModalOpen && inTeam && !isLeader && (
                            <>
                                {/*<div className="button--dashboard">Add member</div>*/}
                                <div className="button--dashboard" onClick={leaveTeam}>{isLeaveLoading ? <div className="tailspin-black">
                                    <TailSpin/>
                                </div> : "Leave Team"}</div>
                            </>
                        )}

                        {isCreateModalOpen && (
                            <div className="modal_dashboard">

                                <input type="textbox" className="label-modal" placeholder="Enter team name" value={newTeamName} onChange={(e) => setNewTeamName(e.target.value)}/>
                                <div className="modal_button">
                                    <div className="button--dashboard" onClick={closeCreateModalSave}>
                                        {isSaveLoading ? <div className="tailspin-black">
                                            <TailSpin/>
                                        </div> : "Save"}
                                    </div>
                                    <div className="button--dashboard" onClick={closeCreateModal}>Cancel</div>
                                </div>
                            </div>
                        )}

                        {isJoinModalOpen && (
                            <div className="modal_dashboard">

                                <input type="textbox" className="label-modal" placeholder="Enter team ID" value={newTeamId} onChange={(e) => setNewTeamId(e.target.value)}/>
                                <div className="modal_button">
                                    <div className="button--dashboard" onClick={joinTeam}>
                                        {isJoinLoading ? <div className="tailspin-black">
                                            <TailSpin/>
                                        </div> : "Join"}
                                    </div>
                                    <div className="button--dashboard" onClick={closeJoinModal}>Cancel</div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="details-outer-box--dashboard">
                    <span className="label--dashboard">Name : </span>
                    <span className="text-field--dashboard">{userData.name}</span>
                    <span className="label--dashboard">Reg No : </span>
                    <span className="text-field--dashboard">{userData.registrationNumber}</span>
                    <span className="label--dashboard">Email : </span>
                    <span className="text-field--dashboard">{maskedEmail}</span>
                    <span className="label--dashboard">Phone No : </span>
                    <span className="text-field--dashboard">{userData.phone}</span>
                    {/*<button className="button--dashboard btn-change-password--dashboard">*/}
                    {/*    Change Password*/}
                    {/*</button>*/}
                </div>
            </div>
        </section>
    );
};

export default Dashboard;