import React, { useEffect } from 'react';
import './Faq.css';

const Faq = () => {
    useEffect(() => {
        const boxes = document.querySelectorAll('.FAQ_Question');

        function toggleContent(event) {
            const arrow = event.currentTarget.querySelector('.FAQ_Arrow');
            const hiddenContent = event.currentTarget.querySelector('.FAQ_Answer');

            hiddenContent.style.display = (hiddenContent.style.display === 'none') ? 'block' : 'none';
            arrow.classList.toggle('FAQ_ArrowUp', hiddenContent.style.display !== 'none');
        }

        boxes.forEach((box) => {
            box.addEventListener('click', toggleContent);
        });

        return () => {
            boxes.forEach((box) => {
                box.removeEventListener('click', toggleContent);
            });
        };
    }, []);

    return (
        <div className="FAQ_Main">
            {/*<div className="FAQ_Header">Header</div>*/}
            <div className="FAQ_Container">
                <div className="FAQ_Image"></div>
                <div className="FAQ_Question q1"><p>What are the dates of the event?</p>
                    <div className="FAQ_Arrow"></div>
                    <div className="FAQ_Answer">The submission will be open from 26-28 January 2024.</div>
                </div>
                <div className="FAQ_Question q2"><p>Who all can participate?</p>
                    <div className="FAQ_Arrow"></div>
                    <div className="FAQ_Answer">Students from all colleges can participate in this event.</div>
                </div>
                <div className="FAQ_Question q3"><p>What is the prize pool?</p>
                    <div className="FAQ_Arrow"></div>
                    <div className="FAQ_Answer">The prize pool is 6500 Rupees.</div>
                </div>
                <div className="FAQ_Question q4"><p>Does the website need to have </p>
                    <p>any backend?</p>
                    <div className="FAQ_Arrow"></div>
                    <div className="FAQ_Answer">It is a front-end competition, so it's not compulsory to have a working backend.</div>
                </div>
                <div className="FAQ_Question q5"><p>What is the theme that the</p>
                    <p> website needs to be built?</p>
                    <div className="FAQ_Arrow"></div>
                    <div className="FAQ_Answer">The complete storyline is in the About section. The website is to be built on a company that delivers supplies to individuals stuck inside this entirely new planet they’re on.</div>
                </div>
            </div>
        </div>
    );
};

export default Faq;