import React, {useState, useEffect} from 'react';
import './Navbar.css';
import {useLocation, useNavigate} from "react-router-dom";

const Navbar = () => {
  const userData  = localStorage.getItem("userData");
  // if userData is null, then user is not logged in
    // if userData is not null, then user is logged in
  const location = useLocation();
  let data = location.state;
  //console.log(data);
  //console.log("data received");
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('userData') != null);
  const [version, setVersion] = useState(0); // Add this line
  useEffect(() => {
    if (data && data.id) {
      setIsLoggedIn(true);
    }
  }, [version, data]);
  useEffect(() => {
    if (!localStorage.getItem('firstLoad')) {
      localStorage.setItem('firstLoad', 'true');
    } else {
      // navigate('/'); // Or another page
    }
  }, []);
  useEffect(() => {
    if (localStorage.getItem('userId') != null) {
      setIsLoggedIn(true);
    }
  }, [version]); // Add version as a dependency

  const logout = () => {
    localStorage.removeItem("userId");
    setIsLoggedIn(false);
    localStorage.removeItem("userData");
    setVersion(prevVersion => prevVersion + 1); // Update version here
    navigate("/");
  }
  function toggleNavbar() {
    const navbar = document.getElementById('nav_dropdown');
    navbar.classList.toggle('vertical');
  }
  // const logout = () => {
  //   localStorage.removeItem("userId");
  //   setIsLoggedIn(false);
  //   navigate("/");
  // }
  const home = () => {
    navigate("/");
  }

  window.addEventListener("DOMContentLoaded", (event) => {
    document.getElementById('toggleButton').addEventListener('click', toggleNavbar);
  });
  return (
      <div>
        <div className="nav_container">
          <div className="nav_container1">
            <img className="Nav Vector" src="../../images/Vector.svg" alt="Icon" onClick={home}/>
            <div className="nav_exodus"><img src="../../images/EXODUS.svg" alt="exodus" onClick={home}/></div>
          </div>



          <div className="nav_container2">
            <div className="nav_elements"><a href="/">Home</a></div>
            <div className="nav_elements"><a href="/about">About</a></div>
            {isLoggedIn && (<div className="nav_elements"><a href="/submission">Submission</a></div>)}
            <div className="nav_elements"><a href="/faq">FAQ</a></div>
            <div className="nav_elements"><a href="/rules">Rules</a></div>
            <div className="nav_elements"><a href="/timeline">Timeline</a></div>
            {isLoggedIn && (<div className="nav_elements"><a href="/dashboard">User Dashboard</a></div>)}
            {/* <a href="javascript:void(0);" className="nav_icon" onclick={toggleNavbar} />
        <i className="fa fa-bars" ></i> */}
          </div>

          <div className="nav_container3">
            {!isLoggedIn && (
                <div className="nav_button">
                  <a href="/signup" className="nav_button1">Register</a>
                </div>
            )}
            {!isLoggedIn && (
                <div className="nav_button">
                  <a href="/login" className="nav_button2">Log In
                    <span><img className="nav_arrow" src="../../images/LoginArrow.svg" alt="Arrow" /></span>
                  </a>
                </div>
            )}

            {isLoggedIn && (
                <div className="nav_button" onClick={logout}>
                  <a href="/" className="nav_button2">Log Out
                    <span><img className="nav_arrow" src="../../images/LoginArrow.svg" alt="Arrow" /></span>
                  </a>
                </div>
            )}
            <button className="nav_menu_parent" id="toggleButton" onClick={toggleNavbar}>
              <div className="nav_hamburger_menu">
                <div className="nav_menubar"></div>
                <div className="nav_menubar"></div>
                <div className="nav_menubar"></div>
              </div>
            </button>


          </div>
        </div>

        <div className="nav_container4" id="nav_dropdown">
          <div className="nav_elements">
            <img src="../../images/NavHome.svg" alt="Icon" className="nav_icons"/>
            <a href="/">Home</a>
          </div>
          <div className="nav_elements">
            <img src="../../images/NavAbout.svg" alt="Icon" className="nav_icons"/>
            <a href="/about">About</a>
          </div>
          {isLoggedIn && (<div className="nav_elements">
            <img src="../../images/NavSubmission.svg" alt="Icon" className="nav_icons"/>
            <a href="/submission">Submission</a>
          </div>)}
          <div className="nav_elements">
            <img src="../../images/NavFAQ.svg" alt="Icon" className="nav_icons"/>
            <a href="/faq">FAQ</a>
          </div>
          {isLoggedIn && (<div className="nav_elements">
            <img src="../../images/NavDashboard.svg" alt="Icon" className="nav_icons"/>
            <a href="/dashboard">User Dashboard</a></div>)}
          {/* <a href="javascript:void(0);" className="nav_icon" onClick={toggleNavbar} /> */}
          <i className="fa fa-bars"></i>
          <div className="nav_elements">
            <img src="../../images/NavFAQ.svg" alt="Icon" className="nav_icons"/>
            <a href="/rules">Rules</a>
          </div>
          <div className="nav_elements">
            <img src="../../images/NavFAQ.svg" alt="Icon" className="nav_icons"/>
            <a href="/timeline">Timeline</a>
          </div>
        </div>
      </div>
  );
}

export default Navbar;