import React, {useState} from 'react'
import './Login.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate, useHistory } from 'react-router-dom';
import { TailSpin } from 'react-loading-icons';
const Login = () => {
    const [isLoading, setIsLoading] = useState(false); // Add this line
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loginStatus, setLoginStatus] = useState(false);

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true); // Set loading state to true
        try {
            let res = await fetch("https://exodus-24-joiv.onrender.com/login", {
                method: "POST",
                headers: {
                    'Content-type': 'application/json'
                },
                body: JSON.stringify({
                    email: email,
                    password: password,
                }),
            });
            let resJson = await res.json();
            if (res.status === 201){
                // Handle successful registration
                // //console.log(resJson);
                setLoginStatus(true)
                toast.success("Login successful!");
                //console.log(resJson);
                localStorage.removeItem('userData');
                localStorage.setItem('userData', JSON.stringify(resJson));
                // Clear all inputs
                setPassword("");
                setEmail("");

                navigate('/', {
                    state: {
                        id: resJson.id
                    }
                });
            } else {
                setLoginStatus(false)
                // Handle failed registration
                // //console.log(resJson);
                toast.error("Invalid Credentials!")
            }
        } catch (err) {
            localStorage.setItem("userId", null);
            toast.error("Invalid Credentials!")
            //console.log(err);
        } finally {
            setIsLoading(false); // Set loading state to false
        }
    };

    return (
        <>
        <div className="first_container_login">
            <ToastContainer />
    <div className="container_login"> 
        <div>
            <p className="header_login">LOGIN</p>
        </div>
        <div className="middle_login">
            <p className="detail_login">EMAIL ID:</p>
            <input className="enterdetails_login" type="email" value={email} onChange={handleEmailChange} />
            <p className="passdetail_login">PASSWORD: </p>
            <input className="enterdetails_login" type="password" value={password} onChange={handlePasswordChange}/>
        </div>
        <div>
            <button className="buttons_login" type="submit" onClick={handleSubmit} >
                {isLoading ? <TailSpin className="loader" /> : "Login"}
            </button>
        </div>
        <div>
            <p className="account_login">Don't have an account yet?<Link to="/signup" style={{ textDecoration: 'none' }}><span className="seperate_login">Sign Up</span></Link></p>
        </div>
    </div>
    </div>
        </>
    )
}
export default Login;