import React, {useState} from 'react';
import './Signup.css';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Link} from 'react-router-dom';
import {useNavigate, useLocation} from 'react-router-dom';
import {TailSpin} from 'react-loading-icons';

const Signup = () => {

    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [phoneNumber, setPhoneNumber] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [ieeeId, setIeeeId] = useState("");
    const [registrationNumber, setRegistrationNumber] = useState("");

    // const nextPage = () => {
    //     localStorage.setItem("phoneNumber", phoneNumber);
    //     localStorage.setItem("name", name);
    //     localStorage.setItem("email", email);
    //     localStorage.setItem("password", password)
    //     navigate("/signup_main");
    // }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true); // Set loading state to true
        try {
            let res = await fetch("https://exodus-24-joiv.onrender.com/signup", {
                method: "POST",
                headers: {
                    'Content-type': 'application/json'
                },
                body: JSON.stringify({
                    name: name,
                    phone: phoneNumber,
                    regNo: registrationNumber,
                    ieeeID: ieeeId,
                    email: email,
                    password: password,
                }),
            });
            //console.log(res);
            let resJson = await res.json();
            if (res.status === 201) {
                // Clear all inputs
                setPhoneNumber("");
                setName("");
                setEmail("");
                setPassword("");
                setIeeeId("");
                setRegistrationNumber("");
                // Handle successful registration
                //console.log(resJson);
                toast.success("Registration successful!");
                //wait for toast to disappear
                navigate('/login');

            } else {
                // Handle failed registration
                //console.log(resJson);
                toast.error("Registration failed!")
            }
        } catch (err) {
            //console.log(err);
            toast.error("Registration failed!")
        } finally {
            setIsLoading(false); // Set loading state to false
        }
    };


    return (
        <div className="outer_container_signup">
            <ToastContainer/>
            <div className="inner_container_signup">
                <div>
                    <p className="header_signup">SIGN UP</p>
                </div>
                {/*<form onSubmit={handleSubmit}>*/}
                <div className="middle_signup">
                    <p className="detail_signup">PHONE NUMBER:</p>
                    <input className="enterdetails_signup" type="textbox" value={phoneNumber}
                           onChange={(e) => setPhoneNumber(e.target.value)}/>
                    <p className="passdetail_signup">NAME: </p>
                    <input className="enterdetails_signup" value={name} type="textbox" onChange={(e) => setName(e.target.value)}/>
                    <p className="passdetail_signup">EMAIL ID: </p>
                    <input className="enterdetails_signup" type="email" value={email} onChange={(e) => setEmail(e.target.value)}/>
                    <p className="detail_signup">Registration Number:</p>
                    <input className="enterdetails_signup" type="textbox" value={registrationNumber}
                           onChange={(e) => setRegistrationNumber(e.target.value)}/>
                    <p className="passdetail_signup">IEEE Membership ID (if available) </p>
                    <input className="enterdetails_signup" type="textbox" value={ieeeId} onChange={(e) => setIeeeId(e.target.value)}/>
                    <p className="passdetail_signup">PASSWORD: </p>
                    <input className="enterdetails_signup" type="password" value={password}
                           onChange={(e) => setPassword(e.target.value)}/>
                </div>
                <div>
                    <button className="buttons_signup" type="submit" onClick={handleSubmit} disabled={isLoading}>
                        {isLoading ? <TailSpin/> : "SIGN UP"}
                    </button>
                </div>
                {/*</form>*/}

                <div>'

                    {/*<p className="account_signup">Already have an account?*/}
                    {/*    <Link to="/login" style={{textDecoration: 'none'}}><span*/}
                    {/*        className="seperate_signup">LOG IN</span></Link></p>*/}
                </div>
            </div>
        </div>
    );
}

export default Signup;