import React from 'react';
import './Timeline.css';
const Timeline = () => {
    return (

        <div className="timeline_main">
            <h1 className="timeline_h1">Timeline</h1>
            <div className="tl_container">
                <svg width="85%" height="782" id="pc"viewBox="0 0 2062 782" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M147.381 777.74V778.831H330.252L422 619.916L330.252 461L146.751 461L55 619.916L146.753 778.831H147.381V777.74L148.326 777.195L57.5208 619.916L148.012 463.182L328.99 463.182L419.481 619.916L328.99 776.649H147.381V777.74Z" fill="url(#paint0_linear_117_460)"/>
                    <path d="M147.381 777.74V778.831H330.252L422 619.916L330.252 461L146.751 461L55 619.916L146.753 778.831H147.381V777.74L148.326 777.195L57.5208 619.916L148.012 463.182L328.99 463.182L419.481 619.916L328.99 776.649H147.381V777.74Z" fill="white"/>
                    <g filter="url(#filter0_bdd_117_460)">
                        <path d="M158.232 758.759V763.124L322.892 763.124L406.482 618.339L322.892 473.555L155.711 473.555L72.1211 618.339L155.711 763.124H158.232V758.759L162.012 756.576L82.2018 618.339L160.751 482.285H317.853L396.404 618.339L317.853 754.394L158.232 754.394V758.759Z" fill="url(#paint1_linear_117_460)"/>
                        <path d="M157.732 759.625V758.759V754.394V753.894H158.232H159.886L81.7688 618.589L81.6245 618.339L81.7688 618.089L160.318 482.035L160.462 481.785H160.751L317.853 481.785H318.141L318.286 482.035L396.837 618.089L396.981 618.339L396.837 618.589L318.286 754.644L318.141 754.894H317.853H161.618L162.445 756.326L162.695 756.759L162.262 757.009L158.732 759.047V762.624H322.603L405.905 618.339L322.603 474.055L156 474.055L72.6985 618.339L156 762.624H157.732V759.625ZM161.329 756.393L160.463 754.894H158.732V757.893L161.329 756.393Z" stroke="black"/>
                    </g>
                    <path d="M905.381 780.74V781.831H1088.25L1180 622.916L1088.25 464L904.751 464L813 622.916L904.753 781.831H905.381V780.74L906.326 780.195L815.521 622.916L906.012 466.182L1086.99 466.182L1177.48 622.916L1086.99 779.649H905.381V780.74Z" fill="url(#paint2_linear_117_460)"/>
                    <path d="M905.381 780.74V781.831H1088.25L1180 622.916L1088.25 464L904.751 464L813 622.916L904.753 781.831H905.381V780.74L906.326 780.195L815.521 622.916L906.012 466.182L1086.99 466.182L1177.48 622.916L1086.99 779.649H905.381V780.74Z" fill="white"/>
                    <g filter="url(#filter1_bdd_117_460)">
                        <path d="M916.232 761.759V766.124H1080.89L1164.48 621.339L1080.89 476.555L913.711 476.555L830.121 621.339L913.711 766.124H916.232V761.759L920.012 759.576L840.202 621.339L918.751 485.285L1075.85 485.285L1154.4 621.339L1075.85 757.394H916.232V761.759Z" fill="url(#paint3_linear_117_460)"/>
                        <path d="M915.732 762.625V761.759V757.394V756.894H916.232H917.886L839.769 621.589L839.624 621.339L839.769 621.089L918.318 485.035L918.462 484.785H918.751L1075.85 484.785H1076.14L1076.29 485.035L1154.84 621.089L1154.98 621.339L1154.84 621.589L1076.29 757.644L1076.14 757.894H1075.85H919.618L920.445 759.326L920.695 759.759L920.262 760.009L916.732 762.047V765.624H1080.6L1163.91 621.339L1080.6 477.055L914 477.055L830.698 621.339L914 765.624H915.732V762.625ZM919.329 759.393L918.463 757.894H916.732V760.893L919.329 759.393Z" stroke="black"/>
                    </g>
                    <path d="M1691.38 780.74V781.831H1874.25L1966 622.916L1874.25 464L1690.75 464L1599 622.916L1690.75 781.831H1691.38V780.74L1692.33 780.195L1601.52 622.916L1692.01 466.182L1872.99 466.182L1963.48 622.916L1872.99 779.649H1691.38V780.74Z" fill="url(#paint4_linear_117_460)"/>
                    <path d="M1691.38 780.74V781.831H1874.25L1966 622.916L1874.25 464L1690.75 464L1599 622.916L1690.75 781.831H1691.38V780.74L1692.33 780.195L1601.52 622.916L1692.01 466.182L1872.99 466.182L1963.48 622.916L1872.99 779.649H1691.38V780.74Z" fill="white"/>
                    <g filter="url(#filter2_bdd_117_460)">
                        <path d="M1702.23 761.759V766.124L1866.89 766.124L1950.48 621.339L1866.89 476.555L1699.71 476.555L1616.12 621.339L1699.71 766.124H1702.23V761.759L1706.01 759.576L1626.2 621.339L1704.75 485.285H1861.85L1940.4 621.339L1861.85 757.394L1702.23 757.394V761.759Z" fill="url(#paint5_linear_117_460)"/>
                        <path d="M1701.73 762.625V761.759V757.394V756.894H1702.23H1703.89L1625.77 621.589L1625.62 621.339L1625.77 621.089L1704.32 485.035L1704.46 484.785H1704.75L1861.85 484.785H1862.14L1862.29 485.035L1940.84 621.089L1940.98 621.339L1940.84 621.589L1862.29 757.644L1862.14 757.894H1861.85H1705.62L1706.45 759.326L1706.7 759.759L1706.26 760.009L1702.73 762.047V765.624H1866.6L1949.91 621.339L1866.6 477.055L1700 477.055L1616.7 621.339L1700 765.624H1701.73V762.625ZM1705.33 759.393L1704.46 757.894H1702.73V760.893L1705.33 759.393Z" stroke="black"/>
                    </g>
                    <g filter="url(#filter3_d_117_460)">
                        <path d="M4.33335 386.973C4.3099 401.701 16.23 413.652 30.9575 413.667C45.6851 413.681 57.6432 401.754 57.6667 387.027C57.6901 372.299 45.77 360.348 31.0425 360.333C16.3149 360.319 4.3568 372.246 4.33335 386.973ZM2004.33 388.973C2004.31 403.701 2016.23 415.652 2030.96 415.667C2045.69 415.681 2057.64 403.754 2057.67 389.027C2057.69 374.299 2045.77 362.348 2031.04 362.333C2016.31 362.319 2004.36 374.246 2004.33 388.973ZM30.992 392L2030.99 394L2031.01 384L31.008 382L30.992 392Z" fill="white"/>
                    </g>
                    <path d="M240.702 386C240.702 385.172 240.031 384.5 239.202 384.5C238.374 384.5 237.702 385.172 237.702 386H240.702ZM231.202 464C231.202 468.418 234.784 472 239.202 472C243.62 472 247.202 468.418 247.202 464C247.202 459.582 243.62 456 239.202 456C234.784 456 231.202 459.582 231.202 464ZM237.702 386V464H240.702V386H237.702Z" fill="white"/>
                    <path d="M613.536 318C613.536 322.418 617.118 326 621.536 326C625.954 326 629.536 322.418 629.536 318C629.536 313.582 625.954 310 621.536 310C617.118 310 613.536 313.582 613.536 318ZM620.036 386.449C620.036 387.278 620.708 387.949 621.536 387.949C622.365 387.949 623.036 387.278 623.036 386.449H620.036ZM620.036 318V386.449H623.036V318H620.036Z" fill="white"/>
                    <path d="M1408.49 318C1408.49 322.418 1412.07 326 1416.49 326C1420.91 326 1424.49 322.418 1424.49 318C1424.49 313.582 1420.91 310 1416.49 310C1412.07 310 1408.49 313.582 1408.49 318ZM1414.99 386.449C1414.99 387.278 1415.66 387.949 1416.49 387.949C1417.32 387.949 1417.99 387.278 1417.99 386.449H1414.99ZM1414.99 318V386.449H1417.99V318H1414.99Z" fill="white"/>
                    <path d="M997.8 386C997.8 385.172 997.128 384.5 996.3 384.5C995.471 384.5 994.8 385.172 994.8 386H997.8ZM988.3 464C988.3 468.418 991.882 472 996.3 472C1000.72 472 1004.3 468.418 1004.3 464C1004.3 459.582 1000.72 456 996.3 456C991.882 456 988.3 459.582 988.3 464ZM994.8 386V464H997.8V386H994.8Z" fill="white"/>
                    <path d="M1781.4 386C1781.4 385.172 1780.72 384.5 1779.9 384.5C1779.07 384.5 1778.4 385.172 1778.4 386H1781.4ZM1771.9 464C1771.9 468.418 1775.48 472 1779.9 472C1784.31 472 1787.9 468.418 1787.9 464C1787.9 459.582 1784.31 456 1779.9 456C1775.48 456 1771.9 459.582 1771.9 464ZM1778.4 386V464H1781.4V386H1778.4Z" fill="white"/>
                    <path d="M523.381 316.74V317.831L706.252 317.831L798 158.916L706.252 0L522.751 0L431 158.916L522.753 317.831H523.381V316.74L524.326 316.195L433.521 158.916L524.012 2.18246L704.99 2.18246L795.481 158.916L704.99 315.649L523.381 315.649V316.74Z" fill="url(#paint6_linear_117_460)"/>
                    <path d="M523.381 316.74V317.831L706.252 317.831L798 158.916L706.252 0L522.751 0L431 158.916L522.753 317.831H523.381V316.74L524.326 316.195L433.521 158.916L524.012 2.18246L704.99 2.18246L795.481 158.916L704.99 315.649L523.381 315.649V316.74Z" fill="white"/>
                    <g filter="url(#filter4_bdd_117_460)">
                        <path d="M534.232 297.759V302.124L698.892 302.124L782.482 157.339L698.892 12.5549L531.711 12.5549L448.121 157.339L531.711 302.124H534.232V297.759L538.012 295.576L458.202 157.339L536.751 21.2848L693.853 21.2848L772.404 157.339L693.853 293.394L534.232 293.394V297.759Z" fill="url(#paint7_linear_117_460)"/>
                        <path d="M533.732 298.625V297.759V293.394V292.894H534.232H535.886L457.769 157.589L457.625 157.339L457.769 157.089L536.318 21.0348L536.462 20.7848H536.751L693.853 20.7848H694.141L694.286 21.0348L772.837 157.089L772.981 157.339L772.837 157.589L694.286 293.644L694.141 293.894H693.853L537.618 293.894L538.445 295.326L538.695 295.759L538.262 296.009L534.732 298.047V301.624L698.603 301.624L781.905 157.339L698.603 13.0549L532 13.0549L448.698 157.339L532 301.624H533.732V298.625ZM537.329 295.393L536.463 293.894H534.732V296.893L537.329 295.393Z" stroke="black"/>
                    </g>
                    <path d="M1324.38 316.74V317.831L1507.25 317.831L1599 158.916L1507.25 0L1323.75 0L1232 158.916L1323.75 317.831H1324.38V316.74L1325.33 316.195L1234.52 158.916L1325.01 2.18246L1505.99 2.18246L1596.48 158.916L1505.99 315.649L1324.38 315.649V316.74Z" fill="url(#paint8_linear_117_460)"/>
                    <path d="M1324.38 316.74V317.831L1507.25 317.831L1599 158.916L1507.25 0L1323.75 0L1232 158.916L1323.75 317.831H1324.38V316.74L1325.33 316.195L1234.52 158.916L1325.01 2.18246L1505.99 2.18246L1596.48 158.916L1505.99 315.649L1324.38 315.649V316.74Z" fill="white"/>
                    <g filter="url(#filter5_bdd_117_460)">
                        <path d="M1335.23 297.759V302.124L1499.89 302.124L1583.48 157.339L1499.89 12.5549L1332.71 12.5549L1249.12 157.339L1332.71 302.124H1335.23V297.759L1339.01 295.576L1259.2 157.339L1337.75 21.2848L1494.85 21.2848L1573.4 157.339L1494.85 293.394L1335.23 293.394V297.759Z" fill="url(#paint9_linear_117_460)"/>
                        <path d="M1334.73 298.625V297.759V293.394V292.894H1335.23H1336.89L1258.77 157.589L1258.62 157.339L1258.77 157.089L1337.32 21.0348L1337.46 20.7848H1337.75L1494.85 20.7848H1495.14L1495.29 21.0348L1573.84 157.089L1573.98 157.339L1573.84 157.589L1495.29 293.644L1495.14 293.894H1494.85L1338.62 293.894L1339.45 295.326L1339.7 295.759L1339.26 296.009L1335.73 298.047V301.624L1499.6 301.624L1582.91 157.339L1499.6 13.0549L1333 13.0549L1249.7 157.339L1333 301.624H1334.73V298.625ZM1338.33 295.393L1337.46 293.894H1335.73V296.893L1338.33 295.393Z" stroke="black"/>
                    </g>
                    <defs>
                        <filter id="filter0_bdd_117_460" x="68.1211" y="469.555" width="342.361" height="301.569" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                            <feGaussianBlur in="BackgroundImageFix" stdDeviation="2"/>
                            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_117_460"/>
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                            <feOffset dy="4"/>
                            <feGaussianBlur stdDeviation="2"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                            <feBlend mode="normal" in2="effect1_backgroundBlur_117_460" result="effect2_dropShadow_117_460"/>
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                            <feOffset dy="4"/>
                            <feGaussianBlur stdDeviation="2"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                            <feBlend mode="normal" in2="effect2_dropShadow_117_460" result="effect3_dropShadow_117_460"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_117_460" result="shape"/>
                        </filter>
                        <filter id="filter1_bdd_117_460" x="826.121" y="472.555" width="342.361" height="301.569" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                            <feGaussianBlur in="BackgroundImageFix" stdDeviation="2"/>
                            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_117_460"/>
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                            <feOffset dy="4"/>
                            <feGaussianBlur stdDeviation="2"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                            <feBlend mode="normal" in2="effect1_backgroundBlur_117_460" result="effect2_dropShadow_117_460"/>
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                            <feOffset dy="4"/>
                            <feGaussianBlur stdDeviation="2"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                            <feBlend mode="normal" in2="effect2_dropShadow_117_460" result="effect3_dropShadow_117_460"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_117_460" result="shape"/>
                        </filter>
                        <filter id="filter2_bdd_117_460" x="1612.12" y="472.555" width="342.361" height="301.569" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                            <feGaussianBlur in="BackgroundImageFix" stdDeviation="2"/>
                            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_117_460"/>
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                            <feOffset dy="4"/>
                            <feGaussianBlur stdDeviation="2"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                            <feBlend mode="normal" in2="effect1_backgroundBlur_117_460" result="effect2_dropShadow_117_460"/>
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                            <feOffset dy="4"/>
                            <feGaussianBlur stdDeviation="2"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                            <feBlend mode="normal" in2="effect2_dropShadow_117_460" result="effect3_dropShadow_117_460"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_117_460" result="shape"/>
                        </filter>
                        <filter id="filter3_d_117_460" x="0.333008" y="360.333" width="2061.33" height="63.3335" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                            <feOffset dy="4"/>
                            <feGaussianBlur stdDeviation="2"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_117_460"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_117_460" result="shape"/>
                        </filter>
                        <filter id="filter4_bdd_117_460" x="444.121" y="8.55493" width="342.361" height="301.569" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                            <feGaussianBlur in="BackgroundImageFix" stdDeviation="2"/>
                            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_117_460"/>
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                            <feOffset dy="4"/>
                            <feGaussianBlur stdDeviation="2"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                            <feBlend mode="normal" in2="effect1_backgroundBlur_117_460" result="effect2_dropShadow_117_460"/>
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                            <feOffset dy="4"/>
                            <feGaussianBlur stdDeviation="2"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                            <feBlend mode="normal" in2="effect2_dropShadow_117_460" result="effect3_dropShadow_117_460"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_117_460" result="shape"/>
                        </filter>
                        <filter id="filter5_bdd_117_460" x="1245.12" y="8.55493" width="342.361" height="301.569" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                            <feGaussianBlur in="BackgroundImageFix" stdDeviation="2"/>
                            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_117_460"/>
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                            <feOffset dy="4"/>
                            <feGaussianBlur stdDeviation="2"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                            <feBlend mode="normal" in2="effect1_backgroundBlur_117_460" result="effect2_dropShadow_117_460"/>
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                            <feOffset dy="4"/>
                            <feGaussianBlur stdDeviation="2"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                            <feBlend mode="normal" in2="effect2_dropShadow_117_460" result="effect3_dropShadow_117_460"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_117_460" result="shape"/>
                        </filter>
                        <linearGradient id="paint0_linear_117_460" x1="238.5" y1="461" x2="238.5" y2="778.831" gradientUnits="userSpaceOnUse">
                            <stop offset="0.108333" stop-color="white"/>
                            <stop offset="0.233333" stop-color="#696A6A" stop-opacity="0.15"/>
                            <stop offset="0.4625" stop-color="white"/>
                            <stop offset="1" stop-color="white" stop-opacity="0.62"/>
                        </linearGradient>
                        <linearGradient id="paint1_linear_117_460" x1="239.302" y1="473.555" x2="239.302" y2="763.124" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#A65454"/>
                            <stop offset="0.0001" stop-color="#4F398E"/>
                            <stop offset="0.365"/>
                            <stop offset="0.62" stop-color="#210334"/>
                            <stop offset="1" stop-color="#5A3DAA"/>
                        </linearGradient>
                        <linearGradient id="paint2_linear_117_460" x1="996.5" y1="464" x2="996.5" y2="781.831" gradientUnits="userSpaceOnUse">
                            <stop offset="0.108333" stop-color="white"/>
                            <stop offset="0.233333" stop-color="#696A6A" stop-opacity="0.15"/>
                            <stop offset="0.4625" stop-color="white"/>
                            <stop offset="1" stop-color="white" stop-opacity="0.62"/>
                        </linearGradient>
                        <linearGradient id="paint3_linear_117_460" x1="997.302" y1="476.555" x2="997.302" y2="766.124" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#A65454"/>
                            <stop offset="0.0001" stop-color="#4F398E"/>
                            <stop offset="0.365"/>
                            <stop offset="0.62" stop-color="#210334"/>
                            <stop offset="1" stop-color="#5A3DAA"/>
                        </linearGradient>
                        <linearGradient id="paint4_linear_117_460" x1="1782.5" y1="464" x2="1782.5" y2="781.831" gradientUnits="userSpaceOnUse">
                            <stop offset="0.108333" stop-color="white"/>
                            <stop offset="0.233333" stop-color="#696A6A" stop-opacity="0.15"/>
                            <stop offset="0.4625" stop-color="white"/>
                            <stop offset="1" stop-color="white" stop-opacity="0.62"/>
                        </linearGradient>
                        <linearGradient id="paint5_linear_117_460" x1="1783.3" y1="476.555" x2="1783.3" y2="766.124" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#A65454"/>
                            <stop offset="0.0001" stop-color="#4F398E"/>
                            <stop offset="0.365"/>
                            <stop offset="0.62" stop-color="#210334"/>
                            <stop offset="1" stop-color="#5A3DAA"/>
                        </linearGradient>
                        <linearGradient id="paint6_linear_117_460" x1="614.5" y1="0" x2="614.5" y2="317.831" gradientUnits="userSpaceOnUse">
                            <stop offset="0.108333" stop-color="white"/>
                            <stop offset="0.233333" stop-color="#696A6A" stop-opacity="0.15"/>
                            <stop offset="0.4625" stop-color="white"/>
                            <stop offset="1" stop-color="white" stop-opacity="0.62"/>
                        </linearGradient>
                        <linearGradient id="paint7_linear_117_460" x1="615.302" y1="12.5549" x2="615.302" y2="302.124" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#A65454"/>
                            <stop offset="0.0001" stop-color="#4F398E"/>
                            <stop offset="0.365"/>
                            <stop offset="0.62" stop-color="#210334"/>
                            <stop offset="1" stop-color="#5A3DAA"/>
                        </linearGradient>
                        <linearGradient id="paint8_linear_117_460" x1="1415.5" y1="0" x2="1415.5" y2="317.831" gradientUnits="userSpaceOnUse">
                            <stop offset="0.108333" stop-color="white"/>
                            <stop offset="0.233333" stop-color="#696A6A" stop-opacity="0.15"/>
                            <stop offset="0.4625" stop-color="white"/>
                            <stop offset="1" stop-color="white" stop-opacity="0.62"/>
                        </linearGradient>
                        <linearGradient id="paint9_linear_117_460" x1="1416.3" y1="12.5549" x2="1416.3" y2="302.124" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#A65454"/>
                            <stop offset="0.0001" stop-color="#4F398E"/>
                            <stop offset="0.365"/>
                            <stop offset="0.62" stop-color="#210334"/>
                            <stop offset="1" stop-color="#5A3DAA"/>
                        </linearGradient>
                    </defs>
                    <text x="25%" y="14%" fill="white" className="tl_first">Registrations
                        <tspan x="28%"dy ="5%" >
                            Ends
                        </tspan>
                        <tspan x="24%"dy ="5%" >
                            It's takeoff time!
                        </tspan>
                        <tspan x="25%"dy ="5%" fill='gold'>
                            27th January
                        </tspan>
                    </text>
                    <text x="6.5%" y="75%" fill="white" className="tl_first">Registrations
                        <tspan x="9.5%"dy ="5%" >
                            Starts
                        </tspan>
                        <tspan fill='gold' x="6.5%"dy ="5%">
                            23rd January
                        </tspan>
                    </text>
                    <text x="43.5%" y="76%" fill="white" className="tl_first">Competition
                        <tspan x="46%"dy ="5%" >
                            Begins!
                        </tspan>
                        <tspan x="43.5%"dy ="5%" fill='gold'>
                            26th January
                        </tspan>
                    </text>
                    <text x="65.5%" y="11%" fill="white" className="tl_first">Working
                        <tspan x="66.5%"dy ="5%">
                            Time!
                        </tspan>
                        <tspan x="63.7%"dy ="5%"fill='gold'>
                            26th January
                        </tspan>
                        <tspan x="68%"dy ="5%"fill='gold'>
                            to
                        </tspan>
                        <tspan x="63.7%"dy ="5%"fill='gold'>
                            28th January
                        </tspan>
                    </text>
                    <text x="82%" y="76%" fill="white" className="tl_first">Submission
                        <tspan x="82.5%"dy ="5%" >
                            Time it is!
                        </tspan>
                        <tspan x="81.5%"dy ="5%" fill='gold'>
                            28th January
                        </tspan>
                        <tspan x="83%"dy ="5%" fill='gold'>
                            11:59 PM
                        </tspan>
                    </text>
                </svg>




                <svg width="325" height="654" id="mob" viewBox="0 0 325 654" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_d_93_68)">
                        <path d="M159 0C154.582 0 151 3.58172 151 8C151 12.4183 154.582 16 159 16C163.418 16 167 12.4183 167 8C167 3.58172 163.418 0 159 0ZM159 630C154.582 630 151 633.582 151 638C151 642.418 154.582 646 159 646C163.418 646 167 642.418 167 638C167 633.582 163.418 630 159 630ZM157.5 8L157.5 638H160.5L160.5 8H157.5Z" fill="white"/>
                    </g>
                    <path d="M222.635 124.598L222.635 125L289.953 125L323.728 66.5L289.953 8L222.403 8L188.628 66.5L222.404 125L222.635 125L222.635 124.598L222.983 124.397L189.556 66.5L222.867 8.80341L289.489 8.80341L322.801 66.5L289.489 124.197L222.635 124.197L222.635 124.598Z" fill="url(#paint0_linear_93_68)"/>
                    <path d="M222.635 124.598L222.635 125L289.953 125L323.728 66.5L289.953 8L222.403 8L188.628 66.5L222.404 125L222.635 125L222.635 124.598L222.983 124.397L189.556 66.5L222.867 8.80341L289.489 8.80341L322.801 66.5L289.489 124.197L222.635 124.197L222.635 124.598Z" fill="white"/>
                    <g filter="url(#filter1_bdd_93_68)">
                        <path d="M226.63 117.611L226.63 119.218L287.245 119.218L318.016 65.9198L287.245 12.6218L225.702 12.6218L194.931 65.9198L225.702 119.218L226.63 119.218L226.63 117.611L228.022 116.808L198.642 65.9198L227.557 15.8354L285.39 15.8354L314.306 65.9198L285.39 116.004L226.63 116.004L226.63 117.611Z" fill="url(#paint1_linear_93_68)"/>
                        <path d="M226.13 118.477L226.13 117.611L226.13 116.004L226.13 115.504L226.63 115.504L226.692 115.504L198.209 66.1698L198.064 65.9198L198.209 65.6698L227.124 15.5855L227.269 15.3354L227.557 15.3354L285.39 15.3354L285.678 15.3354L285.823 15.5854L314.739 65.6698L314.883 65.9198L314.739 66.1698L285.823 116.254L285.678 116.504L285.39 116.504L228.424 116.504L228.455 116.558L228.705 116.991L228.272 117.241L227.13 117.9L227.13 118.718L286.956 118.718L317.439 65.9198L286.956 13.1218L225.991 13.1218L195.508 65.9198L225.991 118.718L226.13 118.718L226.13 118.477ZM227.339 116.624L227.269 116.504L227.13 116.504L227.13 116.745L227.339 116.624Z" stroke="black"/>
                    </g>
                    <path d="M158.975 65.3759C158.146 65.3899 157.486 66.0727 157.5 66.901C157.514 67.7293 158.197 68.3895 159.025 68.3755L158.975 65.3759ZM187.844 74.3916C192.261 74.3173 195.782 70.6759 195.708 66.2582C195.634 61.8406 191.992 58.3196 187.575 58.3939C183.157 58.4682 179.636 62.1097 179.71 66.5273C179.785 70.945 183.426 74.466 187.844 74.3916ZM159.025 68.3755L187.735 67.8926L187.684 64.893L158.975 65.3759L159.025 68.3755Z" fill="white"/>
                    <path d="M34.0074 241.598L34.0074 242L101.326 242L135.1 183.5L101.326 125L33.7753 125L4.19904e-05 183.5L33.776 242L34.0074 242L34.0074 241.598L34.3553 241.397L0.927993 183.5L34.2396 125.803L100.861 125.803L134.173 183.5L100.861 241.197L34.0074 241.197L34.0074 241.598Z" fill="url(#paint2_linear_93_68)"/>
                    <path d="M34.0074 241.598L34.0074 242L101.326 242L135.1 183.5L101.326 125L33.7753 125L4.19904e-05 183.5L33.776 242L34.0074 242L34.0074 241.598L34.3553 241.397L0.927993 183.5L34.2396 125.803L100.861 125.803L134.173 183.5L100.861 241.197L34.0074 241.197L34.0074 241.598Z" fill="white"/>
                    <g filter="url(#filter2_bdd_93_68)">
                        <path d="M38.002 234.611L38.002 236.218L98.6166 236.218L129.388 182.92L98.6166 129.622L37.0741 129.622L6.30283 182.92L37.0741 236.218L38.002 236.218L38.002 234.611L39.3935 233.808L10.0137 182.92L38.9291 132.835L96.7615 132.835L125.678 182.92L96.7615 233.004L38.002 233.004L38.002 234.611Z" fill="url(#paint3_linear_93_68)"/>
                        <path d="M37.502 235.477L37.502 234.611L37.502 233.004L37.502 232.504L38.002 232.504L38.0636 232.504L9.58073 183.17L9.43639 182.92L9.58073 182.67L38.4961 132.585L38.6405 132.335L38.9291 132.335L96.7615 132.335L97.0502 132.335L97.1945 132.585L126.111 182.67L126.255 182.92L126.111 183.17L97.1945 233.254L97.0502 233.504L96.7615 233.504L39.7957 233.504L39.8265 233.558L40.0765 233.991L39.6435 234.241L38.502 234.9L38.502 235.718L98.3279 235.718L128.81 182.92L98.3279 130.122L37.3627 130.122L6.88017 182.92L37.3627 235.718L37.502 235.718L37.502 235.477ZM38.7105 233.624L38.641 233.504L38.502 233.504L38.502 233.745L38.7105 233.624Z" stroke="black"/>
                    </g>
                    <path d="M223.007 358.598L223.007 359L290.325 359L324.1 300.5L290.325 242L222.775 242L189 300.5L222.776 359L223.007 359L223.007 358.598L223.355 358.397L189.928 300.5L223.239 242.803L289.861 242.803L323.173 300.5L289.861 358.197L223.007 358.197L223.007 358.598Z" fill="url(#paint4_linear_93_68)"/>
                    <path d="M223.007 358.598L223.007 359L290.325 359L324.1 300.5L290.325 242L222.775 242L189 300.5L222.776 359L223.007 359L223.007 358.598L223.355 358.397L189.928 300.5L223.239 242.803L289.861 242.803L323.173 300.5L289.861 358.197L223.007 358.197L223.007 358.598Z" fill="white"/>
                    <g filter="url(#filter3_bdd_93_68)">
                        <path d="M227.002 351.611L227.002 353.218L287.617 353.218L318.388 299.92L287.617 246.622L226.074 246.622L195.303 299.92L226.074 353.218L227.002 353.218L227.002 351.611L228.394 350.807L199.014 299.92L227.929 249.835L285.762 249.835L314.678 299.92L285.762 350.004L227.002 350.004L227.002 351.611Z" fill="url(#paint5_linear_93_68)"/>
                        <path d="M226.502 352.477L226.502 351.611L226.502 350.004L226.502 349.504L227.002 349.504L227.064 349.504L198.581 300.17L198.437 299.92L198.581 299.67L227.496 249.585L227.641 249.335L227.929 249.335L285.762 249.335L286.05 249.335L286.195 249.585L315.111 299.67L315.255 299.92L315.111 300.17L286.195 350.254L286.05 350.504L285.762 350.504L228.796 350.504L228.827 350.557L229.077 350.99L228.644 351.24L227.502 351.9L227.502 352.718L287.328 352.718L317.811 299.92L287.328 247.122L226.363 247.122L195.88 299.92L226.363 352.718L226.502 352.718L226.502 352.477ZM227.711 350.624L227.641 350.504L227.502 350.504L227.502 350.745L227.711 350.624Z" stroke="black"/>
                    </g>
                    <path d="M34.0074 475.598L34.0074 476L101.326 476L135.1 417.5L101.326 359L33.7753 359L4.19904e-05 417.5L33.776 476L34.0074 476L34.0074 475.598L34.3553 475.397L0.927993 417.5L34.2396 359.803L100.861 359.803L134.173 417.5L100.861 475.197L34.0074 475.197L34.0074 475.598Z" fill="url(#paint6_linear_93_68)"/>
                    <path d="M34.0074 475.598L34.0074 476L101.326 476L135.1 417.5L101.326 359L33.7753 359L4.19904e-05 417.5L33.776 476L34.0074 476L34.0074 475.598L34.3553 475.397L0.927993 417.5L34.2396 359.803L100.861 359.803L134.173 417.5L100.861 475.197L34.0074 475.197L34.0074 475.598Z" fill="white"/>
                    <g filter="url(#filter4_bdd_93_68)">
                        <path d="M38.002 468.611L38.002 470.218L98.6166 470.218L129.388 416.92L98.6166 363.622L37.0741 363.622L6.30283 416.92L37.0741 470.218L38.002 470.218L38.002 468.611L39.3935 467.807L10.0137 416.92L38.9291 366.835L96.7615 366.835L125.678 416.92L96.7615 467.004L38.002 467.004L38.002 468.611Z" fill="url(#paint7_linear_93_68)"/>
                        <path d="M37.502 469.477L37.502 468.611L37.502 467.004L37.502 466.504L38.002 466.504L38.0636 466.504L9.58073 417.17L9.43639 416.92L9.58073 416.67L38.4961 366.585L38.6405 366.335L38.9291 366.335L96.7615 366.335L97.0502 366.335L97.1945 366.585L126.111 416.67L126.255 416.92L126.111 417.17L97.1945 467.254L97.0502 467.504L96.7615 467.504L39.7957 467.504L39.8265 467.557L40.0765 467.99L39.6435 468.24L38.502 468.9L38.502 469.718L98.3279 469.718L128.81 416.92L98.3279 364.122L37.3627 364.122L6.88017 416.92L37.3627 469.718L37.502 469.718L37.502 469.477ZM38.7105 467.624L38.641 467.504L38.502 467.504L38.502 467.745L38.7105 467.624Z" stroke="black"/>
                    </g>
                    <path d="M223.007 592.598L223.007 593L290.325 593L324.1 534.5L290.325 476L222.775 476L189 534.5L222.776 593L223.007 593L223.007 592.598L223.355 592.397L189.928 534.5L223.239 476.803L289.861 476.803L323.173 534.5L289.861 592.197L223.007 592.197L223.007 592.598Z" fill="url(#paint8_linear_93_68)"/>
                    <path d="M223.007 592.598L223.007 593L290.325 593L324.1 534.5L290.325 476L222.775 476L189 534.5L222.776 593L223.007 593L223.007 592.598L223.355 592.397L189.928 534.5L223.239 476.803L289.861 476.803L323.173 534.5L289.861 592.197L223.007 592.197L223.007 592.598Z" fill="white"/>
                    <g filter="url(#filter5_bdd_93_68)">
                        <path d="M227.002 585.611L227.002 587.218L287.617 587.218L318.388 533.92L287.617 480.622L226.074 480.622L195.303 533.92L226.074 587.218L227.002 587.218L227.002 585.611L228.394 584.807L199.014 533.92L227.929 483.835L285.762 483.835L314.678 533.92L285.762 584.004L227.002 584.004L227.002 585.611Z" fill="url(#paint9_linear_93_68)"/>
                        <path d="M226.502 586.477L226.502 585.611L226.502 584.004L226.502 583.504L227.002 583.504L227.064 583.504L198.581 534.17L198.437 533.92L198.581 533.67L227.496 483.585L227.641 483.335L227.929 483.335L285.762 483.335L286.05 483.335L286.195 483.585L315.111 533.67L315.255 533.92L315.111 534.17L286.195 584.254L286.05 584.504L285.762 584.504L228.796 584.504L228.827 584.557L229.077 584.99L228.644 585.24L227.502 585.9L227.502 586.718L287.328 586.718L317.811 533.92L287.328 481.122L226.363 481.122L195.88 533.92L226.363 586.718L226.502 586.718L226.502 586.477ZM227.711 584.624L227.641 584.504L227.502 584.504L227.502 584.745L227.711 584.624Z" stroke="black"/>
                    </g>
                    <path d="M159.975 533.983C159.146 533.997 158.486 534.68 158.5 535.508C158.514 536.337 159.197 536.997 160.025 536.983L159.975 533.983ZM188.844 542.999C193.261 542.925 196.782 539.283 196.708 534.865C196.634 530.448 192.992 526.927 188.575 527.001C184.157 527.075 180.636 530.717 180.71 535.135C180.785 539.552 184.426 543.073 188.844 542.999ZM160.025 536.983L188.735 536.5L188.684 533.5L159.975 533.983L160.025 536.983Z" fill="white"/>
                    <path d="M158.975 298.983C158.146 298.997 157.486 299.68 157.5 300.508C157.514 301.337 158.197 301.997 159.025 301.983L158.975 298.983ZM187.844 307.999C192.261 307.925 195.782 304.283 195.708 299.865C195.634 295.448 191.992 291.927 187.575 292.001C183.157 292.075 179.636 295.717 179.71 300.135C179.785 304.552 183.426 308.073 187.844 307.999ZM159.025 301.983L187.735 301.5L187.684 298.5L158.975 298.983L159.025 301.983Z" fill="white"/>
                    <path d="M158.713 184.5C159.542 184.5 160.213 183.828 160.213 183C160.213 182.172 159.542 181.5 158.713 181.5V184.5ZM130 175C125.582 175 122 178.582 122 183C122 187.418 125.582 191 130 191C134.418 191 138 187.418 138 183C138 178.582 134.418 175 130 175ZM158.713 181.5H130V184.5H158.713V181.5Z" fill="white"/>
                    <path d="M158.713 418.5C159.542 418.5 160.213 417.828 160.213 417C160.213 416.172 159.542 415.5 158.713 415.5V418.5ZM130 409C125.582 409 122 412.582 122 417C122 421.418 125.582 425 130 425C134.418 425 138 421.418 138 417C138 412.582 134.418 409 130 409ZM158.713 415.5H130V418.5H158.713V415.5Z" fill="white"/>
                    <defs>
                        <filter id="filter0_d_93_68" x="147" y="0" width="24" height="654" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                            <feOffset dy="4"/>
                            <feGaussianBlur stdDeviation="2"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_93_68"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_93_68" result="shape"/>
                        </filter>
                        <filter id="filter1_bdd_93_68" x="190.931" y="8.62183" width="131.085" height="118.596" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                            <feGaussianBlur in="BackgroundImageFix" stdDeviation="2"/>
                            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_93_68"/>
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                            <feOffset dy="4"/>
                            <feGaussianBlur stdDeviation="2"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                            <feBlend mode="normal" in2="effect1_backgroundBlur_93_68" result="effect2_dropShadow_93_68"/>
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                            <feOffset dy="4"/>
                            <feGaussianBlur stdDeviation="2"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                            <feBlend mode="normal" in2="effect2_dropShadow_93_68" result="effect3_dropShadow_93_68"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_93_68" result="shape"/>
                        </filter>
                        <filter id="filter2_bdd_93_68" x="2.30286" y="125.622" width="131.085" height="118.596" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                            <feGaussianBlur in="BackgroundImageFix" stdDeviation="2"/>
                            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_93_68"/>
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                            <feOffset dy="4"/>
                            <feGaussianBlur stdDeviation="2"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                            <feBlend mode="normal" in2="effect1_backgroundBlur_93_68" result="effect2_dropShadow_93_68"/>
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                            <feOffset dy="4"/>
                            <feGaussianBlur stdDeviation="2"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                            <feBlend mode="normal" in2="effect2_dropShadow_93_68" result="effect3_dropShadow_93_68"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_93_68" result="shape"/>
                        </filter>
                        <filter id="filter3_bdd_93_68" x="191.303" y="242.622" width="131.085" height="118.596" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                            <feGaussianBlur in="BackgroundImageFix" stdDeviation="2"/>
                            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_93_68"/>
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                            <feOffset dy="4"/>
                            <feGaussianBlur stdDeviation="2"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                            <feBlend mode="normal" in2="effect1_backgroundBlur_93_68" result="effect2_dropShadow_93_68"/>
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                            <feOffset dy="4"/>
                            <feGaussianBlur stdDeviation="2"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                            <feBlend mode="normal" in2="effect2_dropShadow_93_68" result="effect3_dropShadow_93_68"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_93_68" result="shape"/>
                        </filter>
                        <filter id="filter4_bdd_93_68" x="2.30286" y="359.622" width="131.085" height="118.596" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                            <feGaussianBlur in="BackgroundImageFix" stdDeviation="2"/>
                            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_93_68"/>
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                            <feOffset dy="4"/>
                            <feGaussianBlur stdDeviation="2"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                            <feBlend mode="normal" in2="effect1_backgroundBlur_93_68" result="effect2_dropShadow_93_68"/>
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                            <feOffset dy="4"/>
                            <feGaussianBlur stdDeviation="2"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                            <feBlend mode="normal" in2="effect2_dropShadow_93_68" result="effect3_dropShadow_93_68"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_93_68" result="shape"/>
                        </filter>
                        <filter id="filter5_bdd_93_68" x="191.303" y="476.622" width="131.085" height="118.596" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                            <feGaussianBlur in="BackgroundImageFix" stdDeviation="2"/>
                            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_93_68"/>
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                            <feOffset dy="4"/>
                            <feGaussianBlur stdDeviation="2"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                            <feBlend mode="normal" in2="effect1_backgroundBlur_93_68" result="effect2_dropShadow_93_68"/>
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                            <feOffset dy="4"/>
                            <feGaussianBlur stdDeviation="2"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                            <feBlend mode="normal" in2="effect2_dropShadow_93_68" result="effect3_dropShadow_93_68"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_93_68" result="shape"/>
                        </filter>
                        <linearGradient id="paint0_linear_93_68" x1="256.178" y1="8" x2="256.178" y2="125" gradientUnits="userSpaceOnUse">
                            <stop offset="0.108333" stop-color="white"/>
                            <stop offset="0.233333" stop-color="#696A6A" stop-opacity="0.15"/>
                            <stop offset="0.4625" stop-color="white"/>
                            <stop offset="1" stop-color="white" stop-opacity="0.62"/>
                        </linearGradient>
                        <linearGradient id="paint1_linear_93_68" x1="256.473" y1="12.6218" x2="256.473" y2="119.218" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#A65454"/>
                            <stop offset="0.0001" stop-color="#4F398E"/>
                            <stop offset="0.365"/>
                            <stop offset="0.62" stop-color="#210334"/>
                            <stop offset="1" stop-color="#5A3DAA"/>
                        </linearGradient>
                        <linearGradient id="paint2_linear_93_68" x1="67.55" y1="125" x2="67.55" y2="242" gradientUnits="userSpaceOnUse">
                            <stop offset="0.108333" stop-color="white"/>
                            <stop offset="0.233333" stop-color="#696A6A" stop-opacity="0.15"/>
                            <stop offset="0.4625" stop-color="white"/>
                            <stop offset="1" stop-color="white" stop-opacity="0.62"/>
                        </linearGradient>
                        <linearGradient id="paint3_linear_93_68" x1="67.8453" y1="129.622" x2="67.8453" y2="236.218" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#A65454"/>
                            <stop offset="0.0001" stop-color="#4F398E"/>
                            <stop offset="0.365"/>
                            <stop offset="0.62" stop-color="#210334"/>
                            <stop offset="1" stop-color="#5A3DAA"/>
                        </linearGradient>
                        <linearGradient id="paint4_linear_93_68" x1="256.55" y1="242" x2="256.55" y2="359" gradientUnits="userSpaceOnUse">
                            <stop offset="0.108333" stop-color="white"/>
                            <stop offset="0.233333" stop-color="#696A6A" stop-opacity="0.15"/>
                            <stop offset="0.4625" stop-color="white"/>
                            <stop offset="1" stop-color="white" stop-opacity="0.62"/>
                        </linearGradient>
                        <linearGradient id="paint5_linear_93_68" x1="256.845" y1="246.622" x2="256.845" y2="353.218" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#A65454"/>
                            <stop offset="0.0001" stop-color="#4F398E"/>
                            <stop offset="0.365"/>
                            <stop offset="0.62" stop-color="#210334"/>
                            <stop offset="1" stop-color="#5A3DAA"/>
                        </linearGradient>
                        <linearGradient id="paint6_linear_93_68" x1="67.55" y1="359" x2="67.55" y2="476" gradientUnits="userSpaceOnUse">
                            <stop offset="0.108333" stop-color="white"/>
                            <stop offset="0.233333" stop-color="#696A6A" stop-opacity="0.15"/>
                            <stop offset="0.4625" stop-color="white"/>
                            <stop offset="1" stop-color="white" stop-opacity="0.62"/>
                        </linearGradient>
                        <linearGradient id="paint7_linear_93_68" x1="67.8453" y1="363.622" x2="67.8453" y2="470.218" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#A65454"/>
                            <stop offset="0.0001" stop-color="#4F398E"/>
                            <stop offset="0.365"/>
                            <stop offset="0.62" stop-color="#210334"/>
                            <stop offset="1" stop-color="#5A3DAA"/>
                        </linearGradient>
                        <linearGradient id="paint8_linear_93_68" x1="256.55" y1="476" x2="256.55" y2="593" gradientUnits="userSpaceOnUse">
                            <stop offset="0.108333" stop-color="white"/>
                            <stop offset="0.233333" stop-color="#696A6A" stop-opacity="0.15"/>
                            <stop offset="0.4625" stop-color="white"/>
                            <stop offset="1" stop-color="white" stop-opacity="0.62"/>
                        </linearGradient>
                        <linearGradient id="paint9_linear_93_68" x1="256.845" y1="480.622" x2="256.845" y2="587.218" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#A65454"/>
                            <stop offset="0.0001" stop-color="#4F398E"/>
                            <stop offset="0.365"/>
                            <stop offset="0.62" stop-color="#210334"/>
                            <stop offset="1" stop-color="#5A3DAA"/>
                        </linearGradient>
                    </defs>

                    <text x="8%" y="25%" fill="white" className="tl_first">Registrations
                        <tspan x="17%"dy ="2.5%" >
                            Ends
                        </tspan>
                        <tspan x="5.5%"dy ="2.5%" >
                            It's takeoff time!
                        </tspan>
                        <tspan x="8.5%"dy ="2.5%" fill='gold'>
                            27th January
                        </tspan>
                    </text>
                    <text x="66%" y="8.5%" fill="white" className="tl_first">Registrations
                        <tspan x="74%"dy ="2.5%" >
                            Starts
                        </tspan>
                        <tspan x="66.5%"dy ="2.5%" fill='gold' >
                            23rd January
                        </tspan>
                    </text>
                    <text x="67%" y="44%" fill="white" className="tl_first">Competition
                        <tspan x="74%"dy ="2.5%" >
                            Begins
                        </tspan>
                        <tspan x="66.5%"dy ="2.5%" fill='gold'>
                            26th January
                        </tspan>
                    </text>
                    <text x="13%" y="60%" fill="white" className="tl_first">Working
                        <tspan x="17%"dy ="2%" >
                            Time
                        </tspan>
                        <tspan x="8.5%"dy ="2%" fill='gold'>
                            26th January
                        </tspan>
                        <tspan x="19.5%"dy ="2%" fill='gold'>
                            to
                        </tspan>
                        <tspan x="8.5%"dy ="2%" fill='gold'>
                            28th January
                        </tspan>
                    </text>
                    <text x="68%" y="79%" fill="white" className="tl_first">Submission
                        <tspan x="74%"dy ="2.5%" >
                            Times
                        </tspan>
                        <tspan x="66.5%"dy ="2.5%" fill ='gold'>
                            28th January
                        </tspan>
                        <tspan x="70.75%"dy ="2.5%" fill ='gold'>
                            11:59 PM
                        </tspan>
                    </text>
                </svg>
            </div>
        </div>

    );
}

export default Timeline;